﻿import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { KhSelect } from '@internal-libraries/kheops-ui-lib'
import { VSelectItem } from '../Courier/VSelectItem'
import { Status } from '../models/Courier/Status'

@Component({ components: { KhSelect } })
export default class ImedsSelectStatus extends Vue {
    @Prop({ type: Boolean, default: false })
    multiple!: boolean

    @Prop({ default: null })
    defaultValue!: Status | Status[] | null

    @Prop()
    statusOptions!: VSelectItem<Status>[]

    currentStatus: Status | Status[] = this.getDefaultValue() ?? []

    //workaround, can't do: currentStatus: Status | Status[] = this.defaultValue ?? []
    getDefaultValue() {
        return this.defaultValue ?? []
    }

    @Watch('currentStatus')
    @Emit('status:change')
    emitChangeCompany(
        status: Status | Status[] | null
    ): Status | Status[] | null {
        return status
    }

    get selectAllIcon() {
        if (
            this.currentStatus &&
            this.currentStatus.length === this.statuses.length
        )
            return 'mdi-close-box'
        if (this.currentStatus && this.currentStatus.length > 0)
            return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    selectAll() {
        if (
            this.currentStatus &&
            this.currentStatus.length === this.statuses.length
        ) {
            this.currentStatus = []
        } else {
            this.currentStatus = this.statuses.map((d) => d.value) as Status[]
        }
    }

    @Watch('$t')
    get statuses(): VSelectItem<Status>[] {
        return this.statusOptions
    }
}
