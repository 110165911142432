﻿import '../polyfills'
import '@internal-libraries/kheops-ui-lib'

import Vue from 'vue'
import { init } from '../init'
import type { TFunction } from 'i18next'
import { Observable } from 'rxjs'
import {
    TDefaultFunction,
    THashFunction,
    useTranslation,
} from '../../i18n/useTranslation'
import { from } from '@vueuse/rxjs'
import { shareReplay } from 'rxjs/operators'
import { forceUpdateOnTranslationUpdates } from '../../i18n/forceUpdateOnTranslationUpdates'
import { PrintStore, usePrintStore } from '../../stores/print/PrintStore'

const plugins = init({
    language:
        (
            document.getElementById(
                'LanguageSwitcher_ddl_changeLanguage'
            ) as HTMLSelectElement
        )?.selectedOptions[0]?.value ?? 'fr',
})

const vues = [...document.getElementsByClassName('imeds-vue-wrap')].map(
    (el) => new Vue({ el, ...plugins })
)

forceUpdateOnTranslationUpdates(...vues)

const { t, tHash, tDefault } = useTranslation()
window.imedsTranslate$ = from(t, { immediate: true }).pipe(shareReplay(1))
window.imedsTranslateHash$ = from(tHash, { immediate: true }).pipe(
    shareReplay(1)
)
window.imedsTranslateDefault$ = from(tDefault, { immediate: true }).pipe(
    shareReplay(1)
)

window.imedsPrintStore = usePrintStore()

window.initImedsLegacy?.()

declare global {
    interface Window {
        imedsTranslate$: Observable<TFunction>
        imedsTranslateHash$: Observable<THashFunction>
        imedsTranslateDefault$: Observable<TDefaultFunction>
        imedsPrintStore: PrintStore
        initImedsLegacy?: () => void
    }
}
