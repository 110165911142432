import { interval, merge, Observable } from 'rxjs'
import { withLatestFrom, filter, mapTo } from 'rxjs/operators'

export function createDatatableAutorefreshObservable(
    refreshAction: Observable<void>,
    page: Observable<number>
): Observable<void> {
    return merge(
        refreshAction,
        interval(1000 * 60 * 5).pipe(
            withLatestFrom(page),
            filter(([_, page]) => page === 1),
            mapTo(undefined)
        )
    )
}
