<script setup lang="ts">
import { ref, computed } from 'vue'
import { useCurrentUserStore } from '../../../stores/currentUser'
import { useMicrobiologyStore } from '../../../stores/prescription/microbiology'
import { useAnalysisStore } from '../../../stores/prescription/analysis'
import { Roles } from '../../../api/ImedsApi'
import { useTranslate } from '../../../i18n/useTranslation'
import { KhModal, KhButton } from '@internal-libraries/kheops-ui-lib'
import PrescriptionOrderQuotation from './ImedsPrescriptionOrderQuotation.vue'

const currentUserStore = useCurrentUserStore()
const imedsMicrobiologyStore = useMicrobiologyStore()
const imedsAnalysisStore = useAnalysisStore()

const visible = computed(
    () =>
        currentUserStore.roles.includes(Roles.OrderQuotation) &&
        imedsMicrobiologyStore.selectedSamples.length +
            imedsAnalysisStore.selectedAnalyses.length >
            0
)

const dialog = ref(false)

const $t = useTranslate()
</script>
<template>
    <div v-if="visible">
        <kh-button color="primary" small @click="dialog = true">
            <kh-icon icon="mdi-cash" />
            {{ $t('prescription:openPriceQuotation') }}
        </kh-button>

        <kh-modal width="50%" v-model="dialog">
            <prescription-order-quotation v-if="dialog" />
        </kh-modal>
    </div>
</template>
