﻿import { useClient } from '../../../../../api/clients'
import ImedsDataTable from '../../../../../components/ImedsDataTable.vue'
import {
    ActionCodes,
    CourierLogisticsClient,
    CourierOrderDto,
    CourierUsersDto,
    DataTableConfigurationDto,
    Roles,
    SortDirection,
} from '../../../../../api/ImedsApi'
import { Component, Watch } from 'vue-property-decorator'
import { anyLoading, Loadable, loadable } from '../../../../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../../../../../components/ImedsDataTable'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import {
    Status,
    statusText,
} from '../../../../../components/models/Courier/Status'
import { useCurrentUserStore } from '../../../../../stores/currentUser'
import { useErrorStore } from '../../../../../stores/error'
import {
    KhConfirmModal,
    KhDateRangePicker,
    KhModal,
} from '@internal-libraries/kheops-ui-lib'
import { VSelectItem } from '../../../../../components/Courier/VSelectItem'
import { createDatatableAutorefreshObservable } from '../../../../../utils/Datatable'
import {
    ImedsLogisticsHistoryState,
    showHistory,
} from '../../../../../components/History/ImedsLogisticsHistory'

@Component({
    components: { ImedsDataTable, KhConfirmModal, KhModal, KhDateRangePicker },
    subscriptions(this: CourierLogisticsPendingRequestsTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(
                from(
                    this.courierLogisticsClient.getPendingRequestsConfiguration()
                )
            )

        const refresh$ = createDatatableAutorefreshObservable(
            this.refreshActionSubject$,
            this.page$
        )

        const loadablePagedResult: Loadable<PagedResultDto<CourierOrderDto>> =
            loadable(
                combineLatest([
                    combineLatest([this.page$, this.pageSize$]),
                    combineLatest([this.sortBy$, this.sortDirections$]),
                    combineLatest([this.filterDateFrom$, this.filterDateTo$]),
                    this.companyStatus$,
                    this.filterStatus$,
                    refresh$,
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([
                    [page, pageSize],
                    [sortBy, sortDirections],
                    [filterDateFrom, filterDateTo],
                    companyStatus,
                    filterStatus,
                ]) =>
                    from(
                        this.courierLogisticsClient.getPendingRequest(
                            sortBy,
                            sortDirections,
                            pageSize,
                            page,
                            true,
                            filterDateFrom,
                            filterDateTo,
                            filterStatus,
                            companyStatus
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            filterDateFrom: this.filterDateFrom$,
            filterDateTo: this.filterDateTo$,
            companyStatus: this.companyStatus$,
            filterStatus: this.filterStatus$,
        })
    },
})
export default class CourierLogisticsPendingRequestsTable extends Vue {
    readonly courierLogisticsClient = useClient(CourierLogisticsClient)
    readonly currentUserStore = useCurrentUserStore()
    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly filterDateFrom$ = new BehaviorSubject<Date | null>(null)
    readonly filterDateTo$ = new BehaviorSubject<Date | null>(null)
    readonly companyStatus$ = new BehaviorSubject<number[] | undefined>(
        this.currentUserStore.companyId
            ? [this.currentUserStore.companyId]
            : undefined
    )
    readonly filterStatus$ = new BehaviorSubject<string[] | undefined>(
        this.currentUserStore.roles.includes(Roles.Logistics)
            ? [Status.pending, Status.accepted, Status.done]
            : [Status.pending]
    )

    dialogConfirmAccept = false
    dialogReject = false
    dialogAssign = false
    dialogComplete = false
    dialogUnassign = false
    dialogReopen = false
    historyModalState: ImedsLogisticsHistoryState = null
    currentItem: CourierOrderDto | null = null
    comment = ''

    Roles = Roles
    async created(): Promise<void> {
        if (this.currentUserStore.roles.includes(Roles.Logistics))
            this.assigneeList =
                await this.courierLogisticsClient.getCourierUsers()
    }

    selectedAssignee: string | null = null

    assigneeList: CourierUsersDto[] | null = null

    async onItemAction({
        item,
        actionCode,
    }: ItemAction<CourierOrderDto>): Promise<void> {
        this.currentItem = item

        switch (actionCode) {
            case ActionCodes.Courier.Logistics.Accept: {
                this.dialogConfirmAccept = true
                break
            }
            case ActionCodes.Courier.Logistics.Assign: {
                this.dialogAssign = true
                break
            }
            case ActionCodes.Courier.Logistics.Reject: {
                this.dialogReject = true
                break
            }
            case ActionCodes.Courier.Logistics.Complete: {
                this.dialogComplete = true
                break
            }
            case ActionCodes.Courier.Logistics.Unassign: {
                this.dialogUnassign = true
                break
            }
            case ActionCodes.Courier.Logistics.Reopen: {
                this.dialogReopen = true
                break
            }
            case ActionCodes.Courier.Logistics.ViewHistory: {
                await showHistory(this, () =>
                    this.courierLogisticsClient.getHistory(item.id)
                )
                break
            }
        }
    }

    async acceptRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        this.currentItem.comment = this.comment
        await this.courierLogisticsClient.acceptRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
        this.comment = ''
    }

    async rejectRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.courierLogisticsClient.rejectRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    async assignRequest() {
        if (!this.currentItem?.id || !this.selectedAssignee) return

        await this.courierLogisticsClient.assignRequest(
            this.currentItem.id,
            this.selectedAssignee
        )
        this.dialogAssign = false
        this.selectedAssignee = null
        this.currentItem = null
        this.refreshActionSubject$.next()
    }

    async completeRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.courierLogisticsClient.completeRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    async unassignRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.courierLogisticsClient.unassignRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    async ReopenRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.courierLogisticsClient.reopenRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<Status>[] {
        return [
            Status.accepted,
            Status.pending,
            Status.done,
            Status.rejected,
            Status.cancelled,
        ].map((status) => ({
            value: status,
            text: this.$t(statusText[status]),
        }))
    }
}
