import { computed, ref, watch } from 'vue'
import { zipObject, debounce } from 'lodash'
import { useErrorStore } from '../error'
import { useClient } from '../../api/clients'
import {
    CurrentUserPrintersClient,
    PrintDriverTypeCode,
    UserPrinterDto,
} from '../../api/ImedsApi'
import { defineLoadableStore } from '@internal-libraries/kheops-ui-lib/src/store/LoadableStore'
import { UserPrinterWithConnectedPrinter } from './UserPrinterWithConnectedPrinter'
import { ConnectedPrinter, drivers } from './drivers'

const currentUserPrintersClient = useClient(CurrentUserPrintersClient)

export const usePrintersStore = defineLoadableStore(
    'printers',
    () => {
        const userPrinters = ref<UserPrinterDto[]>([])

        const selectedPrintDriverTypeCode = ref<PrintDriverTypeCode | null>(
            null
        )

        const activeDrivers = computed(() => {
            const activePrintDriverTypeCodes = new Set([
                ...userPrinters.value.map(
                    (userPrinter) => userPrinter.printDriverTypeCode
                ),
                ...(selectedPrintDriverTypeCode.value
                    ? [selectedPrintDriverTypeCode.value]
                    : []),
            ])

            return drivers.filter((driver) =>
                activePrintDriverTypeCodes.has(driver.printDriverType)
            )
        })

        const connectedPrintersByDriverTypeCode =
            ref<ConnectedPrintersByDriverTypeCode>(
                Object.fromEntries(
                    drivers.map((driver) => [
                        driver.printDriverType,
                        [] as ConnectedPrinter[],
                    ])
                ) as ConnectedPrintersByDriverTypeCode
            )

        const errorByDriverTypeCode = ref<ErrorByDriverTypeCode>({})

        const selectedDriverError = computed<string | null>(() =>
            selectedPrintDriverTypeCode.value
                ? errorByDriverTypeCode.value[
                      selectedPrintDriverTypeCode.value
                  ] ?? null
                : null
        )

        const selectedDriverConnectedPrinters = computed<ConnectedPrinter[]>(
            () =>
                selectedPrintDriverTypeCode.value
                    ? connectedPrintersByDriverTypeCode.value[
                          selectedPrintDriverTypeCode.value
                      ] ?? []
                    : []
        )

        const joinConnectedPrinter = computed(() => {
            const errorByDriverTypeCodeValue = errorByDriverTypeCode.value
            const connectedPrintersByDriverTypeCodeValue =
                connectedPrintersByDriverTypeCode.value

            return (
                userPrinter: UserPrinterDto | UserPrinterWithConnectedPrinter
            ): UserPrinterWithConnectedPrinter => ({
                ...userPrinter,
                connectedPrinter:
                    connectedPrintersByDriverTypeCodeValue[
                        userPrinter.printDriverTypeCode
                    ]?.find(
                        (connectedPrinter: ConnectedPrinter) =>
                            connectedPrinter.id == userPrinter.identifier
                    ) ?? false,
                error: errorByDriverTypeCodeValue[
                    userPrinter.printDriverTypeCode
                ],
            })
        })

        const printers = computed(() =>
            userPrinters.value.map((userPrinter) =>
                joinConnectedPrinter.value(userPrinter)
            )
        )

        const defaultPrinter = computed<UserPrinterWithConnectedPrinter | null>(
            () => printers.value.find((printer) => printer.isDefault) ?? null
        )

        const selectedPrinter = ref<UserPrinterWithConnectedPrinter | null>(
            null
        )

        watch(defaultPrinter, (newDefaultPrinter) => {
            selectedPrinter.value = newDefaultPrinter
        })

        const connectedPrintersLoading = ref(false)
        const _reloadConnectedPrinters = async () => {
            connectedPrintersLoading.value = true

            const results = await Promise.allSettled(
                activeDrivers.value.map((driver) => driver.getPrinters())
            )

            const activeDriverTypes = activeDrivers.value.map(
                (driver) => driver.printDriverType
            )

            connectedPrintersByDriverTypeCode.value = zipObject(
                activeDriverTypes,
                results.map((result) =>
                    result.status === 'fulfilled' ? result.value : []
                )
            ) as ConnectedPrintersByDriverTypeCode

            errorByDriverTypeCode.value = zipObject(
                activeDriverTypes,
                results.map((result) =>
                    result.status === 'rejected'
                        ? 'print:search_printers_error'
                        : undefined
                )
            )

            results.forEach((result) => {
                if (result.status === 'rejected') {
                    useErrorStore().handleError(result.reason, {
                        noNotification: true,
                    })
                }
            })

            connectedPrintersLoading.value = false
        }
        const reloadConnectedPrinters = debounce(_reloadConnectedPrinters, 400)

        watch(activeDrivers, () => reloadConnectedPrinters())

        return {
            printers,
            selectedPrinter,
            defaultPrinter,
            userPrinters,
            selectedPrintDriverTypeCode,
            selectedDriverError,
            selectedDriverConnectedPrinters,
            connectedPrintersLoading,
            reloadConnectedPrinters,
            joinConnectedPrinter,
        }
    },
    async (store) => {
        store.userPrinters.value =
            await useErrorStore().tryOrHandleErrorAndDefault<UserPrinterDto[]>(
                async () =>
                    await currentUserPrintersClient.getAllCurrentUserPrinters(),
                () => []
            )

        await useErrorStore().tryOrHandleError(
            async () => await store.reloadConnectedPrinters()
        )
    }
)

export type ConnectedPrintersByDriverTypeCode = {
    [c in PrintDriverTypeCode]?: ConnectedPrinter[]
}

export type ErrorByDriverTypeCode = {
    [c in PrintDriverTypeCode]?: string
}
