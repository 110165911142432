﻿import { useClient } from '../../api/clients'
import {
    CourierOrderDto,
    CourierOrdersClient,
    DataTableConfigurationDto,
    ActionCodes,
    SortDirection,
} from '../../api/ImedsApi'
import { loadable, Loadable, anyLoading } from '../../utils/Loadable'
import ImedsDataTable from '../ImedsDataTable.vue'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../ImedsDataTable'
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { VSelectItem } from './VSelectItem'
import { Status, statusText } from '../models/Courier/Status'
import { useErrorStore } from '../../stores/error'

@Component({
    components: { ImedsDataTable },
    subscriptions(this: CourierOrdersTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.courierOrdersClient.getConfiguration()).pipe())

        const loadablePagedResult: Loadable<PagedResultDto<CourierOrderDto>> =
            loadable(
                combineLatest([
                    this.page$,
                    this.pageSize$,
                    this.sortBy$,
                    this.sortDirections$,
                    this.filterStatus$,
                    this.refreshActionSubject$,
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([page, pageSize, sortBy, sortDirections, filterStatus]) =>
                    from(
                        this.courierOrdersClient.get(
                            pageSize,
                            page,
                            sortBy,
                            sortDirections,
                            true,
                            filterStatus
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            filterStatus: this.filterStatus$,
        })
    },
})
export default class CourierOrdersTable extends Vue {
    readonly courierOrdersClient = useClient(CourierOrdersClient)

    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly filterStatus$ = new BehaviorSubject<string | null>(null)

    async onItemAction(itemAction: ItemAction<CourierOrderDto>): Promise<void> {
        switch (itemAction.actionCode) {
            case ActionCodes.Courier.Order.Cancel:
                await this.courierOrdersClient.cancel(itemAction.item.id)
                this.refreshActionSubject$.next()
                break
        }
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<Status>[] {
        return [
            Status.accepted,
            Status.pending,
            Status.done,
            Status.cancelled,
            Status.rejected,
        ].map((status) => ({
            value: status,
            text: this.$t(statusText[status]),
        }))
    }
}
