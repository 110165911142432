import Vue from 'vue'
import Component from 'vue-class-component'
import { cloneDeep } from 'lodash'
import { KhButton } from '@internal-libraries/kheops-ui-lib'
import { useAnalysisStore } from '../../../stores/prescription/analysis'
import { useAnalysisProfileStore } from '../../../stores/prescription/analysisProfile'
import { useMicrobiologyStore } from '../../../stores/prescription/microbiology'

@Component({ components: { KhButton } })
export default class ImedsAnalysisProfiles extends Vue {
    readonly analysisProfileStore = useAnalysisProfileStore()
    readonly microbiologyStore = useMicrobiologyStore()

    mounted() {
        window.imedsAnalysesStore = useAnalysisStore()
        window.imedsAnalysisProfileStore = this.analysisProfileStore
    }

    loadProfile() {
        if (this.analysisProfileStore.selectedProfile == null) return

        this.analysisProfileStore.selectedProfile.microbiologySelectedSamples.forEach(
            (selectedSample) => {
                this.microbiologyStore.selectSample(cloneDeep(selectedSample))
            }
        )

        this.$emit(
            'load-profile',
            cloneDeep(this.analysisProfileStore.selectedProfile)
        )
    }
}

declare global {
    //TODO: To delete when the prescription is in a SPA page
    interface Window {
        imedsAnalysesStore: unknown
        imedsAnalysisProfileStore: unknown
    }
}
