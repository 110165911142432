import { Component, Prop, Vue } from 'vue-property-decorator'
import { KhColorUtils } from '@internal-libraries/kheops-ui-lib/src/common/services/KhColorUtils'
@Component
export default class ImedsTube extends Vue {
    @Prop()
    private color!: string

    @Prop()
    private name!: string

    @Prop()
    private capacity!: number

    @Prop()
    private quantity!: number

    private alphaValue = '66' //equals opacity:0.4

    private get quantityPercentage() {
        return (this.quantity * 100) / this.capacity
    }

    private get textColor() {
        return new KhColorUtils(this.color).getTextForegroundColor(false)
    }
}
