﻿import { ref } from 'vue'
import { defineLoadableStore } from '@internal-libraries/kheops-ui-lib/src/store/LoadableStore'
import { LanguageDto, LanguagesClient } from '../api/ImedsApi'
import { useClient } from '../api/clients'
import { useErrorStore } from './error'

const languagesClient = useClient(LanguagesClient)

export const useLanguageStore = defineLoadableStore(
    'language',
    () => ({
        languages: ref<LanguageDto[]>([]),
    }),
    async (store) => {
        await useErrorStore().tryOrHandleError(async () => {
            store.languages.value = await languagesClient.getAllLanguages()
        })
    }
)
