import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { KhSelect, KhSelectMenu } from '@internal-libraries/kheops-ui-lib'
import { UsersClient } from '../../api/ImedsApi'
import { useCurrentUserStore } from '../../stores/currentUser'
import { useLanguageStore } from '../../stores/language'
import { useClient } from '../../api/clients'

@Component({ components: { KhSelect, KhSelectMenu } })
export default class ImedsLanguageSelector extends Vue {
    readonly usersClient = useClient(UsersClient)

    readonly currentUserStore = useCurrentUserStore()
    readonly languageStore = useLanguageStore()

    get loading(): boolean {
        return this.currentUserStore.loading
    }

    get items(): { value: string; text: string }[] {
        return this.languageStore.languages.map((language) => ({
            value: language.code,
            text: language.name,
        }))
    }

    get currentUserLanguageCode(): string | null {
        return this.currentUserStore.languageCode
    }

    async changeCurrentUserLanguage(language: string) {
        await this.usersClient.updateLanguage(language)
        this.currentUserStore.languageCode = language
        this.$emit('change', language)
    }
}
