﻿import { computed, ref } from 'vue'
import { Analysis } from '../../components/models/Analysis'
import { SortOrder } from '../../components/Prescription/Microbiology/SortOrder'
import { chain } from 'lodash'
import { defineStore } from 'pinia'

export const useAnalysisStore = defineStore('analyses', () => {
    const selectedAnalyses = ref<Analysis[]>([])

    const sortOrder = ref<SortOrder>(SortOrder.ASC)

    const selectedAnalysesOrdered = computed(() =>
        chain(selectedAnalyses.value)
            .orderBy(
                (selectedAnalysis) => selectedAnalysis.name,
                sortOrder.value
            )
            .value()
    )

    return {
        selectedAnalyses,
        selectedAnalysesOrdered,
        sortOrder,
        unselectAnalysis: (analysisId: number): void => {
            const analysisIndex = selectedAnalyses.value.findIndex(
                (analysis) => analysis.id === analysisId
            )
            selectedAnalyses.value.splice(analysisIndex, 1)
        },
    }
})
