﻿export enum SampleTypesSaveForm {
    Clinic = 'CL',
    Home = 'DM',
    Office = 'OF',
    SamplingCenter = 'CP',
}

export const sampleTypesSaveFormText: Record<SampleTypesSaveForm, string> = {
    [SampleTypesSaveForm.Clinic]: 'prescription:samplingType.clinic',
    [SampleTypesSaveForm.Home]: 'prescription:samplingType.home',
    [SampleTypesSaveForm.Office]: 'prescription:samplingType.office',
    [SampleTypesSaveForm.SamplingCenter]:
        'prescription:samplingType.samplingCenter',
}
