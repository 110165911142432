﻿import Vue, { ref } from 'vue'
import { defineLoadableStore } from '@internal-libraries/kheops-ui-lib/src/store/LoadableStore'
import { UsersClient } from '../api/ImedsApi'
import { useClient } from '../api/clients'
import { useErrorStore } from './error'

const usersClient = useClient(UsersClient)

export const useCurrentUserStore = defineLoadableStore(
    'currentUser',
    () => {
        const roles = ref<string[]>([])
        return {
            roles,
            authenticated: ref<boolean>(false),
            companyId: ref<number | null>(null),
            languageCode: ref<string | null>(null),
            sampleType: ref<string | null>(null),
            hasAnyRoleIn: (...testedRoles: string[]): boolean =>
                testedRoles.some((role) => roles.value.includes(role)),
        }
    },
    async (store) => {
        const errorStore = useErrorStore()

        try {
            store.authenticated.value = false
            const currentUser = await usersClient.getCurrentUser()
            if (currentUser) store.authenticated.value = true

            store.roles.value = currentUser.roles

            store.companyId.value = currentUser.companyId ?? null

            store.languageCode.value = currentUser.languageCode ?? null
            await Vue.$i18n.changeLanguage(currentUser.languageCode)

            store.sampleType.value = currentUser.sampleType ?? null
        } catch (error) {
            errorStore.handleError(error)
        }
    }
)
