import { Component, Ref } from 'vue-property-decorator'
import PrescriptionPatientTable from './PrescriptionPatientTable'
import Vue from 'vue'
import { PrescriptionPatientDto, VisitDto } from '../../../api/ImedsApi'
import { usePatientSearchStore } from '../../../stores/patientSearch'

@Component
export default class PrescriptionPatientSearchButton extends Vue {
    readonly patientSearchStore = usePatientSearchStore()
    private searchModal = this.patientSearchStore.openSearchModalOnLoad ?? false

    @Ref('patientTable')
    readonly patientTable?: PrescriptionPatientTable

    async mounted() {
        await this.patientSearchStore.load()
        if (this.patientSearchStore.openSearchModalOnLoad)
            this.searchModal = this.patientSearchStore.openSearchModalOnLoad
    }
    setPatient(patient: PrescriptionPatientDto, visit?: VisitDto | null) {
        this.searchModal = false
        this.$emit('set-patient', { patient, visit })
    }
}
