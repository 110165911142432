import Vue from 'vue'
import Component from 'vue-class-component'
import { KhSelect } from '@internal-libraries/kheops-ui-lib'
import { CompanyDto } from '../../api/ImedsApi'
import { useCompanyStore } from '../../stores/company'
import { useCurrentUserStore } from '../../stores/currentUser'
import { Prop } from 'vue-property-decorator'

@Component({ components: { KhSelect } })
export default class ImedsCompanySelector extends Vue {
    readonly currentUserStore = useCurrentUserStore()
    readonly companyStore = useCompanyStore()

    @Prop({ default: null })
    companies?: number[] | null

    get allCompanies(): CompanyDto[] {
        return this.companyStore.companies
    }

    get loading(): boolean {
        return this.currentUserStore.loading || this.companyStore.loading
    }

    selectedCompanyIds: number[] = []

    async created(): Promise<void> {
        await this.currentUserStore.load()
        await this.companyStore.load()

        this.selectedCompanyIds =
            this.companies != null &&
            this.currentUserStore.companyId &&
            this.companies.length === 0
                ? [this.currentUserStore.companyId]
                : this.companies != null && this.companies.length !== 0
                ? this.companies
                : []
    }
}
