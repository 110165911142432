export interface Device {
    name: string
    deviceType: string
    connection: string
    uid: string
    provider: string
    manufacturer: string
    version: number
}

// Warning: on IOS all browsers are detected as Safari
const API_URL =
    /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
    location.protocol === 'https:'
        ? 'https://127.0.0.1:9101/'
        : 'http://127.0.0.1:9100/'

const request = async (
    method: 'GET' | 'POST',
    route: string,
    body?: unknown
): Promise<Response> =>
    await fetch(API_URL + route, {
        method,
        headers: {
            'Content-Type': 'text/plain;charset=UTF-8',
        },
        body: body != undefined ? JSON.stringify(body) : undefined,
    })

export const getAvailablePrinters = async () => {
    const data = await (await request('GET', 'available')).json()

    if (data && data.printer && data.printer.length > 0) {
        return data.printer
    }

    return new Error('No printers available')
}

export const getDefaultPrinter = async (): Promise<Device> => {
    const data = await (await request('GET', 'default')).text()

    if (data && typeof data !== 'object' && data.split('\n\t').length === 7) {
        const deviceRaw = data.split('\n\t')

        return {
            connection: cleanUpString(deviceRaw[3]),
            deviceType: cleanUpString(deviceRaw[2]),
            manufacturer: cleanUpString(deviceRaw[6]),
            name: cleanUpString(deviceRaw[1]),
            provider: cleanUpString(deviceRaw[5]),
            uid: cleanUpString(deviceRaw[4]),
            version: 0,
        }
    }

    throw new Error("There's no default printer")
}

export const cleanUpString = (str: string): string => {
    const arr = str.split(':')
    return arr[1].trim()
}

export const write = async (device: Device, data: string) => {
    await request('POST', 'write', {
        device,
        data,
    })
}

export const read = async (device: Device) => {
    return await (await request('POST', 'read', { device })).text()
}

export const checkPrinterStatus = async (device: Device) => {
    await write(device, '~HQES')
    const result = await read(device)

    const isError = result.charAt(70)
    const media = result.charAt(88)
    const head = result.charAt(87)
    const pause = result.charAt(84)

    const isReadyToPrint = isError === '0'

    const errors = []
    switch (media) {
        case '1':
            errors.push('Paper out')
            break
        case '2':
            errors.push('Ribbon Out')
            break
        case '4':
            errors.push('Media Door Open')
            break
        case '8':
            errors.push('Cutter Fault')
            break
        default:
            break
    }

    switch (head) {
        case '1':
            errors.push('Printhead Overheating')
            break
        case '2':
            errors.push('Motor Overheating')
            break
        case '4':
            errors.push('Printhead Fault')
            break
        case '8':
            errors.push('Incorrect Printhead')
            break
        default:
            break
    }

    if (pause === '1') errors.push('Printer Paused')

    if (!isReadyToPrint && errors.length === 0)
        errors.push('Error: Unknown Error')

    return {
        isReadyToPrint,
        errors: errors.join(),
    }
}
