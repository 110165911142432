﻿import Component from 'vue-class-component'
import Vue from 'vue'
import { KhSelect, KhButton, KhIcon } from '@internal-libraries/kheops-ui-lib'
import { Watch } from 'vue-property-decorator'
import { VSelectItem } from '../Courier/VSelectItem'
import {
    SampleTypesSaveForm,
    sampleTypesSaveFormText,
} from '../models/ImedsPrescriptionSaveFormOptions/SampleTypeSaveForm'
import {
    OptionsSaveForm,
    optionsSaveFormText,
} from '../models/ImedsPrescriptionSaveFormOptions/OptionsSaveForm'
import { useCurrentUserStore } from '../../stores/currentUser'
import ImedsPrescriptionFormAppointment from './PrescriptionFormAppointment/ImedsPrescriptionFormAppointment.vue'

@Component({
    components: {
        ImedsPrescriptionFormAppointment,
        KhSelect,
        KhButton,
        KhIcon,
    },
})
export default class ImedsPrescriptionOrderSaveForm extends Vue {
    readonly userStore = useCurrentUserStore()
    sampleTypes: SampleTypesSaveForm[] = []
    options: OptionsSaveForm[] = []
    @Watch('sampleTypes')
    @Watch('$t')
    get sampleTypeSaveForms(): VSelectItem<SampleTypesSaveForm>[] {
        return [
            SampleTypesSaveForm.Clinic,
            SampleTypesSaveForm.Home,
            SampleTypesSaveForm.Office,
            SampleTypesSaveForm.SamplingCenter,
        ].map((samplesType) => ({
            value: samplesType,
            text: this.$t(sampleTypesSaveFormText[samplesType]),
        }))
    }
    @Watch('options')
    @Watch('$t')
    get optionsSaveForms(): VSelectItem<OptionsSaveForm>[] {
        return [
            OptionsSaveForm.Pathologic,
            OptionsSaveForm.Create,
            OptionsSaveForm.Done,
        ].map((options) => ({
            value: options,
            text: this.$t(optionsSaveFormText[options]),
        }))
    }
    currentSampleType(): string | undefined {
        return this.userStore.sampleType ?? undefined
    }
    saveForm(): string {
        return ''
    }
    saveFormLabels(): string {
        return ''
    }
    close(): string {
        return ''
    }
}
