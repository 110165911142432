import Vue from 'vue'
import Component from 'vue-class-component'
import ImedsLanguageSelector from '../ImedsLanguageSelector.vue'

@Component({ components: { ImedsLanguageSelector } })
export default class ImedsSpaLanguageSelector extends Vue {
    async changeLanguage(languageCode: string): Promise<void> {
        await this.$i18n.changeLanguage(languageCode)
    }
}
