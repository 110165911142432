import {
    NetworkPrinterDto,
    NetworkPrintersClient,
    PrintDataType,
    PrintDriverTypeCode,
} from '../../../../api/ImedsApi'
import { useClient } from '../../../../api/clients'
import { ConnectedPrinter } from '../ConnectedPrinter'
import { PrintDriver } from '../PrintDriver'

const networkPrintersClient = useClient(NetworkPrintersClient)

export const zebraNetworkPrintDriver: PrintDriver<PrintDriverTypeCode.ZebraNetworkPrint> =
    Object.freeze({
        printDriverType: PrintDriverTypeCode.ZebraNetworkPrint,
        printDataType: PrintDataType.Zpl,
        name: 'Zebra Network Print',
        async print(
            printer: ConnectedPrinter<PrintDriverTypeCode.ZebraNetworkPrint>,
            data: string[]
        ): Promise<void> {
            await networkPrintersClient.print(
                parseInt(printer.id),
                data.join('\n')
            )
        },
        async getPrinters(): Promise<
            ConnectedPrinter<PrintDriverTypeCode.ZebraNetworkPrint>[]
        > {
            const dtos = await networkPrintersClient.getNetworkPrinters()
            return dtos.map((dto) => createPrinter(dto))
        },
    })

const createPrinter = (
    dto: NetworkPrinterDto
): ConnectedPrinter<PrintDriverTypeCode.ZebraNetworkPrint> =>
    Object.freeze({
        id: dto.id.toString(),
        name: dto.name,
        driverType: PrintDriverTypeCode.ZebraNetworkPrint,
    })
