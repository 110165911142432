import { defineStore } from 'pinia'
import Vue from 'vue'
import { OperatorFunction, empty, Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import {
    ImedsApiException,
    ImedsDtoErrors,
    ImedsErrorDto,
} from '../api/ImedsApi'
import { useNotificationStore } from './notification'

/**
 * Information related to an error and how it should be handled
 */
export interface ErrorContext {
    /** The vue instance where the error occured */
    vm?: Vue

    /** Additional information about the error location provided by vue */
    info?: string

    /** Error DTO returned by an API route */
    errorDto?: ImedsErrorDto<unknown>

    /**
     * Error message shown to the user in a notification.
     * If it's not set, the message will be the first non-undefined value in:
     * - `context.errorDto.message`
     * - `error.message`
     * - `error` if error is a string
     * - `'imeds:unknown_error_message'`
     */
    message?: string

    /**
     * No notification will be shown when this is true
     */
    noNotification?: boolean
}

export const useErrorStore = defineStore('error', () => {
    const notificationStore = useNotificationStore()
    const timeoutIdsByMessage: Record<string, number> = {}

    /**
     * Shows an error notification, except if the same message was already shown in the last 500ms.
     * @param message The error message to display in the notification.
     */
    const notifyErrorThrottled = (message: string) => {
        if (!(message in timeoutIdsByMessage)) {
            notificationStore.notifyError(message)
        }

        clearTimeout(timeoutIdsByMessage[message])
        timeoutIdsByMessage[message] = setTimeout(() => {
            delete timeoutIdsByMessage[message]
        }, 500)
    }

    /**
     * Logs and notifies an error
     * @param error the error
     * @param context additional information related to the error
     */
    const handleError = (error: unknown, context?: ErrorContext): string => {
        console.warn(
            ...[
                'Error handled in imeds errorStore:\n',
                error,
                { ...(context ?? {}), error },
            ]
        )

        if (!context?.errorDto) {
            const errorDto = tryParseImedsErrorDto(error)
            if (errorDto) {
                context = { ...context, errorDto: tryParseImedsErrorDto(error) }
            }
        }

        const message = getErrorMessage(error, context)

        if (!context?.noNotification) {
            notifyErrorThrottled(message)
        }

        return message
    }

    /**
     * Executes an action and catches any error with `handleError`
     * @param action Action to execute
     * @param context Context passed to `handleError`
     */
    const tryOrHandleError = async (
        action: () => Promise<void>,
        context?: ErrorContext
    ): Promise<void> => {
        try {
            await action()
        } catch (error) {
            handleError(error, context)
        }
    }

    /**
     * Executes an action and catches any error with `handleError`
     * @param action Function that executes an action and return something on success
     * @param getDefault Function that will be used to return a value if `action` throws an error
     * @param context Context passed to `handleError`
     * @returns The return value of `action` on success or the return value of `getDefault` on error
     */
    const tryOrHandleErrorAndDefault = async <T>(
        action: () => Promise<T>,
        getDefault: (message: string) => T,
        context?: ErrorContext
    ): Promise<T> => {
        try {
            return await action()
        } catch (error) {
            const message = handleError(error, context)
            return getDefault(message)
        }
    }

    /**
     * Logs and notifies an error and tries to return more detailed error messages for each DTO field if available
     * @param error the error
     * @typeparam TDto type of the DTO
     */
    const handleDtoError = <TDto>(error: unknown): ImedsDtoErrors<TDto> => {
        const errorDto = tryParseImedsErrorDto(error)

        handleError(error, { errorDto })

        return errorDto?.errors ?? {}
    }

    /**
     * RxJS operator that catches, logs and notifies errors then ends the stream
     */
    const handleRxError = <T>(): OperatorFunction<T, T> => {
        return catchError((error) => {
            handleError(error)
            return empty()
        })
    }

    /**
     * Pipes the `handleRxError` operator to multiple observables.
     * This is useful because Vue does not catch errors in VueRx `subscriptions`.
     * @param subscriptions named observables
     * @returns the `subscriptions` with `handleRxError` applied to each
     */
    const handleSubscriptionsErrors = <
        TSubscriptions extends { [name: string]: Observable<unknown> }
    >(
        subscriptions: TSubscriptions
    ): TSubscriptions => {
        return Object.fromEntries(
            Object.entries(subscriptions).map(([name, observable]) => [
                name,
                observable.pipe(handleRxError()),
            ])
        ) as TSubscriptions
    }

    return {
        handleError,
        handleDtoError,
        handleRxError,
        handleSubscriptionsErrors,
        tryOrHandleError,
        tryOrHandleErrorAndDefault,
    }
})

/**
 * Tries to retrieve an ImedsErrorDto from any error
 * @param error any error
 * @return an ImedsErrorDto if the error is an ImedsApiException and the DTO can be parsed
 */
const tryParseImedsErrorDto = <TDto>(
    error: unknown
): ImedsErrorDto<TDto> | undefined => {
    if (error instanceof ImedsApiException) {
        try {
            return JSON.parse(error.response) as ImedsErrorDto<TDto>
        } catch (parsingError) {
            console.warn('Error while parsing ImedsErrorDto', parsingError)
        }
    }
}

/**
 * Finds the message to notify from an error
 * @param error the error
 * @param context additional information related to the error
 * @returns the message or a default message if none is found
 */
const getErrorMessage = (error: unknown, context?: ErrorContext): string => {
    if (context?.message) {
        // From explicit message in context
        return context.message
    }

    if (context?.errorDto?.message) {
        // From api exception
        return context.errorDto.message
    }

    if (typeof error === 'string') {
        // From string
        return error
    }

    const message =
        error instanceof Object && 'message' in error
            ? error['message']
            : undefined

    if (typeof message === 'string') {
        // From any object with a string message property
        return message
    }

    // Default
    return 'imeds:unknown_error_message'
}
