﻿import {
    KhComponent,
    KhConfirmModal,
    KhDateRangePicker,
} from '@internal-libraries/kheops-ui-lib'
import { useClient } from '../../../../../api/clients'
import ImedsDataTable from '../../../../../components/ImedsDataTable.vue'
import {
    ActionCodes,
    CourierLogisticsClient,
    CourierLogisticsRequestDto,
    CourierOrderDto,
    DataTableConfigurationDto,
    SortDirection,
} from '../../../../../api/ImedsApi'
import { anyLoading, Loadable, loadable } from '../../../../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../../../../../components/ImedsDataTable'
import { Component, Watch } from 'vue-property-decorator'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { VSelectItem } from '../../../../../components/Courier/VSelectItem'
import {
    Status,
    statusText,
} from '../../../../../components/models/Courier/Status'
import { useErrorStore } from '../../../../../stores/error'
import { createDatatableAutorefreshObservable } from '../../../../../utils/Datatable'
import {
    ImedsLogisticsHistoryState,
    showHistory,
} from '../../../../../components/History/ImedsLogisticsHistory'

@Component({
    components: { ImedsDataTable, KhConfirmModal, KhDateRangePicker },
    subscriptions(this: CourierLogisticsPlanningTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(
                from(this.courierLogisticsClient.getPlanningConfiguration())
            )

        const refresh$ = createDatatableAutorefreshObservable(
            this.refreshActionSubject$,
            this.page$
        )

        const loadablePagedResult: Loadable<
            PagedResultDto<CourierLogisticsRequestDto>
        > = loadable(
            combineLatest([
                combineLatest([this.page$, this.pageSize$]),
                combineLatest([this.sortBy$, this.sortDirections$]),
                this.filterStatus$,
                this.filterDateFrom$,
                this.filterDateTo$,
                refresh$,
            ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
            ([
                [page, pageSize],
                [sortBy, sortDirections],
                filterStatus,
                filterDateFrom,
                filterDateTo,
            ]) =>
                from(
                    this.courierLogisticsClient.getPlanningRequest(
                        sortBy,
                        sortDirections,
                        pageSize,
                        page,
                        true,
                        filterDateFrom,
                        filterDateTo,
                        filterStatus
                    )
                )
        )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            filterStatus: this.filterStatus$,
            filterDateFrom: this.filterDateFrom$,
            filterDateTo: this.filterDateTo$,
        })
    },
})
export default class CourierLogisticsPlanningTable extends KhComponent {
    readonly courierLogisticsClient = useClient(CourierLogisticsClient)
    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly filterStatus$ = new BehaviorSubject<string[] | undefined>([
        Status.accepted,
    ])
    readonly filterDateFrom$ = new BehaviorSubject<Date | null>(null)
    readonly filterDateTo$ = new BehaviorSubject<Date | null>(null)

    dialogComplete = false
    dialogUnassign = false
    dialogReopen = false
    currentItem: CourierOrderDto | null = null
    comment = ''
    selectedStatus = [Status.accepted]
    historyModalState: ImedsLogisticsHistoryState = null

    async onItemAction({
        item,
        actionCode,
    }: ItemAction<CourierOrderDto>): Promise<void> {
        this.currentItem = item

        switch (actionCode) {
            case ActionCodes.Courier.Logistics.Complete: {
                this.dialogComplete = true
                break
            }
            case ActionCodes.Courier.Logistics.Unassign: {
                this.dialogUnassign = true
                break
            }
            case ActionCodes.Courier.Logistics.Reopen: {
                this.dialogReopen = true
                break
            }
            case ActionCodes.Courier.Logistics.ViewHistory: {
                await showHistory(this, () =>
                    this.courierLogisticsClient.getHistory(item.id)
                )
                break
            }
        }
    }

    async completeRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.courierLogisticsClient.completeRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.resetData()
    }

    async unassignRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.courierLogisticsClient.unassignRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.resetData()
    }

    async ReopenRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.courierLogisticsClient.reopenRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.resetData()
    }

    resetData(): void {
        this.currentItem = null
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<Status>[] {
        return [
            Status.accepted,
            Status.pending,
            Status.done,
            Status.rejected,
            Status.cancelled,
        ].map((status) => ({
            value: status,
            text: this.$t(statusText[status]),
        }))
    }
}
