import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class ImedsAnalysisProfileContentList extends Vue {
    @Prop({ required: true })
    readonly content!: AnalysisProfileContent
}

export enum SectionType {
    Laboratory = 'prescription:laboratory',
    Microbiology = 'prescription:microbiology',
}

export interface Section {
    type: SectionType
    items: {
        name: string
        subItemNames?: string[]
    }[]
}

export type AnalysisProfileContent = Section[]
