import Component from 'vue-class-component'
import Vue from 'vue'
import { useClient } from '../../../../api/clients'
import {
    CountriesClient,
    CountriesDto,
    PatientClient,
    SexTypeDto,
} from '../../../../api/ImedsApi'
import { VSelectItem } from '../../../Courier/VSelectItem'
import { KhPhoneInput } from '@internal-libraries/kheops-ui-lib'
import { Prop, Watch } from 'vue-property-decorator'
import ImedsEmailInput from '../../../ImedsEmailInput.vue'
import ImedsHealthInsuranceInput from '../../../ImedsHealthInsuranceInput.vue'

@Component({
    components: {
        KhPhoneInput,
        ImedsEmailInput,
        ImedsHealthInsuranceInput,
    },
})
export default class ImedsEditionFormPatientModal extends Vue {
    readonly patientClient = useClient(PatientClient)
    readonly countriesClient = useClient(CountriesClient)

    @Prop({ default: false }) modalOpen!: boolean

    sex: string | null = null
    lastName: string | null = null
    firstName: string | null = null
    maidenSurname: string | null = null
    birthdate: Date | null = null
    patientReference: string | null = null
    address: string | null = null
    additionalAddress: string | null = null
    postalCode: string | null = null
    city: string | null = null
    country: string | null = null
    phone: string | null = null
    email: string | null = null
    idCard: string | null = null
    avs: string | null = null
    healthInsurance: string | null = null
    insuranceNumber: string | null = null
    insuranceCardNumber: string | null = null
    sexList: SexTypeDto[] | null = null
    countriesList: CountriesDto[] | null = null

    async mounted() {
        this.sexList = await this.patientClient.getSexTypes()
        this.countriesList = await this.countriesClient.getAllCountries()
    }

    @Watch('$t')
    get sexOptions(): VSelectItem<number>[] {
        if (!this.sexList) return []
        return this.sexList.map((sex) => ({
            value: sex.id,
            text: this.$t(sex.text) as string,
        }))
    }

    @Watch('$t')
    get countriesOptions(): VSelectItem<number>[] {
        if (!this.countriesList) return []
        return this.countriesList.map((countries) => ({
            value: countries.id,
            text: this.$t('countries:' + countries.code) as string,
        }))
    }
    async editPatient(): Promise<void> {
        this.$emit('close')
    }
}
