<script setup lang="ts">
import { AnalysisGroupItem } from './Models/AnalysisGroupItem'
import { useTranslate } from '../../../i18n/useTranslation'
import ImedsIcon from '../../ImedsIcon.vue'
import ImedsAnalysisList from './ImedsAnalysisList.vue'
import KhEllipsis from '../../KhEllipsis/KhEllipsis.vue'

const props = defineProps<{
    value: AnalysisGroupItem[] | null
}>()

const $t = useTranslate()
</script>
<template>
    <div class="analysis-group-list">
        <div v-for="(group, index) in props.value ?? []" :key="index">
            <div class="d-flex analysis-group-name">
                <kh-ellipsis>
                    <b>
                        {{ $t(group.name) }}
                    </b>
                </kh-ellipsis>
                <v-btn
                    v-for="(action, groupActionIndex) in group.action"
                    :key="groupActionIndex"
                    small
                    icon
                    @click="action.action"
                    class="ml-auto"
                >
                    <imeds-icon :icon="action.icon" :color="action.iconColor" />
                </v-btn>
            </div>
            <ul class="pl-2">
                <li
                    class="no-bullet"
                    v-for="(item, itemIndex) in group.items"
                    :key="itemIndex"
                >
                    <imeds-analysis-list :item="item" />
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
.analysis-group-list {
    width: 100%;
}
.analysis-group-name {
    font-size: 16px;
}
.no-bullet {
    list-style: none;
}
</style>
