import md5 from 'md5'

const base62Charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')

const bigint0 = BigInt(0)
const bigint62 = BigInt(62)

const bigintToBase62 = (n: bigint): string => {
    if (n === bigint0) {
        return base62Charset[0]
    }
    let s: string[] = []
    while (n > bigint0) {
        s = [base62Charset[Number(n % bigint62)], ...s]
        n = n / bigint62
    }
    return s.join('')
}

export const md5Base62 = (str: string): string =>
    bigintToBase62(BigInt('0x' + md5(str, { encoding: 'binary' })))
