import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', () => {
    const notifications = ref<ImedsNotification[]>([])

    const notify = (message: string, severity: ImedsNotificationSeverity) => {
        notifications.value.push({
            message,
            severity,
        })
    }

    return {
        notifications,
        notify,
        notifySuccess: (message: string): void =>
            notify(message, ImedsNotificationSeverity.Success),
        notifyError: (message: string): void =>
            notify(message, ImedsNotificationSeverity.Error),
        notifyWarning: (message: string): void =>
            notify(message, ImedsNotificationSeverity.Warning),
        notifyInfo: (message: string): void =>
            notify(message, ImedsNotificationSeverity.Info),
        acknowledgeAll: (): void => {
            notifications.value = []
        },
    }
})

export enum ImedsNotificationSeverity {
    Success = 'success',
    Error = 'error',
    Info = 'info',
    Warning = 'warning',
}

export interface ImedsNotification {
    message: string
    severity: ImedsNotificationSeverity
}
