import { Vue, Component } from 'vue-property-decorator'
import { KhButton, KhIcon } from '@internal-libraries/kheops-ui-lib'

@Component({ components: { KhButton, KhIcon } })
export default class ImedsPrinterModal extends Vue {
    private printerModal = false

    printLabel() {
        this.printerModal = false
        this.$emit('print-label')
    }

    printLabelPostal() {
        this.printerModal = false
        this.$emit('print-label-postal')
    }
}
