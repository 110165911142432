import Vue from 'vue'
import { Component, Watch, Prop } from 'vue-property-decorator'
import {
    ImedsErrorDto,
    PrescriptionClient,
    PricingDto,
} from '../../../api/ImedsApi'
import { useMicrobiologyStore } from '../../../stores/prescription/microbiology'
import { useAnalysisStore } from '../../../stores/prescription/analysis'
import { useClient } from '../../../api/clients'

const prescriptionClient = useClient(PrescriptionClient)

@Component
export default class ImedsPrescriptionOrderQuotation extends Vue {
    @Prop({ default: false, type: Boolean })
    printable!: boolean

    readonly analysesStore = useAnalysisStore()
    readonly microbiologyStore = useMicrobiologyStore()

    private orderPricing: PricingDto | null = null
    private externalApiError: ImedsErrorDto<PricingDto> | null = null

    private loading = true

    readyToPrint = new CustomEvent('readyToPrint')

    get orderPricingRequestDto(): string[] {
        return this.printable
            ? this.$route?.query.codes
                ? (this.$route.query.codes as string).split(',')
                : []
            : this.analysesStore.selectedAnalyses
                  .map((analysis) => analysis.code)
                  .concat(
                      this.microbiologyStore.selectedSamplesWithCatalog.flatMap(
                          ([, , analyses]) =>
                              analyses.map((analysis) => analysis.code)
                      )
                  )
    }

    @Watch('orderPricingRequestDto')
    async onAnalysesChange() {
        await this.getPrices()
    }

    async mounted() {
        await this.getPrices()
    }

    async getPrices() {
        this.loading = true
        try {
            if (
                this.orderPricingRequestDto &&
                this.orderPricingRequestDto.length > 0
            ) {
                try {
                    const data = await prescriptionClient.analysesPrices(
                        this.orderPricingRequestDto
                    )
                    this.externalApiError = null
                    this.orderPricing = data
                    window.dispatchEvent(this.readyToPrint)
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    this.externalApiError = JSON.parse(error.response)
                    this.orderPricing = null
                    window.dispatchEvent(this.readyToPrint)
                }
            } else {
                this.externalApiError = null
                this.orderPricing = null
                window.dispatchEvent(this.readyToPrint)
            }
        } finally {
            this.loading = false
        }
    }

    formatPrice(price: number, currency: string): string {
        return new Intl.NumberFormat('fr-CH', {
            style: 'currency',
            currency: currency,
        }).format(price)
    }

    formatDateTime(dateTime: string): string {
        return new Date(dateTime).toLocaleString()
    }

    exportToPdf(): void {
        const urlToPrint =
            `/logistics/#/prescription/price/print?` +
            new URLSearchParams({
                codes: this.orderPricingRequestDto.toString(),
            })

        const urlApi =
            '/report/?' +
            new URLSearchParams({
                url: window.btoa(unescape(encodeURIComponent(urlToPrint))),
                scale: (0.7).toString(),
            })

        const urlPdfViewer =
            '/pdf/web/viewer.html?' + new URLSearchParams({ file: urlApi })

        const pdfWindow = window.open('', '_blank')
        if (pdfWindow) {
            pdfWindow.location.href = urlPdfViewer
        }
    }
}
