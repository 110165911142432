﻿export enum Status {
    pending = 'pending',
    accepted = 'accepted',
    done = 'done',
    rejected = 'rejected',
    cancelled = 'cancelled',
}

export const statusText: Record<Status, string> = {
    [Status.pending]: 'courier_overview:status.pending',
    [Status.accepted]: 'courier_overview:status.accepted',
    [Status.done]: 'courier_overview:status.done',
    [Status.rejected]: 'courier_overview:status.rejected',
    [Status.cancelled]: 'courier_overview:status.cancelled',
}
