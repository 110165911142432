﻿import Vue from 'vue'
import VueRouter, { RouteConfig, Route, NavigationGuardNext } from 'vue-router'
import { useCurrentUserStore } from '../../stores/currentUser'
import {
    redirectToHomePage,
    redirectToLoginPage,
    redirectToLogout,
} from './redirects'
import { Roles } from '../../api/ImedsApi'

export enum RouteName {
    Couriers = 'logistics:courier',
    Nurses = 'logistics:nurses',
    Additions = 'logistics:additions',
    NursePrint = 'NursePrint',
    AdditionPrint = 'AdditionPrint',
    Logout = 'Logout',
    PrescriptionOrderQuotationPrint = 'PrescriptionOrderQuotationPrint',
    Default = 'Default',
}

export function createRouter(): VueRouter {
    Vue.use(VueRouter)

    const routesByName: Record<
        RouteName,
        Omit<RouteConfig, 'name'>
    > = Object.freeze({
        [RouteName.Couriers]: {
            path: '/courier',
            component: () => import('./pages/courier/LogisticsCourier.vue'),
            meta: {
                roles: [Roles.CourierLogistics, Roles.Logistics],
                menuItem: {
                    icon: 'mdi-truck-fast',
                },
            },
        },
        [RouteName.Nurses]: {
            path: '/nurse',
            component: () => import('./pages/nurse/LogisticsNurse.vue'),
            meta: {
                roles: [Roles.NurseLogistics, Roles.Logistics],
                menuItem: {
                    icon: 'mdi-doctor',
                },
            },
        },
        [RouteName.Additions]: {
            path: '/addition',
            component: () => import('./pages/addition/LogisticsAddition.vue'),
            meta: {
                roles: [Roles.AdditionLogistics, Roles.Logistics],
                menuItem: {
                    icon: 'mdi-card-plus',
                },
            },
        },
        [RouteName.NursePrint]: {
            path: '/nurse/print',
            component: () =>
                import('./pages/nurse/NurseLogisticsTablePrint.vue'),
            props: true,
            meta: {
                printable: true,
                roles: [Roles.NurseLogistics, Roles.Logistics],
            },
        },
        [RouteName.AdditionPrint]: {
            path: '/addition/print',
            component: () =>
                import('./pages/addition/AdditionsLogisticsTablePrint.vue'),
            props: true,
            meta: {
                printable: true,
                roles: [Roles.AdditionLogistics, Roles.Logistics],
            },
        },
        [RouteName.Logout]: {
            path: '/logout',
            component: {
                beforeRouteEnter() {
                    redirectToLogout()
                },
            },
            meta: {
                menuItem: {
                    icon: 'mdi-logout-variant',
                },
            },
        },
        [RouteName.PrescriptionOrderQuotationPrint]: {
            path: '/prescription/price/print',
            component: () =>
                import(
                    './pages/prescription/PrescriptionOrderQuotationPrint.vue'
                ),
            props: true,
            meta: {
                printable: true,
            },
        },
        [RouteName.Default]: {
            path: '*',
            component: {
                async beforeRouteEnter(
                    to: Route,
                    from: Route,
                    next: NavigationGuardNext
                ): Promise<void> {
                    const currentUserStore = useCurrentUserStore()
                    await currentUserStore.load()

                    // redirect to the first visible menu item if there is one
                    const firstVisibleMenuItem = getVisibleMenuItems(
                        router,
                        currentUserStore.roles
                    ).find(
                        (visibleMenuItem) =>
                            visibleMenuItem.name !== RouteName.Logout
                    )
                    if (firstVisibleMenuItem) {
                        next({ path: firstVisibleMenuItem.link, replace: true })
                        return
                    }

                    // redirect to home.aspx page if there are no menu item to redirect to
                    console.warn(
                        'No visible menu item. Redirecting to home page.',
                        {
                            from,
                            to,
                        }
                    )
                    redirectToHomePage()
                },
            },
        },
    })

    const router = new VueRouter({
        mode: 'hash',
        base: import.meta.env.BASE_URL,
        routes: Object.entries(routesByName).map(([name, routeConfig]) => ({
            ...routeConfig,
            name,
        })),
    })

    router.beforeEach(async (to, from, next) => {
        const currentUserStore = useCurrentUserStore()
        await currentUserStore.load()

        // redirect to login page if current user is not authenticated
        if (!currentUserStore.authenticated) {
            console.warn('User not authenticated. Redirecting to login page.')
            redirectToLoginPage()
            return
        }

        // redirect to the default route if current user is not allowed
        if (
            to?.name !== RouteName.Default &&
            !isUserAllowed(to?.meta?.roles, currentUserStore.roles)
        ) {
            next({ name: RouteName.Default, replace: true })
            return
        }

        // continue loading route if current user is authenticated and allowed or this is the default route
        next()
    })

    return router
}

export function isUserAllowed(
    requiredRoles: string[] | undefined,
    userRoles: string[] | void
): boolean {
    return requiredRoles != undefined
        ? requiredRoles.some((requiredRole) =>
              userRoles?.includes(requiredRole)
          )
        : true
}

export function getVisibleMenuItems(
    router: VueRouter,
    currentUserRoles: string[]
): MenuItem[] {
    return (
        router.options?.routes?.flatMap((route) =>
            route.meta?.menuItem &&
            route.name &&
            isUserAllowed(route.meta.roles, currentUserRoles)
                ? [
                      {
                          ...route.meta.menuItem,
                          name: route.name,
                          link: route.path,
                      },
                  ]
                : []
        ) ?? []
    )
}

declare module 'vue-router' {
    interface RouteMeta {
        printable?: boolean
        roles?: string[]
        menuItem?: MenuItemMeta
    }
}

export interface MenuItemMeta {
    icon: string
}

export interface MenuItem extends MenuItemMeta {
    name: string
    link: string
}
