import { dymoConnectPrintDriver } from './dymo/dymoConnectPrintDriver'
import { zebraBrowserPrintDriver } from './zebra/zebraBrowserPrintDriver'
import { zebraWcpp2PrintDriver } from './zebra/zebraWcpp2PrintDriver'
import { zebraNetworkPrintDriver } from './zebra/zebraNetworkPrintDriver'
import { PrintDriverTypeCode } from '../../../api/ImedsApi'
import { PrintDriver } from './PrintDriver'

export const drivers: readonly PrintDriver[] = [
    dymoConnectPrintDriver,
    zebraBrowserPrintDriver,
    zebraWcpp2PrintDriver,
    zebraNetworkPrintDriver,
]

export const driversByPrintDriverType = Object.freeze(
    Object.fromEntries(
        drivers.map((driver) => [driver.printDriverType, driver])
    )
) as {
    readonly [TPrintDriverTypeCode in PrintDriverTypeCode]: PrintDriver<TPrintDriverTypeCode>
}

export const printDriverTypes = Object.freeze(
    drivers.map((driver) => ({
        name: driver.name,
        type: driver.printDriverType,
    }))
)

export * from './ConnectedPrinter'
export * from './PrintDriver'
