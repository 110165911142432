import { Component, Ref } from 'vue-property-decorator'
import PrescriptionDoctorsTable from './PrescriptionDoctorsTable'
import Vue from 'vue'
import { KhIcon, KhButton, KhTooltip } from '@internal-libraries/kheops-ui-lib'

@Component({
    components: { KhIcon, KhButton, KhTooltip },
})
export default class PrescriptionDoctorSearchButton extends Vue {
    private searchModal = false

    @Ref('doctorTable')
    readonly doctorTable?: PrescriptionDoctorsTable

    reloadDatatable(): void {
        this.doctorTable?.reload()
    }

    addDoctor(id: number) {
        this.searchModal = false
        this.$emit('add-doctor', id)
    }
}
