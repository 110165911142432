import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { VisitDto } from '../../../api/ImedsApi'

@Component
export default class PrescriptionPatientTable extends Vue {
    @Prop()
    visits!: VisitDto[]

    headers = [
        {
            text: this.$t('patientSearch:visitNumber'),
            value: 'visitNumber',
        },
        {
            text: this.$t('patientSearch:frequencyId'),
            value: 'frequencyId',
        },
        {
            text: this.$t('patientSearch:entryDate'),
            value: 'entryDate',
        },
        {
            text: this.$t('patientSearch:releaseDate'),
            value: 'releaseDate',
        },
    ]

    setSelectedVisit(visit: VisitDto) {
        this.$emit('visitSelected', visit)
    }
}
