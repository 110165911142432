import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {
    KhSnackbarManager,
    SnackbarManager,
    Snack,
    SnackDelay,
    SnackColor,
} from '@internal-libraries/kheops-ui-lib'
import {
    ImedsNotificationSeverity,
    useNotificationStore,
} from '../../stores/notification'

@Component({ components: { KhSnackbarManager } })
export default class ImedsNotifications extends Vue {
    readonly notificationStore = useNotificationStore()
    unsubscribeNotificationStore?: () => void

    readonly snackbarManager: SnackbarManager = new SnackbarManager(
        doNothing,
        doNothing,
        doNothing
    )

    created() {
        this.unsubscribeNotificationStore = this.notificationStore.$subscribe(
            (_, state) => {
                if (!state.notifications.length) {
                    return
                }

                state.notifications.forEach((notification) => {
                    this.snackbarManager.addSnack(
                        new Snack(
                            this.$t(notification.message),
                            imedsNotificationSeveritySnackColors[
                                notification.severity
                            ],
                            SnackDelay.long
                        )
                    )
                })

                this.notificationStore.acknowledgeAll()
            }
        )
    }

    destroyed() {
        this.unsubscribeNotificationStore?.()
    }
}

const imedsNotificationSeveritySnackColors: {
    [severity in ImedsNotificationSeverity]: SnackColor
} = {
    [ImedsNotificationSeverity.Success]: SnackColor.success,
    [ImedsNotificationSeverity.Error]: SnackColor.error,
    [ImedsNotificationSeverity.Info]: SnackColor.info,
    [ImedsNotificationSeverity.Warning]: SnackColor.warning,
}

const doNothing = () => {
    /* do nothing */
}
