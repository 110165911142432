import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { useClient } from '../../api/clients'
import {
    PrescriptionAdminClient,
    PrescriptionRedirectionDto,
    SearchTypeDto,
    UserPrescriptionAdminClient,
    UserPrescriptionAdminDto,
} from '../../api/ImedsApi'
import { useErrorStore } from '../../stores/error'
import { useNotificationStore } from '../../stores/notification'

@Component
export default class ImedsUserPrescriptionAdminForm extends Vue {
    @Prop()
    user!: string

    readonly errorStore = useErrorStore()
    readonly notificationStore = useNotificationStore()
    readonly userPrescriptionAdminClient = useClient(
        UserPrescriptionAdminClient
    )
    readonly prescriptionAdminClient = useClient(PrescriptionAdminClient)

    private redirections: PrescriptionRedirectionDto[] = []
    private searchTypes: SearchTypeDto[] = []
    private openInNewTab = false
    private searchType: number | null = null
    private redirection: number | null = null
    private login: string | null = null
    private password: string | null = null

    async mounted() {
        const userConfig: UserPrescriptionAdminDto =
            await this.userPrescriptionAdminClient.getUserPrescription(
                this.user
            )

        this.openInNewTab = userConfig.openInNewTab ?? false
        this.searchType = userConfig.searchTypeId ?? null

        this.redirection = userConfig.remoteId ?? null
        this.login = userConfig.remoteLogin ?? null
        this.password = userConfig.remotePassword ?? null

        this.redirections = [
            { id: null, url: 'local' } as unknown as PrescriptionRedirectionDto,
        ].concat(await this.prescriptionAdminClient.getRedirections())

        this.searchTypes = await this.prescriptionAdminClient.getSearchTypes()
    }
    async save() {
        try {
            await this.userPrescriptionAdminClient.updateUserPrescription(
                this.user,
                {
                    openInNewTab: this.openInNewTab,
                    searchTypeId: this.searchType ?? undefined,
                    remoteId: this.redirection ?? undefined,
                    remoteLogin: this.redirection
                        ? this.login ?? undefined
                        : undefined,
                    remotePassword: this.redirection
                        ? this.password ?? undefined
                        : undefined,
                }
            )
            this.notificationStore.notifySuccess(
                this.$t('imeds:update_success')
            )
        } catch (error) {
            this.notificationStore.notifyError(
                this.$t('imeds:unknown_error_message')
            )
        }
    }
}
