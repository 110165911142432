import { useTranslation } from './useTranslation'
import Vue, { watch } from 'vue'

function forEachVueDeep(vs: Vue[], f: (vue: Vue) => void) {
    vs.forEach((vue) => {
        f(vue)
        forEachVueDeep(vue.$children, f)
    })
}

// This is a dirty workaround for change detection that does not work correctly with i18next-vue on firefox for an
// unknown reason.
// TODO: fix issue with vue-i18next and remove this function
export function forceUpdateOnTranslationUpdates(...vues: Vue[]) {
    const { t } = useTranslation()
    watch(t, () => {
        forEachVueDeep(vues, (vue) => vue.$forceUpdate())
    })
}
