﻿import { Component, Prop, Emit, Vue } from 'vue-property-decorator'
import { chain } from 'lodash'
import { KhDatePicker } from '@internal-libraries/kheops-ui-lib'
import {
    MicrobiologyAnalysisDto,
    MicrobiologySampleDto,
    MicrobiologySelectedSampleDto,
} from '../../../api/ImedsApi'

@Component({
    components: { KhDatePicker },
})
export default class ImedsMicrobiologyAnalysesList extends Vue {
    selectedAnalysesIds: number[] = []
    comment = ''
    samplingDate: Date = new Date()
    showSamplingDatePicker = false

    @Prop({ required: true })
    readonly sample!: MicrobiologySampleDto

    @Prop({ default: null })
    readonly selectedSample!: MicrobiologySelectedSampleDto | null

    @Prop({ default: 'microbio:add' })
    readonly submitActionLabel!: string

    mounted() {
        if (!this.selectedSample) return

        this.selectedAnalysesIds = this.selectedSample.selectedAnalyses
        this.comment = this.selectedSample.comment ?? ''
        this.samplingDate = this.selectedSample.samplingDate
    }

    get analysesByCategory(): CategoryWithAnalyses[] {
        return chain(this.sample.analyses)
            .filter((analysis) => !analysis.isDeleted)
            .sortBy((analysis) => analysis.name)
            .groupBy((analysis) => analysis.category.id)
            .values()
            .map((analyses) => ({
                categoryId: analyses[0].category.id,
                categoryName: analyses[0].category.name,
                analyses,
            }))
            .value()
    }

    @Emit()
    submit(): MicrobiologySelectedSampleDto {
        this.setResetTimeout()
        return {
            sampleId: this.sample.id,
            samplingDate: this.samplingDate,
            comment: this.comment,
            selectedAnalyses: this.selectedAnalysesIds,
        }
    }

    @Emit()
    cancel(): void {
        this.setResetTimeout()
    }

    /**
     * Resets the component data soon in the future, so there is some time left to handle events and animate
     * before the component is empty.
     */
    setResetTimeout(): void {
        setTimeout(() => {
            this.selectedAnalysesIds = []
            this.comment = ''
            this.samplingDate = new Date()
            this.showSamplingDatePicker = false
        }, 400)
    }
}

interface CategoryWithAnalyses {
    categoryId: number
    categoryName: string
    analyses: MicrobiologyAnalysisDto[]
}
