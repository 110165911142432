import ImedsDataTable from '../../ImedsDataTable.vue'
import {
    DataTableConfigurationDto,
    SortDirection,
    PrescriptionDoctorsClient,
    ActionCodes,
    PrescriptionDoctorDto,
} from '../../../api/ImedsApi'
import {
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    PagedResultDto,
    ItemAction,
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
} from '../../ImedsDataTable'
import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { anyLoading, loadable, Loadable } from '../../../utils/Loadable'
import { useClient } from '../../../api/clients'
import { useErrorStore } from '../../../stores/error'
import { KhIcon } from '@internal-libraries/kheops-ui-lib'

@Component({
    components: { ImedsDataTable, KhIcon },
    subscriptions(this: PrescriptionDoctorsTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.prescriptionDoctorsClient.getConfiguration()))

        const loadablePagedResult: Loadable<PagedResultDto<unknown>> = loadable(
            combineLatest([
                combineLatest([this.page$, this.pageSize$]),
                combineLatest([this.sortBy$, this.sortDirections$]),
                combineLatest([
                    this.doctorFirstName$,
                    this.doctorLastName$,
                    this.doctorCity$,
                ]),
                combineLatest([this.refreshActionSubject$]),
            ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
            ([
                [page, pageSize],
                [sortBy, sortDirections],
                [doctorFirstName, doctorLastName, doctorCity],
            ]) =>
                from(
                    this.prescriptionDoctorsClient.doctors(
                        sortBy,
                        sortDirections,
                        pageSize,
                        page,
                        true,
                        doctorFirstName,
                        doctorLastName,
                        doctorCity
                    )
                )
        )
        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            doctorFirstName: this.doctorFirstName$,
            doctorLastName: this.doctorLastName$,
            doctorCity: this.doctorCity$,
        })
    },
})
export default class PrescriptionDoctorsTable extends Vue {
    readonly prescriptionDoctorsClient = useClient(PrescriptionDoctorsClient)
    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])

    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly doctorFirstName$ = new BehaviorSubject<string | undefined>(
        undefined
    )
    readonly doctorLastName$ = new BehaviorSubject<string | undefined>(
        undefined
    )
    readonly doctorCity$ = new BehaviorSubject<string | undefined>(undefined)

    async onItemAction(
        itemAction: ItemAction<PrescriptionDoctorDto>
    ): Promise<void> {
        if (itemAction.actionCode == ActionCodes.Prescription.DoctorCopy.Add) {
            this.$emit('addDoctor', itemAction.item)
        }
    }

    public reload() {
        this.refreshActionSubject$.next()
    }
}
