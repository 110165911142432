import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

//TODO: move this component in the kh-ui-lib
@Component
export default class ReloadButton extends Vue {
    @Prop()
    loading!: boolean

    @Prop({ default: null })
    tooltip?: string | null
    emitReload() {
        this.$emit('reload')
    }
}
