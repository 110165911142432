import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ImedsVersion from './ImedsVersion.vue'
import { KhButton, KhModal, KhIcon } from '@internal-libraries/kheops-ui-lib'
import { FooterTextDto, FootersClient } from '../../api/ImedsApi'
import { useClient } from '../../api/clients'

@Component({ components: { ImedsVersion, KhButton, KhModal, KhIcon } })
export default class ImedsFooter extends Vue {
    readonly footerClient = useClient(FootersClient)

    @Prop({ type: Boolean, default: false })
    sticky!: boolean

    @Prop({ type: Boolean, default: false })
    app!: boolean

    @Prop({ type: Boolean, default: false })
    inset!: boolean
    dialogs: boolean[] = []
    footerTexts: FooterTextDto[] = []
    async created(): Promise<void> {
        this.footerTexts = await this.footerClient.getFooterTexts()
    }
}
