import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { useClient } from '../../api/clients'
import {
    LabelsClient,
    PatientLabelDto,
    PrintDataType,
} from '../../api/ImedsApi'
import { useErrorStore } from '../error'
import {
    ConnectedPrinter,
    driversByPrintDriverType,
    PrintDriver,
} from './drivers'
import { usePrintersStore } from './PrintersStore'

type ConnectedPrinterOrDefault = ConnectedPrinter | null | undefined | false

interface PrintDataGetter {
    (printer: PrintDataType): Promise<string[]>
}

interface PrintJob {
    printer: ConnectedPrinterOrDefault
    getPrintData: PrintDataGetter
}

export const usePrintStore = defineStore('print', () => {
    const labelsClient = useClient(LabelsClient)
    const errorStore = useErrorStore()

    const printersStore = usePrintersStore()

    const jobs = ref<PrintJob[]>([])

    const runJob = async ({
        getPrintData,
        printer,
    }: PrintJob): Promise<void> => {
        if (!printer) {
            const userPrinter =
                printersStore.selectedPrinter ?? printersStore.defaultPrinter

            if (!userPrinter) {
                errorStore.handleError('print:no_configured_printer')
                return
            }

            printer = userPrinter.connectedPrinter
        }

        if (!printer) {
            errorStore.handleError('print:print_error_disconnected')
            return
        }

        const driver: PrintDriver = driversByPrintDriverType[printer.driverType]
        const validPrinter = printer
        await errorStore.tryOrHandleError(
            async () => {
                await driver.print(
                    validPrinter,
                    await getPrintData(driver.printDataType)
                )
            },
            { message: 'print:print_error' }
        )
    }

    watch(
        [
            jobs,
            () => printersStore.loading,
            () => printersStore.connectedPrintersLoading,
        ],
        async ([
            newJobs,
            printersLoading,
            connectedPrintersLoading,
        ]): Promise<void> => {
            if (!newJobs.length || printersLoading || connectedPrintersLoading)
                return

            for (const job of newJobs) {
                await runJob(job)
            }

            jobs.value = []
        }
    )

    const enqueueJob = (
        getPrintData: PrintDataGetter,
        printer?: ConnectedPrinterOrDefault
    ): void => {
        jobs.value = [...jobs.value, { printer, getPrintData }]
    }

    return {
        printTestLabels: (printer?: ConnectedPrinter | null | false): void =>
            enqueueJob(
                async (printDataType) =>
                    await labelsClient.getTestLabels(printDataType),
                printer
            ),
        printOrders: (...orderIds: number[]): void =>
            enqueueJob(
                async (printDataType) =>
                    await labelsClient.getPrescriptionLabels(
                        printDataType,
                        orderIds
                    )
            ),
        printPatient: (patient: PatientLabelDto): void =>
            enqueueJob(async (printDataType) => [
                await labelsClient.getPatientLabel(printDataType, patient),
            ]),
        printPatientPostal: (patient: PatientLabelDto): void =>
            enqueueJob(async (printDataType) => [
                await labelsClient.getPatientPostalLabel(
                    printDataType,
                    patient
                ),
            ]),
    }
})

export type PrintStore = ReturnType<typeof usePrintStore>
