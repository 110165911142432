import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { KhButton, KhIcon, KhModal } from '@internal-libraries/kheops-ui-lib'
import { AnalysisProfileDto } from '../../../../api/ImedsApi'
import { useAnalysisStore } from '../../../../stores/prescription/analysis'
import { useAnalysisProfileStore } from '../../../../stores/prescription/analysisProfile'
import { useMicrobiologyStore } from '../../../../stores/prescription/microbiology'
import ImedsAnalysisProfileContentList from '../ImedsAnalysisProfileContentList.vue'

@Component({
    components: {
        KhModal,
        KhButton,
        KhIcon,
        ImedsAnalysisProfileContentList,
    },
})
export default class ImedsAnalysisProfileEditionModal extends Vue {
    readonly analysesStore = useAnalysisStore()
    readonly analysisProfileStore = useAnalysisProfileStore()
    readonly microbiologyStore = useMicrobiologyStore()

    name = ''
    deleteModal = false

    @Prop({ default: false })
    readonly value!: boolean

    get selectedProfile(): AnalysisProfileDto | null {
        return this.analysisProfileStore.selectedProfile
    }

    @Watch('value')
    syncNameWithSelectedProfile(): void {
        this.name = this.selectedProfile?.name ?? ''
    }

    @Emit('input')
    close(): null {
        this.deleteModal = false
        return null
    }

    get isEditButtonEnabled(): boolean {
        return (
            !!this.selectedProfile &&
            this.analysesStore.selectedAnalyses.length +
                this.microbiologyStore.selectedSamples.length >
                0
        )
    }

    async editProfile(): Promise<void> {
        await this.analysisProfileStore.updateProfile(this.name)
        this.close()
    }

    async deleteProfile(): Promise<void> {
        if (!this.selectedProfile) return

        await this.analysisProfileStore.deleteProfile(this.selectedProfile.id)
        this.close()
    }
}
