import { md5Base62 } from './md5Base62'
import { TFunction } from 'i18next'
import { fallbackToEnglishExceptForFrench } from './fallbackToEnglishExceptForFrench'

const hashKeyByText: Map<string, string> = new Map()

/**
 * Converts some text to a hash that can be used as a translation key.
 * @param text The text to convert to a hash key
 * @returns A MD5 hash encoded in base62
 */
const textToHashKey = (text: string): string => {
    let hashKey = hashKeyByText.get(text)

    if (hashKey === undefined) {
        hashKey = md5Base62(text)
        hashKeyByText.set(text, hashKey)
    }

    return hashKey
}

/**
 * Tries to translate some text by using its md5 base62 hash as the translation key.
 * @param $t Translation function from i18next
 * @param namespace The translation namespace
 * @param text The text to translate
 * @returns The translated text or the original text if no translation is found.
 */
export const hashTranslate = (
    $t: TFunction,
    namespace: string,
    text: string | undefined
): string =>
    text
        ? $t(textToHashKey(text), {
              ns: namespace,
              defaultValue: text,
              fallbackLng: fallbackToEnglishExceptForFrench,
          })
        : ''
