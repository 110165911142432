import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import ImedsDataTable from '../../ImedsDataTable.vue'
import {
    DataTableConfigurationDto,
    SortDirection,
    ActionCodes,
    PrescriptionOrdersClient,
    PrescriptionOrderDto,
    DossierPrescriptionStatusType,
} from '../../../api/ImedsApi'
import {
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    PagedResultDto,
    ItemAction,
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
} from '../../ImedsDataTable'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'

import { anyLoading, loadable, Loadable } from '../../../utils/Loadable'
import { useClient } from '../../../api/clients'
import { useErrorStore } from '../../../stores/error'
import { VSelectItem } from '../../Courier/VSelectItem'
import ImedsUserPrinterSelector from '../../Printers/ImedsUserPrinterSelector.vue'
import { usePrintStore } from '../../../stores/print/PrintStore'
import { vMaska } from 'maska'
import { DirectiveFunction } from 'vue'

@Component({
    components: { ImedsDataTable, ImedsUserPrinterSelector },
    subscriptions(this: PrescriptionOrdersTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.prescriptionOrdersClient.getConfiguration()))

        const loadablePagedResult: Loadable<PagedResultDto<unknown>> = loadable(
            combineLatest([
                combineLatest([this.page$, this.pageSize$]),
                combineLatest([this.sortBy$, this.sortDirections$]),
                combineLatest([this.samplingDateFrom$, this.samplingDateTo$]),
                combineLatest([
                    this.patientFirstName$,
                    this.patientLastName$,
                    this.patientBirthday$,
                ]),
                combineLatest([this.isEmergencyOnly$, this.status$]),
                combineLatest([
                    this.imedsOrderNumber$,
                    this.visitId$,
                    this.refreshActionSubject$,
                ]),
            ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
            ([
                [page, pageSize],
                [sortBy, sortDirections],
                [samplingDateFrom, samplingDateTo],
                [patientFirstName, patientLastName, patientBirthday],
                [isEmergencyOnly, status],
                [imedsOrderNumber, visitId],
            ]) =>
                from(
                    this.prescriptionOrdersClient.orders(
                        sortBy,
                        sortDirections,
                        pageSize,
                        page,
                        true,
                        samplingDateFrom,
                        samplingDateTo,
                        patientFirstName,
                        patientLastName,
                        patientBirthday,
                        isEmergencyOnly,
                        imedsOrderNumber,
                        visitId,
                        status ?? undefined
                    )
                )
        )
        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            samplingDateFrom: this.samplingDateFrom$,
            samplingDateTo: this.samplingDateTo$,
            patientFirstName: this.patientFirstName$,
            patientLastName: this.patientLastName$,
            patientBirthday: this.patientBirthday$,
            isEmergencyOnly: this.isEmergencyOnly$,
            imedsOrderNumber: this.imedsOrderNumber$,
            visitId: this.visitId$,
            status: this.status$,
        })
    },
    directives: { maska: vMaska as DirectiveFunction },
})
export default class PrescriptionOrdersTable extends Vue {
    readonly prescriptionOrdersClient = useClient(PrescriptionOrdersClient)
    readonly errorStore = useErrorStore()
    readonly printStore = usePrintStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])

    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)

    readonly samplingDateFrom$ = new BehaviorSubject<Date | undefined>(
        undefined
    )
    readonly samplingDateTo$ = new BehaviorSubject<Date | undefined>(undefined)
    readonly patientFirstName$ = new BehaviorSubject<string | undefined>(
        undefined
    )
    readonly patientLastName$ = new BehaviorSubject<string | undefined>(
        undefined
    )
    readonly patientBirthday$ = new BehaviorSubject<Date | undefined>(undefined)
    readonly isEmergencyOnly$ = new BehaviorSubject<boolean | undefined>(
        undefined
    )
    readonly imedsOrderNumber$ = new BehaviorSubject<number | undefined>(
        undefined
    )
    readonly visitId$ = new BehaviorSubject<string | undefined>(undefined)
    readonly status$ = new BehaviorSubject<
        DossierPrescriptionStatusType | undefined
    >(undefined)

    cancelModal = false
    selectedOrderId: number | null = null

    imedsOrderNumber: string | undefined
    setImedsOrderNumber(value: string | undefined) {
        this.imedsOrderNumber = value ? value.replace(/\D/g, '') : undefined
        this.imedsOrderNumber$.next(
            this.imedsOrderNumber ? parseInt(this.imedsOrderNumber) : undefined
        )
    }
    onItemAction(itemAction: ItemAction<PrescriptionOrderDto>): void {
        switch (itemAction.actionCode) {
            case ActionCodes.Prescription.Order.Edit:
                this.$emit('editOrder', itemAction.item.id)
                break
            case ActionCodes.Prescription.Order.Print:
                this.printStore.printOrders(itemAction.item.id)
                break
            case ActionCodes.Prescription.Order.Copy:
                this.$emit('copyOrder', itemAction.item.id)
                break
            case ActionCodes.Prescription.Order.Cancel:
                this.selectedOrderId = itemAction.item.id
                this.cancelModal = true
                break
        }
    }

    public reload() {
        this.refreshActionSubject$.next()
    }

    async cancelOrder() {
        if (!this.selectedOrderId) return

        await this.prescriptionOrdersClient.cancelOrder(this.selectedOrderId)
        this.refreshActionSubject$.next()
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<string>[] {
        return [
            {
                value: DossierPrescriptionStatusType.Pending,
                text: this.$t('prescription:status_pending'),
            },
            {
                value: DossierPrescriptionStatusType.Received,
                text: this.$t('prescription:status_received'),
            },
            {
                value: DossierPrescriptionStatusType.Cancelled,
                text: this.$t('prescription:status_cancelled'),
            },
        ]
    }
}
