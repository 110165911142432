import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'

@Component
export default class KhEllipsis extends Vue {
    @Ref()
    text?: HTMLElement

    isTooltipEnabled = false

    async mounted() {
        await this.$nextTick()
        this.checkOverflow()
    }

    checkOverflow(): void {
        if (this.text) {
            this.isTooltipEnabled =
                this.text.offsetWidth < this.text.scrollWidth
        } else {
            this.isTooltipEnabled = false
        }
    }
}
