import { KhComponent } from '@internal-libraries/kheops-ui-lib'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import { CompanyDto, OfficeDto, OfficeEditionDto } from '../../api/ImedsApi'
import { useErrorStore } from '../../stores/error'
import {} from 'vuetify/types'

@Component
export default class ImedsOfficeForm extends KhComponent {
    readonly errorStore = useErrorStore()

    @Prop()
    readonly companies!: CompanyDto[]

    @Prop({
        default: null,
    })
    readonly office!: OfficeDto | null

    @Ref('form')
    readonly form!: HTMLFormElement & {
        validate(): boolean
    }

    internalOffice: Partial<OfficeEditionDto & { id: number }> = {}

    @Watch('office', { deep: true, immediate: true })
    setInternalOffice(): void {
        this.internalOffice = {
            id: this.office?.id,
            name: this.office?.name ?? '',
            code: this.office?.code ?? '',
            address: this.office?.address ?? '',
            phoneNumber: this.office?.phoneNumber ?? '',
            companyId: this.office?.companyId,
        }
    }

    cancel() {
        this.$emit('cancel')
    }

    isValid: boolean | null = null

    edit() {
        this.form.validate()
        if (this.isValid) this.$emit('edit', this.internalOffice)
    }

    create() {
        this.form.validate()
        if (this.isValid) this.$emit('create', this.internalOffice)
    }
}
