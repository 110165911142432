import { Vue, Component, Prop } from 'vue-property-decorator'
import { ImedsIcon } from '../index'
import { useClient } from '../../api/clients'
import { ContactClient } from '../../api/ImedsApi'

@Component({ components: { ImedsIcon } })
export default class ImedsContactButton extends Vue {
    @Prop({ default: '', type: String })
    color!: string

    @Prop({ default: '', type: String })
    size!: string

    readonly contactClient = useClient(ContactClient)
    modal = false

    template: string | null = null

    async mounted() {
        this.template = await this.contactClient.getContactTemplate()
    }
}
