﻿import { Component, Vue } from 'vue-property-decorator'
import { format } from 'date-fns'
import { sortIcons, SortOrder } from './SortOrder'
import {
    SelectedSampleWithCatalog,
    useMicrobiologyStore,
} from '../../../stores/prescription/microbiology'
import {
    MicrobiologySampleDto,
    MicrobiologySelectedSampleDto,
} from '../../../api/ImedsApi'
import ImedsMicrobiologyAnalysesPicker from './ImedsMicrobiologyAnalysesPicker.vue'
import KhEllipsis from '../../KhEllipsis/KhEllipsis.vue'

@Component({ components: { ImedsMicrobiologyAnalysesPicker, KhEllipsis } })
export default class ImedsMicrobiologyCart extends Vue {
    readonly microbiologyStore = useMicrobiologyStore()

    editedSelectedSample: MicrobiologySelectedSampleDto | null = null

    get selectedSamples(): SelectedSampleWithCatalog[] {
        return this.microbiologyStore.selectedSamplesWithCatalog
    }

    get editedSample(): MicrobiologySampleDto | null {
        return this.editedSelectedSample
            ? this.microbiologyStore.findSampleById(
                  this.editedSelectedSample.sampleId
              )
            : null
    }

    get sortIcon(): string {
        return sortIcons[
            this.microbiologyStore.selectedSamplesWithCatalogSortOrder
        ]
    }

    submitEdition(newSelectedSample: MicrobiologySelectedSampleDto): void {
        if (!this.editedSelectedSample) return

        this.microbiologyStore.updateSelectedSample(
            this.editedSelectedSample,
            newSelectedSample
        )

        this.closeEdition()
    }

    closeEdition(): void {
        this.editedSelectedSample = null
    }

    unselectSample(sample: MicrobiologySelectedSampleDto): void {
        this.microbiologyStore.unselectSample(sample)
    }

    unselectAnalysis(
        sample: MicrobiologySelectedSampleDto,
        analysisId: number
    ): void {
        this.microbiologyStore.unselectAnalysis(sample, analysisId)
    }

    formatDate(date: Date): string {
        return format(date, 'dd.MM.yyyy HH:mm')
    }

    toggleSortOrder(): void {
        this.microbiologyStore.selectedSamplesWithCatalogSortOrder =
            this.microbiologyStore.selectedSamplesWithCatalogSortOrder ===
            SortOrder.ASC
                ? SortOrder.DESC
                : SortOrder.ASC
    }
}
