﻿import { Icon } from '../../../api/ImedsApi'

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
}

export const sortIcons: Record<SortOrder, Icon> = {
    [SortOrder.ASC]: Icon.SortAsc,
    [SortOrder.DESC]: Icon.SortDesc,
}
