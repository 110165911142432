import Vue from 'vue'
import Component from 'vue-class-component'
import { max } from 'lodash/fp'
import { useClient } from '../../api/clients'
import { VersionClient, VersionDto } from '../../api/ImedsApi'
import { Prop } from 'vue-property-decorator'

@Component
export default class ImedsVersion extends Vue {
    readonly versionClient = useClient(VersionClient)

    @Prop()
    attach?: string

    @Prop({ default: () => [] })
    versions!: VersionDto[]

    asyncVersions: VersionDto[] = []

    dialog = false

    get allVersions(): VersionDto[] {
        return [...this.versions, ...this.asyncVersions, thisUiVersion]
    }

    get shortNewestVersion(): string {
        return (
            max(
                this.allVersions
                    .filter(({ version }) => version)
                    .map(({ version }) =>
                        formatVersion({ target: 'Imeds', version })
                    )
            ) ?? 'Unknown Imeds version'
        )
    }

    get formattedVersions(): string[] {
        return this.allVersions
            .map((versionInfo) => formatVersion(versionInfo))
            .filter((x) => x)
    }

    async copyVersionsToClipboard(): Promise<void> {
        await navigator.clipboard.writeText(
            [this.shortNewestVersion, ...this.formattedVersions].join('\n')
        )
    }

    async created(): Promise<void> {
        const webapiVersion = await this.versionClient.get()
        this.asyncVersions.push(webapiVersion)
    }
}

const thisUiVersion: VersionDto = {
    target: import.meta.env.VUE_APP_TARGET ?? '',
    version: import.meta.env.VUE_APP_VERSION ?? '',
    hash: import.meta.env.VUE_APP_COMMIT_HASH ?? '',
}

function tryPrefix(
    prefix: string,
    ...values: (string | null | undefined)[]
): string {
    const concatValues = ''.concat(...(values.filter((v) => v) as string[]))
    return concatValues ? prefix + concatValues : ''
}

function formatVersion(
    versionInfo: Partial<VersionDto> & Pick<VersionDto, 'target'>
): string {
    return tryPrefix(
        versionInfo.target ?? '',
        tryPrefix(' v', versionInfo.version),
        tryPrefix(' #', versionInfo.hash)
    )
}
