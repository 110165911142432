import { Component } from 'vue-property-decorator'
import ImedsEditionFormPatientModal from './ImedsEditionFormPatientModal.vue'
import Vue from 'vue'
import { KhIcon, KhButton, KhTooltip } from '@internal-libraries/kheops-ui-lib'

@Component({
    components: {
        ImedsEditionFormPatientModal,
        KhIcon,
        KhButton,
        KhTooltip,
    },
})
export default class ImedsPatientEditionButton extends Vue {
    editionModal = false
}
