<script setup lang="ts">
import { ref, onMounted } from 'vue'

const headerElement = ref<HTMLDivElement | null>(null)
const footerElement = ref<HTMLDivElement | null>(null)

const cssVariables = ref<{
    ['--header-height']?: `${number}px`
    ['--footer-height']?: `${number}px`
}>({})

onMounted(() => {
    cssVariables.value = {
        '--header-height': `${headerElement.value?.clientHeight || 0}px`,
        '--footer-height': `${footerElement.value?.clientHeight || 0}px`,
    }
})
</script>
<template>
    <div :style="cssVariables">
        <table>
            <thead>
                <tr>
                    <td>
                        <div class="document-header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <div class="document-content">
                            <slot name="content"></slot>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div class="document-footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <div class="document-header" ref="headerElement">
            <slot name="header" />
        </div>
        <div class="document-footer" ref="footerElement">
            <slot name="footer" />
        </div>
    </div>
</template>
<style scoped>
@media print {
    @page {
        margin: 5mm;
    }

    ::v-deep .v-input__icon {
        display: none;
    }
}

.document-header-space {
    /*noinspection CssUnresolvedCustomProperty*/
    height: var(--header-height);
}

.document-footer-space {
    /*noinspection CssUnresolvedCustomProperty*/
    height: var(--footer-height);
}

.document-header {
    position: fixed;
    top: 0;
    width: 100%;
}

.document-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
}
</style>
