import Vue from 'vue'
import { KhMenus } from '@internal-libraries/kheops-ui-lib'
import { useClient } from '../../api/clients'
import { AnnouncementsClient, AnnouncementDto } from '../../api/ImedsApi'
import { format, parseJSON } from 'date-fns'
import { Component, Prop } from 'vue-property-decorator'

const announcementsClient = useClient(AnnouncementsClient)

@Component({ components: { KhMenus } })
export default class ImedsAnnouncement extends Vue {
    @Prop({ default: '', type: String })
    color!: string

    @Prop({ default: '', type: String })
    size!: string

    announcements: AnnouncementDto[] = []
    announcementsTimeoutId = setTimeout(
        this.getNewAnnouncements,
        1000 * 60 * 10
    )

    async mounted() {
        await this.getNewAnnouncements()
    }

    async getNewAnnouncements(): Promise<void> {
        this.announcements = await announcementsClient.getNewAnnouncements()
    }

    getIcon = (code: string): string | undefined => this.icons[code]?.icon

    getColor = (code: string): string | undefined => this.icons[code]?.color

    formatDateTime(dateTime: string | Date) {
        return format(parseJSON(dateTime), 'dd-MM-yyyy HH:mm')
    }

    acknowledgeAnnouncements(isMenuOpened: boolean): void {
        if (isMenuOpened && this.announcements.length > 0) {
            window.clearTimeout(this.announcementsTimeoutId)
            this.announcementsTimeoutId = setTimeout(
                this.getNewAnnouncements,
                1000 * 60 * 10
            )

            announcementsClient.acknowledge(
                this.announcements.map((announcement) => announcement.id)
            )
        }
    }

    icons: { [code: string]: AnnouncementIcon } = {
        info: {
            icon: 'Information',
            color: 'Info',
        },
        warn: {
            icon: 'Warning',
            color: 'Warning',
        },
        alert: {
            icon: 'Warning',
            color: 'Error',
        },
    }
}

interface AnnouncementIcon {
    icon: string
    color: string
}
