import { useClient } from '../../../../api/clients'
import ImedsDataTable from '../../../../components/ImedsDataTable.vue'
import PrintableDocument from '../../../../components/Pdf/PrintableDocument.vue'

import {
    ActionCodes,
    DataTableConfigurationDto,
    NurseLogisticsClient,
    NurseLogisticsRequestDto,
    NurseRequestUpdateDto,
    NurseUserDto,
    SortDirection,
} from '../../../../api/ImedsApi'
import { anyLoading, Loadable, loadable } from '../../../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    DatatableFiltering,
    ItemAction,
    PagedResultDto,
} from '../../../../components/ImedsDataTable'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'
import { VSelectItem } from '../../../../components/Courier/VSelectItem'
import {
    Status,
    statusText,
} from '../../../../components/models/Courier/Status'
import { parseISO } from 'date-fns'
import {
    DateTimeRange,
    KhButton,
    KhDateRangePicker,
    KhModal,
} from '@internal-libraries/kheops-ui-lib'
import { useErrorStore } from '../../../../stores/error'
import { createDatatableAutorefreshObservable } from '../../../../utils/Datatable'
import {
    ImedsLogisticsHistoryState,
    showHistory,
} from '../../../../components/History/ImedsLogisticsHistory'
import {
    SamplingType,
    samplingTypesText,
} from '../../../../components/models/Courier/Nurse/SamplingType'
import { useCurrentUserStore } from '../../../../stores/currentUser'
import { RouteName } from '../../router'
import {
    LogisticsNurseRequestEditionModal,
    NurseOrderDetail,
    ImedsUserPrinterSelector,
} from '../../../../components'
import { useMicrobiologyStore } from '../../../../stores/prescription/microbiology'

@Component({
    components: {
        ImedsDataTable,
        PrintableDocument,
        LogisticsNurseRequestEditionModal,
        NurseOrderDetail,
        ImedsUserPrinterSelector,
        KhDateRangePicker,
        KhButton,
        KhModal,
    },
    subscriptions(this: NurseLogisticsTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.nurseLogisticsClient.getConfiguration()))

        const refresh$ = createDatatableAutorefreshObservable(
            this.refreshActionSubject$,
            this.page$
        )

        const loadablePagedResult: Loadable<
            PagedResultDto<NurseLogisticsRequestDto>
        > = loadable(
            combineLatest([
                combineLatest([this.page$, this.pageSize$]),
                combineLatest([this.sortBy$, this.sortDirections$]),
                combineLatest([
                    this.filterStatus$,
                    this.filterAppointmentType$,
                    this.companyStatus$,
                    this.users$,
                ]),
                combineLatest([
                    this.filterDateFrom$,
                    this.filterDateTo$,
                    this.patientFirstName$,
                    this.patientLastName$,
                    this.patientBirthDate$,
                ]),
                refresh$,
            ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
            ([
                [page, pageSize],
                [sortBy, sortDirections],
                [
                    filterStatus,
                    filterAppointmentType,
                    filterCompanyStatus,
                    filterUsers,
                ],
                [
                    filterDateFrom,
                    filterDateTo,
                    patientFirstName,
                    patientLastName,
                    patientBirthDate,
                ],
            ]) =>
                from(
                    this.nurseLogisticsClient.getRequests(
                        sortBy,
                        sortDirections,
                        pageSize,
                        page,
                        !this.printable,
                        filterDateFrom,
                        filterDateTo,
                        filterStatus,
                        filterAppointmentType,
                        filterCompanyStatus,
                        filterUsers,
                        patientFirstName,
                        patientLastName,
                        patientBirthDate
                    )
                ).pipe(
                    tap(() => {
                        window.dispatchEvent(this.readyToPrint)
                    })
                )
        )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            filterStatus: this.filterStatus$,
            filterDateFrom: this.filterDateFrom$,
            filterDateTo: this.filterDateTo$,
            companyStatus: this.companyStatus$,
        })
    },
})
export default class NurseLogisticsTable extends Vue {
    readonly nurseLogisticsClient = useClient(NurseLogisticsClient)
    readonly errorStore = useErrorStore()
    readonly currentUserStore = useCurrentUserStore()
    readonly microbiologyStore = useMicrobiologyStore()

    async created(): Promise<void> {
        await this.currentUserStore.load()
        this.usersOptions = await this.nurseLogisticsClient.getNurseUsers()
        this.microbiologyStore.load()
    }

    @Prop({ default: null })
    dateFrom!: Date | null

    @Prop({ default: null })
    dateTo!: Date | null

    @Prop({ default: false, type: Boolean })
    printable!: boolean

    readyToPrint = new CustomEvent('readyToPrint')

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly filterStatus$ = new BehaviorSubject<string[] | undefined>(
        this.getStatus()
    )
    readonly filterAppointmentType$ = new BehaviorSubject<string[] | undefined>(
        this.getAppointmentType()
    )
    readonly companyStatus$ = new BehaviorSubject<number[] | undefined>(
        this.$route.query.companies &&
        typeof this.$route.query.companies === 'string'
            ? this.$route.query.companies
                  .split(',')
                  .map((company) => parseInt(company))
            : this.$route.name === RouteName.NursePrint
            ? undefined
            : this.currentUserStore.companyId
            ? [this.currentUserStore.companyId]
            : undefined
    )
    readonly users$ = new BehaviorSubject<string[] | undefined>(this.getUsers())
    readonly patientFirstName$ = new BehaviorSubject<string | undefined>(
        undefined
    )
    readonly patientLastName$ = new BehaviorSubject<string | undefined>(
        undefined
    )
    readonly patientBirthDate$ = new BehaviorSubject<string | undefined>(
        undefined
    )

    usersOptions: NurseUserDto[] | null = null

    getAppointmentType(): string[] | undefined {
        return this.$route.name === 'NursePrint' &&
            this.$route.query.companies &&
            typeof this.$route.query.appointmentTypes === 'string'
            ? this.$route.query.appointmentTypes.split(',')
            : undefined
    }

    getStatus(): string[] | undefined {
        return this.printable
            ? this.$route.query.statuses
                ? (this.$route.query.statuses as string).split(',')
                : undefined
            : this.filterStatus$?.value ?? [Status.pending, Status.accepted]
    }

    getUsers(): string[] | undefined {
        return this.printable
            ? this.$route.query.users
                ? (this.$route.query.users as string).split(',')
                : undefined
            : this.users$?.value ?? []
    }

    filterDateFrom$ = new BehaviorSubject<Date | null>(this.getDateFrom())
    // for an unknown reason, doing it directly in the initialization doesn't works, the $route.query will be null
    getDateFrom() {
        return this.printable
            ? tryParseIsoDate(this.$route.query.dateFrom)
            : new Date(new Date().setHours(0, 0, 0, 0))
    }

    filterDateTo$ = new BehaviorSubject<Date | null>(this.getDateTo())
    // for an unknown reason, doing it directly in the initialization doesn't works, the $route.query will be null
    getDateTo() {
        return this.printable
            ? tryParseIsoDate(this.$route.query.dateTo)
            : new Date(new Date().setHours(23, 59, 59, 0))
    }

    dialogAccept = false
    dialogReject = false
    dialogComplete = false
    dialogUnassign = false
    dialogEdit = false
    currentItem: NurseLogisticsRequestDto | null = null
    currentItemUpdate: NurseRequestUpdateDto | null = null
    historyModalState: ImedsLogisticsHistoryState = null

    get dateRange(): DateTimeRange {
        return {
            start: this.filterDateFrom$.value,
            end: this.filterDateTo$.value,
        }
    }

    async printPlanning(): Promise<void> {
        const urlToPrint =
            `/logistics#/nurse/print?` +
            new URLSearchParams({
                ...(this.dateRange.start
                    ? { dateFrom: (this.dateRange.start as Date).toISOString() }
                    : {}),
                ...(this.dateRange.end
                    ? { dateTo: (this.dateRange.end as Date).toISOString() }
                    : {}),
                ...(this.filterAppointmentType$.value
                    ? {
                          appointmentTypes:
                              this.filterAppointmentType$.value?.toString(),
                      }
                    : {}),
                ...(this.filterStatus$.value
                    ? { statuses: this.filterStatus$.value?.toString() }
                    : {}),
                ...(this.users$.value
                    ? { users: this.users$.value?.toString() }
                    : {}),
                ...(this.companyStatus$.value
                    ? { companies: this.companyStatus$.value?.toString() }
                    : {}),
            })

        const urlApi =
            '/report/?' +
            new URLSearchParams({
                url: this.utf8_to_b64(urlToPrint),
                scale: (0.7).toString(),
            })

        const urlPdfViewer =
            '/pdf/web/viewer.html?' + new URLSearchParams({ file: urlApi })

        const pdfWindow = window.open('', '_blank')
        if (pdfWindow) {
            pdfWindow.location.href = urlPdfViewer
        }
    }

    utf8_to_b64(str: string): string {
        return window.btoa(unescape(encodeURIComponent(str)))
    }

    b64_to_utf8(str: string): string {
        return decodeURIComponent(escape(window.atob(str)))
    }

    async onItemAction({
        item,
        actionCode,
    }: ItemAction<NurseLogisticsRequestDto>): Promise<void> {
        this.currentItem = item
        switch (actionCode) {
            case ActionCodes.Nurse.Logistics.Accept:
                this.dialogAccept = true
                break
            case ActionCodes.Nurse.Logistics.Complete:
                this.dialogComplete = true
                break
            case ActionCodes.Nurse.Logistics.Reject:
                this.dialogReject = true
                break
            case ActionCodes.Nurse.Logistics.Unassign:
                this.dialogUnassign = true
                break
            case ActionCodes.Nurse.Logistics.Edit:
                this.currentItemUpdate = {
                    id: item.id,
                    sampleDate: new Date(item.sampleDate),
                }
                this.dialogEdit = true
                break
            case ActionCodes.Nurse.Logistics.ViewHistory:
                await showHistory(this, () =>
                    this.nurseLogisticsClient.getHistory(item.id)
                )
                break
        }
    }

    async acceptRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.nurseLogisticsClient.acceptRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    async rejectRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.nurseLogisticsClient.rejectRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    async completeRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.nurseLogisticsClient.completeRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    async unassignRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.nurseLogisticsClient.unassignRequest(this.currentItem?.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    async editRequest(): Promise<void> {
        if (!this.currentItemUpdate?.id) return

        await this.nurseLogisticsClient.updateNurseRequest(
            this.currentItemUpdate
        )
        this.refreshActionSubject$.next()
        this.currentItemUpdate = null
        this.dialogEdit = false
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<Status>[] {
        return [
            Status.pending,
            Status.accepted,
            Status.done,
            Status.rejected,
            Status.cancelled,
        ].map((status) => ({
            value: status,
            text: this.$t(statusText[status]),
        }))
    }

    @Watch('$t')
    get samplingTypeOptions(): VSelectItem<SamplingType>[] {
        return [
            {
                value: SamplingType.SamplingCenter,
                text: this.$t(
                    samplingTypesText[SamplingType.SamplingCenter]
                ) as string,
            },
            {
                value: SamplingType.Home,
                text: this.$t(samplingTypesText[SamplingType.Home]) as string,
            },
        ]
    }

    setFilters(filters: DatatableFiltering[]) {
        //todo : find a better solution to not use magic string
        this.patientFirstName$.next(
            filters.find((filter) => filter.path == 'patientFirstName')
                ?.value as string | undefined
        )
        this.patientLastName$.next(
            filters.find((filter) => filter.path == 'patientLastName')
                ?.value as string | undefined
        )
        this.patientBirthDate$.next(
            filters.find((filter) => filter.path == 'patientBirthDate')
                ?.value as string | undefined
        )
    }
}

function tryParseIsoDate(date: unknown): Date | null {
    if (typeof date !== 'string' || !date) return null

    const parsed = parseISO(date)

    if (!(parsed instanceof Date)) return null

    return parsed
}
