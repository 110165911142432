import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { KhMenus } from '@internal-libraries/kheops-ui-lib'
import { useClient } from '../../api/clients'
import {
    AnnouncementsClient,
    UserDto,
    UsersClient,
    AnnouncementLevelDto,
} from '../../api/ImedsApi'
import { loadable, Loadable } from '../../utils/Loadable'
import { useErrorStore } from '../../stores/error'
import { from } from 'rxjs'
import { VSelectItem } from '../Courier/VSelectItem'

const announcementsClient = useClient(AnnouncementsClient)

@Component({
    components: { KhMenus },
    subscriptions(this: ImedsCreateAnnouncementForm) {
        const loadableUsers: Loadable<UserDto[]> = loadable(
            from(this.usersClient.getAll())
        )

        return this.errorStore.handleSubscriptionsErrors({
            users: loadableUsers.value$,
            usersLoading: loadableUsers.loading$,
        })
    },
})
export default class ImedsCreateAnnouncementForm extends Vue {
    readonly errorStore = useErrorStore()
    readonly usersClient = useClient(UsersClient)

    title: string | null = null
    message: string | null = null
    announcementLevels: AnnouncementLevelDto[] | null = null

    selectedUserIds: string[] = []
    selectedLevel: number | null = null

    async mounted() {
        this.announcementLevels =
            await announcementsClient.getAnnouncementLevels()
    }

    async createAnnouncement(): Promise<void> {
        if (this.title && this.message && this.selectedLevel)
            await announcementsClient.createAnnouncement({
                title: this.title,
                message: this.message,
                levelId: this.selectedLevel,
                usersIds: this.selectedUserIds,
            })
    }

    @Watch('$t')
    get announcementlevelOptions(): VSelectItem<number>[] {
        if (!this.announcementLevels) return []
        return this.announcementLevels.map((level) => ({
            value: level.id,
            text: this.$t(level.name) as string,
        }))
    }
}
