﻿import { useClient } from '../../api/clients'
import ImedsDataTable from '../ImedsDataTable.vue'
import {
    AdditionDto,
    AdditionsOverviewClient,
    DataTableConfigurationDto,
    ActionCodes,
    SortDirection,
} from '../../api/ImedsApi'
import { loadable, Loadable, anyLoading } from '../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../ImedsDataTable'
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { VSelectItem } from '../Courier/VSelectItem'
import { Status, statusText } from '../models/Courier/Status'
import { useErrorStore } from '../../stores/error'
import { KhConfirmModal } from '@internal-libraries/kheops-ui-lib'

@Component({
    components: { ImedsDataTable, KhConfirmModal },
    subscriptions(this: AdditionTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.additionsOverviewClient.getConfiguration()))

        const loadablePagedResult: Loadable<PagedResultDto<AdditionDto>> =
            loadable(
                combineLatest([
                    combineLatest([this.pageSize$, this.page$]),
                    combineLatest([this.sortBy$, this.sortDirections$]),
                    this.filterStatus$,
                    this.refreshActionSubject$,
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([[pageSize, page], [sortBy, sortDirections], filterStatus]) =>
                    from(
                        this.additionsOverviewClient.get(
                            pageSize,
                            page,
                            sortBy,
                            sortDirections,
                            true,
                            filterStatus
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            filterStatus: this.filterStatus$,
        })
    },
})
export default class AdditionTable extends Vue {
    readonly additionsOverviewClient = useClient(AdditionsOverviewClient)
    readonly errorStore = useErrorStore()
    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly filterStatus$ = new BehaviorSubject<string | null>(null)
    dialogCancel = false
    currentItem: AdditionDto | null = null

    async onItemAction(itemAction: ItemAction<AdditionDto>): Promise<void> {
        switch (itemAction.actionCode) {
            case ActionCodes.Additions.Cancel:
                this.currentItem = itemAction.item
                this.dialogCancel = true
                break
        }
    }

    async cancelRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.additionsOverviewClient.cancel(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.currentItem = null
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<Status>[] {
        return [
            Status.accepted,
            Status.pending,
            Status.done,
            Status.cancelled,
            Status.rejected,
        ].map((status) => ({
            value: status,
            text: this.$t(statusText[status]),
        }))
    }
}
