﻿import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { useClient } from '../../api/clients'
import {
    DataTableConfigurationDto,
    GdprClient,
    GdprPatientSearchLogDto,
    SortDirection,
    UserDto,
    UsersClient,
} from '../../api/ImedsApi'
import 'vue-rx'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import ImedsDataTable from '../ImedsDataTable.vue'
import { Loadable, loadable } from '../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    PagedResultDto,
} from '../ImedsDataTable'
import { KhDateTimePicker } from '@internal-libraries/kheops-ui-lib'
import { useErrorStore } from '../../stores/error'

@Component({
    components: { ImedsDataTable, KhDateTimePicker },
    subscriptions(this: GdprPatientSearchLogs) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.gdprClient.getPatientSearchLogsConfiguration()))

        const loadablePagedResult: Loadable<
            PagedResultDto<GdprPatientSearchLogDto>
        > = loadable(
            combineLatest([
                combineLatest([this.page$, this.pageSize$]),
                combineLatest([this.sortBy$, this.sortDirections$]),
                this.selectedUserIds$,
                this.fromDate$,
                this.toDate$,
            ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
            ([
                [page, pageSize],
                [sortBy, sortDirections],
                userIds,
                fromDate,
                toDate,
            ]) =>
                from(
                    this.gdprClient.getPatientSearchLogs(
                        sortBy,
                        sortDirections,
                        pageSize,
                        page,
                        userIds,
                        fromDate,
                        toDate
                    )
                )
        )

        const loadableUsers: Loadable<UserDto[]> = loadable(
            from(this.usersClient.getAll())
        )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            users: loadableUsers.value$,
            usersLoading: loadableUsers.loading$,
            fromDate: this.fromDate$,
            toDate: this.toDate$,
            selectedUserIds: this.selectedUserIds$,
            filtered: combineLatest([
                this.fromDate$,
                this.toDate$,
                this.selectedUserIds$,
            ]).pipe(
                map(
                    ([fromDate, toDate, selectedUserIds]) =>
                        fromDate !== null ||
                        toDate !== null ||
                        selectedUserIds.length > 0
                )
            ),
        })
    },
})
export default class GdprPatientSearchLogs extends Vue {
    readonly errorStore = useErrorStore()
    readonly gdprClient = useClient(GdprClient)
    readonly usersClient = useClient(UsersClient)

    page$ = new BehaviorSubject<number>(1)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    sortBy$ = new BehaviorSubject<string[]>([])
    sortDirections$ = new BehaviorSubject<SortDirection[]>([])

    selectedUserIds$ = new BehaviorSubject<string[]>([])
    fromDate$ = new BehaviorSubject<Date | null>(null)
    toDate$ = new BehaviorSubject<Date | null>(null)
}
