﻿import { KhComponent } from '@internal-libraries/kheops-ui-lib'
import Component from 'vue-class-component'
import { HealthInsuranceDto, HealthInsurancesClient } from '../api/ImedsApi'
import axios from 'axios'
import { loadable } from '../utils/Loadable'
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Emit, Ref } from 'vue-property-decorator'
import { chain, deburr } from 'lodash'
import Vue from 'vue'

@Component({
    components: {},
    subscriptions(this: ImedsHealthInsuranceInput) {
        return {
            filteredInsurances: this.filteredInsurances$,
            loading: this.loadableHealthInsurances.loading$,
        }
    },
})
export default class ImedsHealthInsuranceInput extends KhComponent {
    readonly axiosInstance = axios.create({ transformResponse: [] })
    readonly healthInsurancesClient = new HealthInsurancesClient(
        undefined,
        this.axiosInstance
    )

    searchSubject = new BehaviorSubject<string | null>(null)

    get search(): string | null {
        return this.searchSubject.value
    }

    set search(value: string | null) {
        this.searchSubject.next(value)
    }

    readonly loadableHealthInsurances = loadable(
        from(this.healthInsurancesClient.getHealthInsurances())
    )
    readonly loading?: Observed<
        ImedsHealthInsuranceInput['loadableHealthInsurances']['loading$']
    >

    selectedSubject = new BehaviorSubject<
        string | HealthInsuranceDto | undefined
    >(undefined)

    get selected(): string | HealthInsuranceDto | undefined {
        return this.selectedSubject.value
    }

    set selected(value: string | HealthInsuranceDto | undefined) {
        this.selectedSubject.next(value)
    }

    readonly filteredInsurances$: Observable<HealthInsuranceDto[]> =
        combineLatest([
            this.searchSubject,
            this.loadableHealthInsurances.value$,
            this.selectedSubject,
        ]).pipe(
            map(([search, insurances, selected]) =>
                search && !selected
                    ? chain(insurances)
                          .flatMap((insurance) => {
                              const searchIndex = lowerCaseNoAccentIndexOf(
                                  search,
                                  insurance.name
                              )
                              return searchIndex >= 0
                                  ? [{ insurance, searchIndex }]
                                  : []
                          })
                          .orderBy(({ searchIndex }) => [searchIndex])
                          .map(({ insurance }) => insurance)
                          .value()
                    : insurances
            )
        )
    readonly filteredInsurances?: Observed<
        ImedsHealthInsuranceInput['filteredInsurances$']
    >

    searchBeforeReset: string | null = ''

    get entries(): Record<string, unknown> {
        return {
            ...this.$props,
            ...this.$attrs,
        }
    }

    get listeners(): VueListeners {
        return {
            ...(this.$listeners as VueListeners),
            input: (value?: string | HealthInsuranceDto) => {
                this.input(
                    typeof value === 'string'
                        ? chain(this.filteredInsurances ?? [])
                              .orderBy((insurance) =>
                                  lowerCaseNoAccentIndexOf(
                                      value,
                                      insurance.name
                                  )
                              )
                              .head()
                              .value() ?? value
                        : value
                )
            },
            'update:search-input': async (value: string | null) => {
                if (value) {
                    this.searchBeforeReset = value
                    this.input(undefined)
                } else if (
                    value == null &&
                    !this.selected &&
                    this.searchBeforeReset
                ) {
                    await this.$nextTick()
                    this.search = this.searchBeforeReset
                    this.searchBeforeReset = null
                }
            },
            keydown: async (event: KeyboardEvent) => {
                if (
                    !this.selected &&
                    this.search &&
                    (this.filteredInsurances ?? []).length === 0 &&
                    ['Enter', 'Tab'].indexOf(event.code) >= 0
                ) {
                    await this.$nextTick()
                    this.input(this.search)
                    this.combobox.isMenuActive = false
                }
            },
        }
    }

    @Ref()
    combobox!: Vue & { isMenuActive: boolean }

    @Emit()
    input(
        newValue?: string | HealthInsuranceDto
    ): string | HealthInsuranceDto | undefined {
        this.selected = newValue
        return newValue
    }

    maskSearch(name: string) {
        if (!this.search) {
            return name
        }

        const startIndex = lowerCaseNoAccentIndexOf(this.search, name)
        if (startIndex < 0) {
            return name
        }

        const endIndex = startIndex + this.search.length

        const before = name.slice(0, startIndex)
        const matched = name.slice(startIndex, endIndex)
        const after = name.slice(endIndex)
        return `${before}<span class="v-list-item__mask">${matched}</span>${after}`
    }
}

function lowerCaseNoAccent(str: string): string {
    return deburr(str).toLowerCase()
}

function lowerCaseNoAccentIndexOf(needle: string, haysack: string): number {
    return lowerCaseNoAccent(haysack).indexOf(lowerCaseNoAccent(needle))
}

type UnknownFunction = (...args: never[]) => unknown
type VueListeners = Record<string, UnknownFunction | UnknownFunction[]>
type Observed<O> = O extends Observable<infer T> ? T : never
