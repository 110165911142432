﻿import { ref } from 'vue'
import { defineLoadableStore } from '@internal-libraries/kheops-ui-lib/src/store/LoadableStore'
import { PatientsSearchClient, SearchType } from '../api/ImedsApi'
import { useClient } from '../api/clients'

const patientSearchClient = useClient(PatientsSearchClient)
export const usePatientSearchStore = defineLoadableStore(
    'patientSearch',
    () => {
        const searchType = ref<SearchType | undefined>(undefined)

        const isPatientExternalIdSearchEnabled = ref<boolean | undefined>(
            undefined
        )
        const isVisitIdSearchEnabled = ref<boolean | undefined>(undefined)

        const openSearchModalOnLoad = ref<boolean | undefined>(undefined)

        return {
            searchType,
            isPatientExternalIdSearchEnabled,
            isVisitIdSearchEnabled,
            openSearchModalOnLoad,
        }
    },
    async (store) => {
        const configuration = await patientSearchClient.getUserConfiguration()
        store.searchType.value = configuration.searchType
        store.isPatientExternalIdSearchEnabled.value =
            configuration.isPatientExternalIdSearchEnabled
        store.isVisitIdSearchEnabled.value =
            configuration.isVisitIdSearchEnabled
        store.openSearchModalOnLoad.value = configuration.openSearchModalOnLoad
    }
)
