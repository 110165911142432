import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Emit } from 'vue-property-decorator'
import { KhModal, KhButton } from '@internal-libraries/kheops-ui-lib'
import { useAnalysisProfileStore } from '../../../../stores/prescription/analysisProfile'
import ImedsAnalysisProfileContentList from '../ImedsAnalysisProfileContentList.vue'

@Component({
    components: {
        KhModal,
        KhButton,
        ImedsAnalysisProfileContentList,
    },
})
export default class ImedsAnalysisProfileCreationModal extends Vue {
    readonly analysisProfileStore = useAnalysisProfileStore()

    name = ''

    @Prop({
        default: false,
    })
    readonly value!: boolean

    async save(): Promise<void> {
        await this.analysisProfileStore.createProfile(this.name)
        this.close()
    }

    get creatable(): boolean {
        return !!this.name
    }

    @Emit('input')
    close(): false {
        this.name = ''
        return false
    }
}
