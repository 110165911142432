import Vue from 'vue'
import Component from 'vue-class-component'
import {
    KhSelect,
    KhConfirmModal,
    KhDateTimePicker,
} from '@internal-libraries/kheops-ui-lib'
import { Prop } from 'vue-property-decorator'
import { NurseRequestUpdateDto } from '../../api/ImedsApi'

@Component({ components: { KhSelect, KhConfirmModal, KhDateTimePicker } })
export default class LogisticsNurseRequestEditionModal extends Vue {
    @Prop()
    nurseUpdateDto!: NurseRequestUpdateDto
}
