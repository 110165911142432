import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { useClient } from '../../../api/clients'
import { CountriesClient, CountriesDto } from '../../../api/ImedsApi'
import { VSelectItem } from '../../Courier/VSelectItem'

const countriesClient = useClient(CountriesClient)
@Component
export default class ImedsPrescriptionFormAppointment extends Vue {
    countryList: CountriesDto[] | null = null
    nuseInformation: string | null = null
    address: string | null = null
    additionalAddress: string | null = null
    city: string | null = null
    countryCode: string | null = null
    postalCode: string | null = null
    phone: number | null = null
    async mounted() {
        this.countryList = await countriesClient.getAllCountries()
    }

    @Watch('$t')
    get countriesOptions(): VSelectItem<number>[] {
        if (!this.countryList) return []
        return this.countryList.map((countries) => ({
            value: countries.id,
            text: this.$t('countries:' + countries.code) as string,
        }))
    }
}
