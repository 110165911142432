﻿import {
    Component,
    Emit,
    Prop,
    PropSync,
    Ref,
    Watch,
} from 'vue-property-decorator'
import Vue from 'vue'
import {
    DataTableActionDto,
    DataTableColumnDto,
    DataTableColumnType,
    DataTableConfigurationDto,
    Icon,
    IconConfigurationDto,
    SortDirection,
} from '../api/ImedsApi'
import { DataTableHeader } from 'vuetify'
import { get, range, zip } from 'lodash'
import 'vue-rx'
import { KhButton, KhTooltip } from '@internal-libraries/kheops-ui-lib'
import { format, parseJSON } from 'date-fns'
import { useCurrentUserStore } from '../stores/currentUser'
import ImedsDataTableHeaderElement from './ImedsDataTable/ImedsDataTableHeaderElement/ImedsDataTableHeaderElement.vue'

export const DATA_TABLE_DEFAULT_PAGE = 1
export const DATA_TABLE_DEFAULT_PAGE_SIZE = 16
export const DATA_TABLE_DEFAULT_DEBOUNCE_TIME = 400

@Component({
    components: { KhButton, KhTooltip, ImedsDataTableHeaderElement },
    filters: {
        formatDateTime: (value: string | number | Date | null): string | null =>
            value ? format(parseJSON(value), 'dd-MM-yyyy HH:mm') : null,
        formatDateOnly: (value: string | null) =>
            value ? value.split('-').reverse().join('.') : null,
    },
})
export default class ImedsDataTable<TItem> extends Vue {
    readonly footerProps = {
        'items-per-page-options': range(1, 6).map(
            (i) => i * DATA_TABLE_DEFAULT_PAGE_SIZE
        ),
    }

    readonly currentUserStore = useCurrentUserStore()

    @Ref()
    datatable?: HTMLElement

    @Prop({ default: null })
    configuration!: DataTableConfigurationDto | null

    @Prop({ default: null })
    pagedResult!: PagedResultDto<TItem> | null

    @Prop({ required: true })
    loading?: boolean

    @Prop({ default: false })
    filtered?: boolean

    @PropSync('pageSize', { default: DATA_TABLE_DEFAULT_PAGE_SIZE })
    syncedPageSize!: number

    @PropSync('page', { default: DATA_TABLE_DEFAULT_PAGE })
    syncedPage!: number

    @Prop({ default: false, type: Boolean })
    expandAll!: boolean

    @Prop({ default: false, type: Boolean })
    printable!: boolean

    @Prop({ default: false, type: Boolean })
    clickable!: boolean

    expandable = false

    get showExpandAction(): boolean {
        return this.expandable && !this.printable
    }

    @Emit('update:filtering')
    emitUpdateFiltering(
        filter: string | Date | null,
        columnIndex: number
    ): DatatableFiltering[] {
        this.setHeaderFilterValue(filter, this.columns[columnIndex].path)
        return this.filters
    }

    setHeaderFilterValue(value: string | Date | null, path: string): void {
        const filterIndex = this.filters.findIndex(
            (filter) => filter.path === path
        )
        if (filterIndex >= 0) {
            if (!value) {
                this.filters.splice(filterIndex, 1)
            } else {
                this.filters[filterIndex].value = value
            }
        } else {
            if (value)
                this.filters.push({
                    value: value,
                    path: path,
                })
        }
    }

    @Emit('row-action')
    emitItemAction(actionCode: string, item: TItem): ItemAction<TItem> {
        switch (actionCode) {
            case expandActionCode:
                this.expandedItems.push(item)
                break

            case collapseActionCode:
                this.expandedItems = this.expandedItems.filter(
                    (expandedItem) => expandedItem !== item
                )
                break
        }

        return {
            actionCode,
            item,
        }
    }

    @Emit('click-row-action')
    emitItemRowAction(item: TItem): TItem {
        return item
    }
    getClassRow() {
        return this.clickable ? 'clickable' : ''
    }
    stopEventActionRow(e: Event) {
        e.stopPropagation()
    }

    readonly columnType = DataTableColumnType
    private expandedItems: TItem[] = []

    get headers(): DataTableHeader[] {
        return (
            this.columns.map((column, index) => ({
                text: column.name,
                value: index.toString(),
                sortable: column.isSortable,
                width: column.weight,
            })) ?? []
        )
    }

    get items(): TItem[] {
        this.expandedItems = this.expandAll
            ? this.pagedResult?.results ?? []
            : []
        return this.pagedResult?.results ?? []
    }

    get serverItemsLength(): number {
        return this.pagedResult?.totalCount ?? 0
    }

    getColumnValue(column: DataTableColumnDto, item: TItem): unknown {
        return get(item, column.path, '')
    }

    get noDataText(): string {
        return this.filtered
            ? this.$t('datatable:no_data_with_filters')
            : this.$t('datatable:no_data')
    }

    datatableCurrentWidth: number = this.datatable?.offsetWidth ?? 0

    setCurrentDatatableWidth() {
        this.datatableCurrentWidth = this.datatable?.offsetWidth ?? 0
    }

    @Watch('$t')
    get columns(): DataTableColumnDto[] {
        if (!this.configuration) return []

        const actions = this.configuration.actions.filter(
            (action) =>
                !action.roles?.length ||
                this.currentUserStore.roles.some((userRole) =>
                    (action.roles ?? []).includes(userRole)
                )
        )

        let actionsCount = this.items?.length
            ? Math.max(
                  ...this.items.map((item) => this.getItemActions(item).length)
              )
            : actions.length + (this.showExpandAction ? 1 : 0) || 0

        if (actionsCount === 1) actionsCount = 2 //or else the title will be truncated

        const actionsColumnWidth = actionsCount
            ? actionsCount * actionButtonWidth + columnHorizontalPadding
            : 0

        const iconColumnsCount = this.configuration.columns.filter(
            (column) => column.type === DataTableColumnType.Icons
        ).length

        const iconColumnsWidth = iconColumnsCount
            ? iconColumnsCount * (iconWidth + columnHorizontalPadding)
            : 0

        const resizableColumns = this.configuration.columns.filter(
            (column) =>
                column.type != DataTableColumnType.Icons &&
                column.type != DataTableColumnType.Actions
        )

        const resizableColumnsTotalWeight = resizableColumns.reduce(
            (sum, column) => sum + (column.weight ?? 1),
            0
        )

        const resizableWidth = this.datatableCurrentWidth
            ? this.datatableCurrentWidth - actionsColumnWidth - iconColumnsWidth
            : 0

        const widthPerWeight = resizableWidth / resizableColumnsTotalWeight

        return this.configuration.columns
            .filter(
                (column) =>
                    (!this.printable || column.isPrintable) &&
                    (!column.roles?.length ||
                        this.currentUserStore.roles.some((userRole) =>
                            (column.roles ?? []).includes(userRole)
                        ))
            )
            .map((column) => ({
                ...column,
                name: column.name ? this.$t(column.name) : column.name,
            }))
            .concat(
                actions && actions.length > 0 && !this.printable
                    ? [
                          {
                              isSortable: false,
                              isPrintable: true,
                              path: null as unknown as string,
                              name: this.$t('datatable:actions', {
                                  count: actions.length,
                              }),
                              type: DataTableColumnType.Actions,
                          },
                      ]
                    : []
            )
            .map((column) => ({
                ...column,
                weight:
                    column.type === DataTableColumnType.Icons
                        ? iconWidth
                        : column.type === DataTableColumnType.Actions
                        ? actionsColumnWidth
                        : (column.weight ?? 1) * widthPerWeight,
            }))
    }

    getVisibleIcons(
        column: DataTableColumnDto,
        value: unknown
    ): IconConfigurationDto[] {
        return (
            column.icons?.filter(
                (icon) =>
                    (icon.conditionValue === null && value) ||
                    icon.conditionValue === value
            ) ?? []
        )
    }

    getItemActions(item: TItem): DataTableActionDto[] {
        if (!this.configuration) return []

        const itemActions = this.configuration.actions.filter(
            (action) =>
                (!action.conditionValue === undefined ||
                    !action.path ||
                    get(item, action.path, null) === action.conditionValue) &&
                (!action.roles?.length ||
                    this.currentUserStore.roles.some((userRole) =>
                        (action.roles ?? []).includes(userRole)
                    ))
        )

        if (this.showExpandAction) {
            itemActions.push(
                this.expandedItems.includes(item)
                    ? {
                          icon: Icon.Collapse,
                          name: this.$t('datatable:collapse'),
                          code: collapseActionCode,
                      }
                    : {
                          icon: Icon.Expand,
                          name: this.$t('datatable:expand'),
                          code: expandActionCode,
                      }
            )
        }

        return itemActions
    }

    async mounted(): Promise<void> {
        // expandable is computed here because $scopedSlots are not reactive and not initialized before mounted
        this.expandable = !!this.$scopedSlots['expanded-item']
        await this.$nextTick()
        this.setCurrentDatatableWidth()
    }

    updated(): void {
        // This dirty hack makes there are no page breaks between a row and its expanded row when printed in chrome
        if (
            !this.printable ||
            !this.expandable ||
            !this.expandAll ||
            this.loading ||
            !this.pagedResult?.results?.length
        ) {
            return
        }

        const tableElement: HTMLTableElement =
            this.$el.getElementsByTagName('table')[0]

        if (tableElement.classList.contains('imeds-printable-table')) {
            return
        }

        tableElement.classList.add('imeds-printable-table')

        const tableHeaders = [
            ...tableElement.querySelectorAll<HTMLTableCellElement>(
                'td .imeds-datatable-header'
            ),
        ]

        const columnWidths = tableHeaders.map((th) => th.style.width)

        const expandedRows = [
            ...tableElement.getElementsByClassName(
                'v-data-table__expanded__row'
            ),
        ] as HTMLTableRowElement[]

        const expandedContents = [
            ...tableElement.getElementsByClassName(
                'v-data-table__expanded__content'
            ),
        ] as HTMLTableRowElement[]

        zip(expandedRows, expandedContents).forEach(
            ([row, content], rowIndex) => {
                if (!row || !content || rowIndex === 0) {
                    return
                }

                const parent = row.parentElement as HTMLElement
                const newTr = document.createElement('tr')
                const newTd = document.createElement('td')
                newTd.colSpan = columnWidths.length
                newTd.style.paddingLeft = '0'
                newTd.style.paddingRight = '0'

                const innerTable = document.createElement('table')
                innerTable.style.tableLayout = 'fixed'
                innerTable.style.width = '100%'
                innerTable.style.pageBreakInside = 'avoid'
                newTr.appendChild(newTd)
                newTd.appendChild(innerTable)
                innerTable.appendChild(row)
                innerTable.appendChild(content)
                parent.appendChild(newTr)
                ;[...row.getElementsByTagName('td')].forEach((td, index) => {
                    td.style.width = columnWidths[index]
                    td.style.minWidth = columnWidths[index]
                    td.style.paddingLeft = `${columnLeftPadding}px`
                    td.style.paddingRight = `${columnRightPadding}px`
                })
            }
        )
    }

    filters: DatatableFiltering[] = []

    sorting: {
        columnIndex: number
        direction: SortDirection
    } | null = null

    @Watch('configuration', { immediate: true })
    initSortingOnConfigurationChange() {
        this.sorting = this.configuration?.defaultSortingDirection
            ? {
                  columnIndex: this.configuration.columns.findIndex(
                      (column) =>
                          column.path ===
                          this.configuration?.defaultSortingProperty
                  ),
                  direction: this.configuration.defaultSortingDirection,
              }
            : null
    }

    updateSorting(
        sortDirection: SortDirection | null,
        columnIndex: number
    ): void {
        this.sorting = sortDirection
            ? {
                  columnIndex: columnIndex,
                  direction: sortDirection,
              }
            : null

        this.emitUpdateSortBy()
        this.emitUpdateSortDirections()
    }

    @Emit('update:sort-by')
    emitUpdateSortBy(): string[] {
        return this.sorting ? [this.columns[this.sorting.columnIndex].path] : []
    }

    @Emit('update:sort-directions')
    emitUpdateSortDirections(): SortDirection[] {
        return this.sorting ? [this.sorting.direction] : []
    }
}

export interface PagedResultDto<T> {
    totalCount: number
    results: T[]
}

export interface ItemAction<T> {
    actionCode: string
    item: T
}

export interface DatatableFiltering {
    path: string
    value?: string | Date | null
}

const columnLeftPadding = 16
const columnRightPadding = 16
const columnHorizontalPadding = columnLeftPadding + columnRightPadding
const actionButtonWidth = 36
const iconWidth = 16

const expandActionCode = 'ImedsDataTable.Expand'
const collapseActionCode = 'ImedsDataTable.Collapse'
