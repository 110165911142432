﻿import { PrintDataType, PrintDriverTypeCode } from '../../../../api/ImedsApi'
import { useAxiosInstance } from '../../../../api/clients'
import { XMLParser } from 'fast-xml-parser'
import { PrintDriver } from '../PrintDriver'
import { ConnectedPrinter } from '../ConnectedPrinter'
import { flatten } from 'lodash'

const dymoConnectBaseUrl = 'https://127.0.0.1:41951/DYMO/DLS/Printing/'
const axiosInstance = useAxiosInstance()

export const dymoConnectPrintDriver: PrintDriver<PrintDriverTypeCode.DymoConnect> =
    Object.freeze({
        printDriverType: PrintDriverTypeCode.DymoConnect,
        printDataType: PrintDataType.Dymo,
        name: 'Dymo Connect',
        async getPrinters(): Promise<DymoConnectPrinter[]> {
            const response = await axiosInstance.get(
                dymoConnectBaseUrl + 'GetPrinters'
            )

            if (response.status !== 200) {
                throw new Error('Error getting Dymo printers')
            }

            const contentType: string | undefined =
                response.headers['content-type']

            // Dymo Label Web service sends XML in a json string
            // but Dymo Connect web service sends XML directly
            const xml: string =
                typeof contentType === 'string' &&
                contentType.startsWith('application/json')
                    ? JSON.parse(response.data)
                    : response.data

            // Collection must be flattened:
            // XMLParser can return an element or an array of elements
            // depending on if there is one or more elements
            const printers = flatten(
                Object.values(new XMLParser().parse(xml).Printers)
            ) as DymoConnectPrinterInfo[]

            return printers.map((info) => createPrinter(info))
        },
        async print(
            printer: ConnectedPrinter<PrintDriverTypeCode.DymoConnect>,
            data: string[]
        ): Promise<void> {
            for (const label of data) {
                // Dymo Label Web Service crashes if `printParamsXml` and `labelSetXml` are not in the payload.
                // It also requires '%20' instead of '+'
                // Dymo Connect Web service does not have these limitations but accepts both ways.
                const payload = new URLSearchParams({
                    printerName: printer.name,
                    printParamsXml: '',
                    labelXml: label,
                    labelSetXml: '',
                })
                    .toString()
                    .replaceAll('+', '%20')

                await axiosInstance.post(
                    dymoConnectBaseUrl + 'PrintLabel',
                    payload,
                    {
                        headers: {
                            accept: '*/*',
                            'content-type': 'application/x-www-form-urlencoded',
                        },
                    }
                )
            }
        },
    })

export interface DymoConnectPrinterInfo {
    readonly Name: string
    readonly ModelName: string
    readonly IsConnected: 'True' | 'False'
    readonly IsLocal: 'True' | 'False'
}

export interface DymoConnectPrinter
    extends ConnectedPrinter<PrintDriverTypeCode.DymoConnect> {
    readonly info: DymoConnectPrinterInfo
}

const createPrinter = (info: DymoConnectPrinterInfo): DymoConnectPrinter =>
    Object.freeze({
        id: info.Name,
        name: info.Name,
        driverType: PrintDriverTypeCode.DymoConnect,
        info,
    })
