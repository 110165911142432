import {
    combineLatest,
    concat,
    EMPTY,
    Observable,
    of,
    ReplaySubject,
} from 'rxjs'
import { map, shareReplay, switchMap } from 'rxjs/operators'

export interface Loadable<T> {
    loading$: Observable<boolean>
    value$: Observable<T>
}

export interface Reloadable<T> extends Loadable<T> {
    reload(): void
}

export function reloadable<T>(load: () => Observable<T>): Reloadable<T> {
    const commandSubject = new ReplaySubject<void>()

    const result = {
        ...loadable(commandSubject.pipe(switchMap(load))),
        reload: () => commandSubject.next(),
    }

    commandSubject.next()

    return result
}

export function loadable<T>(observable: Observable<T>): Loadable<T>

export function loadable<T, R>(
    observable: Observable<T>,
    project: (value: T) => Observable<R>
): Loadable<R>

export function loadable<T, R>(
    observable: Observable<T>,
    project?: (value: T) => Observable<R>
): Loadable<R | T> {
    const loadState$: Observable<Loading | Loaded<R | T>> = project
        ? observable.pipe(
              switchMap((value) =>
                  concat(
                      of(loading),
                      project(value).pipe(map((v) => loaded(v)))
                  )
              ),
              shareReplay(1)
          )
        : concat(of(loading), observable.pipe(map((v) => loaded(v)))).pipe(
              shareReplay(1)
          )
    return {
        loading$: loadState$.pipe(map((loadState) => loadState.loading)),
        value$: loadState$.pipe(
            switchMap((loadState) =>
                loadState.loading ? EMPTY : of(loadState.value)
            )
        ),
    }
}

export function anyLoading(
    ...loadables: Loadable<unknown>[]
): Observable<boolean> {
    return combineLatest(loadables.map((loadable) => loadable.loading$)).pipe(
        map((loadings) => loadings.some((loading) => loading))
    )
}

interface Loading {
    loading: true
}

const loading: Loading = { loading: true }

interface Loaded<T> {
    loading: false
    value: T
}

function loaded<T>(value: T): Loaded<T> {
    return {
        loading: false,
        value,
    }
}
