import { Component, Vue } from 'vue-property-decorator'

import ImedsTube from './ImedsTube.vue'
import { useTubeStore } from '../../../stores/prescription/tube'

@Component({ components: { ImedsTube } })
export default class ImedsTubes extends Vue {
    readonly tubeStore = useTubeStore()
    async created() {
        window.imedsTubeStore = this.tubeStore
    }
}

declare global {
    //TODO: To delete when the prescription is in a SPA page
    interface Window {
        imedsTubeStore: unknown
    }
}
