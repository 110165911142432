﻿import { Device, getAvailablePrinters, write } from './zebraBrowserPrintWrapper'

import { PrintDriver } from '../PrintDriver'
import { PrintDataType, PrintDriverTypeCode } from '../../../../api/ImedsApi'
import { ConnectedPrinter } from '../ConnectedPrinter'

export const zebraBrowserPrintDriver: PrintDriver<PrintDriverTypeCode.ZebraBrowserPrint> =
    Object.freeze({
        printDriverType: PrintDriverTypeCode.ZebraBrowserPrint,
        printDataType: PrintDataType.Zpl,
        name: 'Zebra Browser Print',
        async getPrinters(): Promise<ZebraBrowserPrinter[]> {
            const devices: Device[] | null = await getAvailablePrinters()
            return devices?.map((device) => createPrinter(device)) ?? []
        },
        async print(
            printer:
                | ConnectedPrinter<PrintDriverTypeCode.ZebraBrowserPrint>
                | ZebraBrowserPrinter,
            data: string[]
        ): Promise<void> {
            let device = (printer as ZebraBrowserPrinter).device
            if (!device) {
                const id = printer.id
                const connectedPrinter = (
                    await zebraBrowserPrintDriver.getPrinters()
                ).find((zebraPrinter) => zebraPrinter.id === id) as
                    | ZebraBrowserPrinter
                    | undefined

                if (!connectedPrinter) {
                    throw new Error('print_error_disconnected')
                }

                device = connectedPrinter.device
            }

            await Promise.all(data.map((zpl) => write(device, zpl)))
        },
    })

export interface ZebraBrowserPrinter
    extends ConnectedPrinter<PrintDriverTypeCode.ZebraBrowserPrint> {
    readonly device: Device
}

const createPrinter = (device: Device): ZebraBrowserPrinter =>
    Object.freeze({
        id: device.name,
        name: device.name,
        driverType: PrintDriverTypeCode.ZebraBrowserPrint,
        device,
    })
