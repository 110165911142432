import { ref, computed } from 'vue'
import { defineLoadableStore } from '@internal-libraries/kheops-ui-lib/src/store/LoadableStore'
import {
    AnalysisProfilesClient,
    AnalysisProfileDto,
    MicrobiologySampleDto,
} from '../../api/ImedsApi'
import { useClient } from '../../api/clients'
import { useMicrobiologyStore } from './microbiology'
import { useAnalysisStore } from './analysis'
import {
    AnalysisProfileContent,
    SectionType,
} from '../../components/Prescription/AnalysisProfile/ImedsAnalysisProfileContentList'
import { AnalysisGroupItem } from '../../components/Prescription/AnalysisList/Models/AnalysisGroupItem'

const analysisProfilesClient = useClient(AnalysisProfilesClient)

export const useAnalysisProfileStore = defineLoadableStore(
    'analysisProfile',
    () => {
        const analysisStore = useAnalysisStore()
        const microbiologyStore = useMicrobiologyStore()

        const profiles = ref<AnalysisProfileDto[]>([])
        const selectedProfile = ref<AnalysisProfileDto | null>(null)

        const loadedProfiles = computed<AnalysisProfileDto[]>(() =>
            profiles.value.filter(
                (profile) =>
                    profile.analyses.every((profileAnalysis) =>
                        analysisStore.selectedAnalyses.some(
                            (analysis) => analysis.id == profileAnalysis.id
                        )
                    ) &&
                    profile.microbiologySelectedSamples.every(
                        (profileSelectedSample) =>
                            microbiologyStore.selectedSamples.some(
                                (selectedSample) =>
                                    selectedSample.sampleId ==
                                        profileSelectedSample.sampleId &&
                                    profileSelectedSample.selectedAnalyses.every(
                                        (profileSelectedAnalysis) =>
                                            selectedSample.selectedAnalyses.includes(
                                                profileSelectedAnalysis
                                            )
                                    )
                            )
                    ) &&
                    profile.analyses.length +
                        profile.microbiologySelectedSamples.length >
                        0
            )
        )

        const selectedProfileContent = computed<AnalysisProfileContent>(() =>
            [
                {
                    type: SectionType.Laboratory,
                    items:
                        selectedProfile.value?.analyses.map((analysis) => ({
                            name:
                                analysis.name ??
                                'prescription:unknown_analysis',
                        })) ?? [],
                },
                {
                    type: SectionType.Microbiology,
                    items:
                        selectedProfile.value?.microbiologySelectedSamples.map(
                            (selectedSample) => {
                                const sample = microbiologyStore.findSampleById(
                                    selectedSample.sampleId
                                )

                                return {
                                    name:
                                        sample?.name ??
                                        'microbio:unknown_sample',
                                    subItemNames:
                                        selectedSample.selectedAnalyses.map(
                                            (selectedAnalysisId) =>
                                                sample?.analyses.find(
                                                    (analysis) =>
                                                        analysis.id ===
                                                        selectedAnalysisId
                                                )?.name ??
                                                'microbio:unknown_analysis'
                                        ),
                                }
                            }
                        ) ?? [],
                },
            ].filter((section) => section.items.length > 0)
        )

        const newProfileContent = computed<AnalysisProfileContent>(() =>
            [
                {
                    type: SectionType.Laboratory,
                    items: analysisStore.selectedAnalyses.map((analysis) => ({
                        name: analysis.name ?? 'prescription:unknown_analysis',
                    })),
                },
                {
                    type: SectionType.Microbiology,
                    items: microbiologyStore.selectedSamples.map(
                        (selectedSample) => {
                            const sample = microbiologyStore.findSampleById(
                                selectedSample.sampleId
                            )

                            return {
                                name: sample?.name ?? 'microbio:unknown_sample',
                                subItemNames:
                                    selectedSample.selectedAnalyses.map(
                                        (analysisId) =>
                                            sample?.analyses.find(
                                                (analysis) =>
                                                    analysis.id === analysisId
                                            )?.name ??
                                            'microbio:unknown_analysis'
                                    ),
                            }
                        }
                    ),
                },
            ].filter((section) => section.items.length > 0)
        )

        const selectedAnalysisList = computed<AnalysisGroupItem[]>(() => {
            const itemGroups: AnalysisGroupItem[] | null = []

            if (analysisStore.selectedAnalyses.length > 0)
                itemGroups.push({
                    name: 'prescription:laboratory',
                    items: analysisStore.selectedAnalyses.map((analysis) => ({
                        name: analysis.name ?? '',
                    })),
                })

            if (microbiologyStore.selectedSamples.length > 0)
                itemGroups.push({
                    name: 'microbio:microbiology',
                    items: microbiologyStore.selectedSamplesWithCatalog.map(
                        ([, sample, analyses]) => ({
                            name: sample.name,
                            items: analyses.map((analysis) => ({
                                name: analysis.name,
                            })),
                        })
                    ),
                })

            if (itemGroups.length === 0)
                itemGroups.push({
                    name: 'prescription:no_analysis',
                })
            return itemGroups
        })

        const selectedProfileAnalysisList = computed<AnalysisGroupItem[]>(
            () => {
                const itemGroups: AnalysisGroupItem[] | null = []

                if (selectedProfile.value?.analyses?.length)
                    itemGroups.push({
                        name: 'prescription:laboratory',
                        items: selectedProfile.value.analyses.map(
                            (analysis) => ({
                                name: analysis.name ?? '',
                            })
                        ),
                    })

                if (selectedProfile.value?.microbiologySelectedSamples?.length)
                    itemGroups.push({
                        name: 'microbio:microbiology',
                        items: selectedProfile.value?.microbiologySelectedSamples.map(
                            (selectedSample) => {
                                const sample: MicrobiologySampleDto =
                                    microbiologyStore.findSampleById(
                                        selectedSample.sampleId
                                    )
                                return {
                                    name: sample.name,
                                    items: sample.analyses
                                        .filter((analysis) =>
                                            selectedSample.selectedAnalyses.includes(
                                                analysis.id
                                            )
                                        )
                                        .map((analysis) => ({
                                            name: analysis.name,
                                        })),
                                }
                            }
                        ),
                    })

                if (itemGroups.length === 0)
                    itemGroups.push({
                        name: 'prescription:no_analysis',
                    })
                return itemGroups
            }
        )

        return {
            profiles,
            loadedProfiles,
            selectedProfile,
            selectedProfileContent,
            newProfileContent,
            selectedAnalysisList,
            selectedProfileAnalysisList,
            deleteProfile: async (profileId: number): Promise<void> => {
                await analysisProfilesClient.deleteProfile(profileId)

                profiles.value = profiles.value.filter(
                    (profile) => profile.id != profileId
                )
            },
            updateProfile: async (name: string): Promise<void> => {
                if (!selectedProfile.value) return

                const updatedProfile = {
                    ...selectedProfile.value,
                    name: name,
                    analyses: analysisStore.selectedAnalyses,
                    microbiologySelectedSamples:
                        microbiologyStore.selectedSamples,
                }

                await analysisProfilesClient.updateProfile(updatedProfile)

                profiles.value = profiles.value.map((profile) =>
                    profile.id === updatedProfile.id ? updatedProfile : profile
                )
                selectedProfile.value = updatedProfile
            },
            createProfile: async (name: string): Promise<void> => {
                const newProfile = {
                    id: 0,
                    name: name,
                    analyses: analysisStore.selectedAnalyses,
                    microbiologySelectedSamples:
                        microbiologyStore.selectedSamples,
                }

                newProfile.id = await analysisProfilesClient.createProfile(
                    newProfile
                )
                profiles.value.push(newProfile)
                selectedProfile.value = newProfile
            },
        }
    },
    async (store) => {
        store.profiles.value = await analysisProfilesClient.getProfiles()
    }
)
