﻿<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { PrintDriverTypeCode, UserPrinterEditionDto } from '../../api/ImedsApi'
import { useTranslation } from '../../i18n/useTranslation'
import { printDriverTypes } from '../../stores/print/drivers'
import ReloadButton from '../ReloadButton/ReloadButton.vue'
import { KhIcon, KhButton, KhSelect } from '@internal-libraries/kheops-ui-lib'
import { usePrintersStore } from '../../stores/print/PrintersStore'
import { usePrintStore } from '../../stores/print/PrintStore'
import { UserPrinterWithConnectedPrinter } from '../../stores/print/UserPrinterWithConnectedPrinter'

const printStore = usePrintStore()
const printersStore = usePrintersStore()

export interface Props {
    printer: UserPrinterWithConnectedPrinter | null
}
const props = withDefaults(defineProps<Props>(), { printer: null })

const identifier = ref<string | null>(null)
const name = ref<string | null>(null)
const printDriverTypeCode = ref<PrintDriverTypeCode | null>(null)

const reset = () => {
    name.value = null
    identifier.value = null
    printDriverTypeCode.value = null
}

const validEditedPrinter = computed<UserPrinterEditionDto | null>(() => {
    return printDriverTypeCode.value && identifier.value && name.value
        ? {
              printDriverTypeCode: printDriverTypeCode.value,
              identifier: identifier.value,
              name: name.value,
          }
        : null
})

const isValid = computed(() => validEditedPrinter.value != null)

const selectedConnectedPrinter = computed(() => {
    return identifier.value
        ? printersStore.selectedDriverConnectedPrinters.find(
              (connectedPrinter) => connectedPrinter.id == identifier.value
          )
        : undefined
})

watch(
    () => props.printer,
    (printer) => {
        if (printer) {
            name.value = printer.name
            identifier.value = printer.identifier
            printDriverTypeCode.value = printer.printDriverTypeCode
            printersStore.reloadConnectedPrinters()
        } else {
            reset()
        }
    },
    { immediate: true }
)

watch(printDriverTypeCode, (newPrintDriverTypeCode) => {
    printersStore.selectedPrintDriverTypeCode = newPrintDriverTypeCode
})

watch(selectedConnectedPrinter, (newSelectedConnectedPrinter) => {
    if (newSelectedConnectedPrinter?.name && !name.value) {
        name.value = newSelectedConnectedPrinter.name
    }
})

const emit = defineEmits<{
    (e: 'cancel'): void
    (e: 'save', printer: UserPrinterEditionDto, userPrinterId?: number): void
}>()

const cancel = () => {
    emit('cancel')
    reset()
}

const save = () => {
    if (validEditedPrinter.value) {
        emit('save', validEditedPrinter.value, props.printer?.id)
    }
}

const printTestLabels = () => {
    printStore.printTestLabels(selectedConnectedPrinter.value)
}

const { t: $t } = useTranslation()
</script>
<template>
    <v-container fluid>
        <v-row dense>
            <v-col>
                <v-text-field
                    dense
                    :label="$t('print:name')"
                    v-model="name"
                    required
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <kh-select
                    label="print:print_driver"
                    :items="printDriverTypes"
                    :loading="printersStore.connectedPrintersLoading"
                    :readonly="printersStore.connectedPrintersLoading"
                    item-text="name"
                    item-value="type"
                    v-model="printDriverTypeCode"
                    :error-messages="$t(printersStore.selectedDriverError)"
                    dense
                />
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <kh-select
                    dense
                    label="print:printer"
                    :items="printersStore.selectedDriverConnectedPrinters"
                    :loading="printersStore.connectedPrintersLoading"
                    :readonly="printersStore.connectedPrintersLoading"
                    item-text="name"
                    item-value="id"
                    :no-data-text="$t('print:no_printer_found')"
                    v-model="identifier"
                    :error-messages="
                        identifier && !selectedConnectedPrinter
                            ? $t('print:disconnected_printer_warning')
                            : null
                    "
                >
                    <template #append-outer>
                        <reload-button
                            @reload="printersStore.reloadConnectedPrinters()"
                            :tooltip="$t('print:refresh_printer_list')"
                            :loading="printersStore.connectedPrintersLoading"
                        />
                    </template>
                </kh-select>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col>
                <kh-button
                    @click="printTestLabels()"
                    class="mr-2"
                    :disabled="!selectedConnectedPrinter"
                >
                    <kh-icon icon="mdi-printer" small class="mr-1" />
                    {{ $t('print:print_test') }}
                </kh-button>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col class="text-right">
                <kh-button @click="cancel" small class="mr-4">
                    {{ $t('print:cancel') }}
                </kh-button>
                <kh-button
                    :disabled="!isValid"
                    @click="save"
                    small
                    color="primary"
                >
                    {{ $t('print:save') }}
                </kh-button>
            </v-col>
        </v-row>
    </v-container>
</template>
