import Vue from 'vue'
import Component from 'vue-class-component'
import { KhTooltip, KhButton, KhIcon } from '@internal-libraries/kheops-ui-lib'
import { useAnalysisStore } from '../../../../stores/prescription/analysis'
import { useMicrobiologyStore } from '../../../../stores/prescription/microbiology'
import { useCurrentUserStore } from '../../../../stores/currentUser'
import ImedsAnalysisProfileCreationModal from './ImedsAnalysisProfileCreationModal.vue'
import { useAnalysisProfileStore } from '../../../../stores/prescription/analysisProfile'
import { Roles } from '../../../../api/ImedsApi'

@Component({
    components: {
        KhTooltip,
        KhButton,
        KhIcon,
        ImedsAnalysisProfileCreationModal,
    },
})
export default class ImedsAnalysisProfileCreationButton extends Vue {
    readonly analysesStore = useAnalysisStore()
    readonly microbiologyStore = useMicrobiologyStore()
    readonly currentUserStore = useCurrentUserStore()
    readonly analysisProfileStore = useAnalysisProfileStore()

    creationModal = false

    async created() {
        window.imedsAnalysesStore = this.analysesStore
    }

    get visible(): boolean {
        return this.currentUserStore.hasAnyRoleIn(
            Roles.ProfileEditor,
            Roles.AdminConnectedAs
        )
    }

    get disabled(): boolean {
        return (
            this.analysisProfileStore.loading ||
            this.analysesStore.selectedAnalyses.length +
                this.microbiologyStore.selectedSamples.length <=
                0
        )
    }
}
