import { KhComponent } from '@internal-libraries/kheops-ui-lib'
import { Component, Watch } from 'vue-property-decorator'
import { anyLoading, loadable, Loadable } from '../../utils/Loadable'
import {
    ActionCodes,
    CompaniesClient,
    CompanyDto,
    DataTableConfigurationDto,
    OfficeDto,
    OfficeEditionDto,
    OfficesClient,
    OfficeUsesDto,
    SortDirection,
} from '../../api/ImedsApi'
import { useClient } from '../../api/clients'
import { useErrorStore } from '../../stores/error'
import {
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    PagedResultDto,
    ItemAction,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
} from '../ImedsDataTable'
import ImedsDataTable from '../ImedsDataTable.vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import ImedsOfficeForm from './ImedsOfficeForm.vue'

@Component({
    components: {
        ImedsOfficeForm,
        ImedsDataTable,
    },
    subscriptions(this: ImedsOfficeAdministration) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.officesClient.getConfiguration()))

        const loadablePagedResult: Loadable<PagedResultDto<OfficeDto>> =
            loadable(
                combineLatest([
                    combineLatest([this.page$, this.pageSize$]),
                    combineLatest([this.sortBy$, this.sortDirections$]),
                    combineLatest([this.Name$, this.Code$, this.CompanyId$]),
                    this.refreshActionSubject$,
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([
                    [page, pageSize],
                    [sortBy, sortDirections],
                    [name, code, companyId],
                ]) =>
                    from(
                        this.officesClient.getOffices(
                            sortBy,
                            sortDirections,
                            pageSize,
                            page,
                            true,
                            name,
                            code,
                            companyId
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            code: this.Code$,
            name: this.Name$,
            companyId: this.CompanyId$,
        })
    },
})
export default class ImedsOfficeAdministration extends KhComponent {
    readonly officesClient = useClient(OfficesClient)
    readonly companiesStore = useClient(CompaniesClient)
    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)

    readonly Name$ = new BehaviorSubject<string | undefined>(undefined)
    readonly Code$ = new BehaviorSubject<string | undefined>(undefined)
    readonly CompanyId$ = new BehaviorSubject<number | undefined>(undefined)

    companies: CompanyDto[] = []
    formModal = false
    disableModal = false
    enableModal = false
    currentOffice: OfficeDto | null = null
    currentOfficeUses: OfficeUsesDto | null = null
    async mounted() {
        this.companies = await this.companiesStore.getCompanies()
    }
    async onItemAction(itemAction: ItemAction<OfficeDto>): Promise<void> {
        this.currentOffice = itemAction.item
        switch (itemAction.actionCode) {
            case ActionCodes.Office.Edit:
                this.openFormModal()
                break
            case ActionCodes.Office.Disable:
                await this.openDisableModal()
                break
            case ActionCodes.Office.Enable:
                await this.openEnableModal()
                break
        }
        return
    }
    openFormModal() {
        this.formModal = true
    }
    closeFormModal() {
        this.formModal = false
    }
    @Watch('formModal')
    resetData(open: boolean) {
        if (!open)
            setTimeout(() => {
                this.currentOffice = null
            }, 500)
    }

    async openDisableModal(): Promise<void> {
        if (!this.currentOffice?.id) return

        this.currentOfficeUses = await this.officesClient.getOfficeUses(
            this.currentOffice.id
        )
        this.disableModal = true
    }

    closeDisableModal(): void {
        this.disableModal = false
        this.currentOffice = null
    }

    openEnableModal(): void {
        this.enableModal = true
    }

    closeEnableModal(): void {
        this.enableModal = false
        this.currentOffice = null
    }

    async createOffice(office: OfficeEditionDto) {
        await this.officesClient.create(office)
        this.closeFormModal()
        this.refreshActionSubject$.next()
    }

    async editOffice(office: OfficeEditionDto & { id: number }): Promise<void> {
        await this.officesClient.edit(office.id, office)
        this.closeFormModal()
        this.refreshActionSubject$.next()
    }

    async disableOffice() {
        if (!this.currentOffice?.id) return

        await this.officesClient.disable(this.currentOffice?.id)
        this.closeDisableModal()
        this.refreshActionSubject$.next()
    }

    async enableOffice() {
        if (!this.currentOffice?.id) return

        await this.officesClient.enable(this.currentOffice?.id)
        this.closeEnableModal()
        this.refreshActionSubject$.next()
    }
}
