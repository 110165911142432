﻿import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import { parseISO, format } from 'date-fns'
import { KhButton } from '@internal-libraries/kheops-ui-lib'
import { NurseLogisticsRequestDto } from '../../api/ImedsApi'
import { usePrintStore } from '../../stores/print/PrintStore'
import { useMicrobiologyStore } from '../../stores/prescription/microbiology'

@Component({ components: { KhButton } })
export default class NurseOrderDetail extends Vue {
    printStore = usePrintStore()
    readonly microbiologyStore = useMicrobiologyStore()

    @Prop({ required: true })
    request!: NurseLogisticsRequestDto

    @Prop({ default: true, type: Boolean })
    printable!: boolean

    get formattedPatientBirthDate(): string {
        return this.request.details.patientBirthday
            ? format(
                  parseISO(this.request.details.patientBirthday.toString()),
                  'MM/dd/yyyy'
              )
            : ''
    }

    get microbiologySelectedSamples(): { name: string; analyses: string[] }[] {
        return this.request.details.microbioSamples.map((selectedSample) => {
            const sample = this.microbiologyStore.findSampleById(
                selectedSample.sampleId
            )
            return {
                name: sample.name,
                analyses: sample.analyses
                    .filter((analysis) =>
                        selectedSample.selectedAnalyses.includes(analysis.id)
                    )
                    .map((analysis) => analysis.name),
            }
        })
    }

    print(): void {
        this.printStore.printOrders(this.request.orderId)
    }
}
