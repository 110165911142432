﻿import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { useClient } from '../../api/clients'

import {
    CourierOrderCreationDto,
    CourierOrdersClient,
    ImedsDtoErrors,
} from '../../api/ImedsApi'
import {
    KhDateTimePicker,
    KhSnackbarManager,
} from '@internal-libraries/kheops-ui-lib'
import { useErrorStore } from '../../stores/error'
import { useNotificationStore } from '../../stores/notification'

@Component({
    components: { KhDateTimePicker, KhSnackbarManager },
})
export default class CourierCreateOrderForm extends Vue {
    readonly courierOrdersClient = useClient(CourierOrdersClient)
    readonly errorStore = useErrorStore()
    readonly notificationStore = useNotificationStore()

    pickupDateTimeOrder: Date = this.minPickupDatetime
    isEmergency = false
    comment = ''

    errors: ImedsDtoErrors<CourierOrderCreationDto> = {}

    isCreating = false

    async createCourierOrder(): Promise<void> {
        if (this.isCreating) return

        this.isCreating = true

        try {
            await this.courierOrdersClient.create({
                pickupDateTime: this.pickupDateTimeOrder,
                isEmergency: this.isEmergency,
                comment: this.comment,
            })
            this.notificationStore.notifySuccess(
                this.$t(
                    'courier_overview:courier_order_creation.request_success'
                )
            )
            this.pickupDateTimeOrder = this.minPickupDatetime
            this.isEmergency = false
            this.comment = ''
        } catch (error) {
            this.errors =
                this.errorStore.handleDtoError<CourierOrderCreationDto>(error)
            this.notificationStore.notifyError(
                this.$t('courier_overview:courier_order_creation.request_error')
            )
        }

        this.isCreating = false
    }

    get minPickupDatetime(): Date {
        return new Date()
    }
}
