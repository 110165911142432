import { v4 as uuidv4 } from 'uuid'
import {
    PrintDataType,
    PrintDriverTypeCode,
    Wcpp2Client,
} from '../../../../api/ImedsApi'
import { useClient } from '../../../../api/clients'
import { PrintDriver } from '../PrintDriver'
import { ConnectedPrinter } from '../ConnectedPrinter'

const wcpp2Client = useClient(Wcpp2Client)

export const zebraWcpp2PrintDriver: PrintDriver<PrintDriverTypeCode.ZebraWcpp2> =
    Object.freeze({
        printDriverType: PrintDriverTypeCode.ZebraWcpp2,
        printDataType: PrintDataType.Zpl,
        name: 'WCPP2',
        getPrinters: async (): Promise<
            ConnectedPrinter<PrintDriverTypeCode.ZebraWcpp2>[]
        > => {
            const printersRequestId = uuidv4()
            sendUrlToWcpp2Client(
                `/webapi/wcpp2/printers/${printersRequestId}/save?`,
                '-getPrinters:'
            )

            await new Promise((r) => setTimeout(r, 5000))
            const printerNames = await wcpp2Client.getPrinters(
                printersRequestId
            )
            return !printerNames
                ? []
                : printerNames.map((printerName) => createPrinter(printerName))
        },
        print: async (
            printer: ConnectedPrinter<PrintDriverTypeCode.ZebraWcpp2>,
            data: string[]
        ): Promise<void> => {
            const jobId = await wcpp2Client.createPrintJob(
                data.join('\n'),
                printer.id
            )
            sendUrlToWcpp2Client(`/webapi/wcpp2/jobs/${jobId}`)
        },
    })

function sendUrlToWcpp2Client(relativeUrl: string, action = ''): void {
    // This hack is necessary because the alternatives have issues:
    // - opening the link in the current window cancels all the pending XHR
    // - opening the link in a new windows requires to show a new window close it, which will be visible for the user

    const iframe = document.createElement('iframe')
    iframe.width = '1'
    iframe.height = '1'
    iframe.style.visibility = 'hidden'
    iframe.style.position = 'absolute'
    iframe.src = `webclientprint:${action}${window.location.origin}${relativeUrl}`
    document.body.appendChild(iframe)

    setTimeout(() => {
        document.body.removeChild(iframe)
    }, 5000)
}

const createPrinter = (
    name: string
): ConnectedPrinter<PrintDriverTypeCode.ZebraWcpp2> =>
    Object.freeze({
        id: name,
        name: name,
        driverType: PrintDriverTypeCode.ZebraWcpp2,
    })
