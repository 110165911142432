﻿import Vue, { ref, computed } from 'vue'
import { defineLoadableStore } from '@internal-libraries/kheops-ui-lib/src/store/LoadableStore'
import { useClient } from '../../api/clients'
import {
    MicrobiologyAnalysisDto,
    MicrobiologyClient,
    MicrobiologySampleDto,
    MicrobiologySelectedSampleDto,
    MicrobiologyTypeDto,
} from '../../api/ImedsApi'
import { findIndex, chain } from 'lodash'
import { useTranslation } from '../../i18n/useTranslation'
import { namespaces } from '../../components/Prescription/Microbiology/i18n'
import { SortOrder } from '../../components/Prescription/Microbiology/SortOrder'

const microbiologyClient = useClient(MicrobiologyClient)

export const useMicrobiologyStore = defineLoadableStore(
    'microbiology',
    () => {
        const catalog = ref<MicrobiologyTypeDto[]>([])
        const selectedSamples = ref<MicrobiologySelectedSampleDto[]>([])

        const { tDefault, tHash } = useTranslation()

        const translatedCatalog = computed<MicrobiologyTypeDto[]>({
            get: () =>
                catalog.value.map((type) => ({
                    ...type,
                    name: tHash.value(namespaces.type, type.name),
                    samples: type.samples.map((sample) => ({
                        ...sample,
                        name: tDefault.value(
                            namespaces.sample,
                            sample.code,
                            sample.name
                        ),
                        analyses: sample.analyses.map((analysis) => ({
                            ...analysis,
                            name: tDefault.value(
                                namespaces.analysis,
                                analysis.code.split('!')[0],
                                analysis.name
                            ),
                            category: {
                                ...analysis.category,
                                name: tHash.value(
                                    namespaces.category,
                                    analysis.category.name
                                ),
                            },
                        })),
                        tubes: sample.tubes.map((tube) => ({
                            ...tube,
                            name: tHash.value(namespaces.specimen, tube.name),
                        })),
                    })),
                })),
            set: (value) => {
                catalog.value = value
            },
        })

        const unselectSample = (
            sample: MicrobiologySelectedSampleDto
        ): void => {
            const sampleIndex = findIndex(selectedSamples.value, sample)
            selectedSamples.value.splice(sampleIndex, 1)
        }

        const findSampleById = computed(
            () =>
                (sampleId: number): MicrobiologySampleDto => {
                    const sample =
                        chain(translatedCatalog.value ?? [])
                            .flatMap((type) => type.samples)
                            .find((sample) => sample.id === sampleId)
                            .value() ?? null

                    if (!sample) throw new Error()

                    return sample
                }
        )

        const selectedSamplesWithCatalogSortOrder = ref<SortOrder>(
            SortOrder.ASC
        )

        return {
            catalog: translatedCatalog,
            selectedSamples,
            selectSample: (
                selectedSample: MicrobiologySelectedSampleDto
            ): void => {
                selectedSamples.value.push(selectedSample)
            },
            unselectSample,
            unselectAnalysis: (
                selectedSample: MicrobiologySelectedSampleDto,
                analysisId: number
            ): void => {
                const selectedAnalysisIndex =
                    selectedSample.selectedAnalyses.findIndex(
                        (selectedAnalysis) => selectedAnalysis == analysisId
                    )
                selectedSample.selectedAnalyses.splice(selectedAnalysisIndex, 1)
                if (selectedSample.selectedAnalyses.length <= 0) {
                    unselectSample(selectedSample)
                }
            },
            updateSelectedSample: (
                oldSelectedSample: MicrobiologySelectedSampleDto,
                newSelectedSample: MicrobiologySelectedSampleDto
            ): void => {
                if (newSelectedSample.selectedAnalyses.length <= 0) {
                    unselectSample(oldSelectedSample)
                    return
                }

                const selectedSampleIndex = findIndex(
                    selectedSamples.value,
                    oldSelectedSample
                )

                Vue.set(
                    selectedSamples.value,
                    selectedSampleIndex,
                    newSelectedSample
                )
            },
            resetSelectedSamples: (): void => {
                selectedSamples.value = []
            },
            findSampleById,
            selectedSamplesWithCatalog: computed(() =>
                chain(selectedSamples.value)
                    .map((selectedSample): SelectedSampleWithCatalog => {
                        const sample = findSampleById.value(
                            selectedSample.sampleId
                        )

                        if (!sample) throw new Error('microbio:unknown_sample')

                        return [
                            selectedSample,
                            sample,
                            selectedSample.selectedAnalyses.map(
                                (selectedAnalysisId) => {
                                    const analysis = sample?.analyses.find(
                                        (analysis) =>
                                            analysis.id === selectedAnalysisId
                                    )

                                    if (!analysis)
                                        throw new Error(
                                            'microbio:unknown_analysis'
                                        )

                                    return analysis
                                }
                            ),
                        ]
                    })
                    .orderBy(
                        [
                            ([_, sample]) => sample.name,
                            ([selectedSample, _]) =>
                                selectedSample.samplingDate,
                        ],
                        [
                            selectedSamplesWithCatalogSortOrder.value,
                            SortOrder.ASC,
                        ]
                    )
                    .value()
            ),
            selectedSamplesWithCatalogSortOrder,
        }
    },
    async (store) => {
        store.catalog.value = await microbiologyClient.getCatalog()
    }
)

export type SelectedSampleWithCatalog = [
    MicrobiologySelectedSampleDto,
    MicrobiologySampleDto,
    MicrobiologyAnalysisDto[]
]
