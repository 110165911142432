﻿import { useClient } from '../../api/clients'
import ImedsDataTable from '../ImedsDataTable.vue'
import {
    DataTableConfigurationDto,
    ActionCodes,
    SortDirection,
    AnalysisRecordDto,
    AnalysisRecordClient,
    AnalysisRecordFilterOptionsDto,
} from '../../api/ImedsApi'
import { loadable, Loadable, anyLoading } from '../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../ImedsDataTable'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { useErrorStore } from '../../stores/error'
import { Component } from 'vue-property-decorator'
import { useCurrentUserStore } from '../../stores/currentUser'
import {
    KhAutocomplete,
    KhDateRangePicker,
    KhDatePicker,
} from '@internal-libraries/kheops-ui-lib'

@Component({
    components: {
        ImedsDataTable,
        KhAutocomplete,
        KhDateRangePicker,
        KhDatePicker,
    },
    subscriptions(this: ImedsAnalysisRecordsTable) {
        const loadableFilters = loadable(
            combineLatest([
                combineLatest([
                    combineLatest([this.page$, this.pageSize$]),
                    combineLatest([this.sortBy$, this.sortDirections$]),
                    combineLatest([this.filterDateFrom$, this.filterDateTo$]),
                    combineLatest([this.firstName$, this.lastName$]),
                ]),
                combineLatest([
                    combineLatest([this.birthDate$, this.externalRecordId$]),
                    combineLatest([this.requester$, this.correspondent$]),
                    combineLatest([
                        this.companyFilter$,
                        this.refreshActionSubject$,
                    ]),
                ]),
                combineLatest([
                    combineLatest([this.isEmergency$, this.isRead$]),
                    combineLatest([this.isPatho$]),
                ]),
            ]).pipe(debounceTime(100)),
            ([
                [
                    [page, pageSize],
                    [sortBy, sortDirections],
                    [filterDateFrom, filterDateTo],
                    [firstName, lastName],
                ],
                [
                    [birthDate, externalRecordId],
                    [requester, correspondent],
                    [companyFilter],
                ],
                [[isEmergencyOnly, isRead], [isPatho]],
            ]) =>
                from(
                    this.SetFilters(
                        filterDateFrom,
                        filterDateTo,
                        firstName ?? undefined,
                        lastName ?? undefined,
                        birthDate,
                        externalRecordId ?? undefined,
                        requester ?? undefined,
                        correspondent ?? undefined,
                        companyFilter,
                        isEmergencyOnly,
                        isRead,
                        isPatho,
                        sortBy,
                        sortDirections,
                        pageSize,
                        page,
                        true
                    )
                )
        )
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.imedsAnalysisRecordsClient.getConfiguration()))

        const loadablePagedResult: Loadable<PagedResultDto<AnalysisRecordDto>> =
            loadable(
                combineLatest([
                    combineLatest([
                        combineLatest([this.page$, this.pageSize$]),
                        combineLatest([this.sortBy$, this.sortDirections$]),
                        combineLatest([
                            this.filterDateFrom$,
                            this.filterDateTo$,
                        ]),
                        combineLatest([this.firstName$, this.lastName$]),
                    ]),
                    combineLatest([
                        combineLatest([
                            this.birthDate$,
                            this.externalRecordId$,
                        ]),
                        combineLatest([this.requester$, this.correspondent$]),
                        combineLatest([
                            this.companyFilter$,
                            this.refreshActionSubject$,
                        ]),
                        combineLatest([this.isEmergency$, this.isRead$]),
                        combineLatest([this.isPatho$]),
                    ]),
                    combineLatest([
                        combineLatest([this.isEmergency$, this.isRead$]),
                        combineLatest([this.isPatho$]),
                    ]),
                ]).pipe(debounceTime(100)),
                ([
                    [
                        [page, pageSize],
                        [sortBy, sortDirections],
                        [filterDateFrom, filterDateTo],
                        [firstName, lastName],
                    ],
                    [
                        [birthDate, externalRecordId],
                        [requester, correspondent],
                        [companyFilter],
                    ],
                    [[isEmergencyOnly, isRead], [isPatho]],
                ]) =>
                    from(
                        this.imedsAnalysisRecordsClient.get(
                            filterDateFrom,
                            filterDateTo,
                            firstName ?? undefined,
                            lastName ?? undefined,
                            birthDate,
                            externalRecordId ?? undefined,
                            requester ?? undefined,
                            correspondent ?? undefined,
                            companyFilter,
                            isEmergencyOnly,
                            isRead,
                            isPatho,
                            sortBy,
                            sortDirections,
                            pageSize,
                            page,
                            true
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            filterDateFrom: this.filterDateFrom$,
            filterDateTo: this.filterDateTo$,
            birthDate: this.birthDate$,
            externalRecordId: this.externalRecordId$,
            correspondent: this.correspondent$,
            requester: this.requester$,
            firstName: this.firstName$,
            lastName: this.lastName$,
            company: this.companyFilter$,
            isEmergency: this.isEmergency$,
            isRead: this.isRead$,
            isPatho: this.isPatho$,
            filter: loadableFilters.value$,
        })
    },
})
export default class ImedsAnalysisRecordsTable extends Vue {
    readonly imedsAnalysisRecordsClient = useClient(AnalysisRecordClient)
    readonly errorStore = useErrorStore()
    readonly currentUserStore = useCurrentUserStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly filterDateFrom$ = new BehaviorSubject<Date | null>(null)
    readonly filterDateTo$ = new BehaviorSubject<Date | null>(null)
    readonly isEmergency$ = new BehaviorSubject<boolean | undefined>(undefined)
    readonly isRead$ = new BehaviorSubject<boolean | undefined>(undefined)
    readonly isPatho$ = new BehaviorSubject<boolean | undefined>(undefined)
    readonly birthDate$ = new BehaviorSubject<Date | null>(null)
    readonly firstName$ = new BehaviorSubject<string | undefined>(undefined)
    readonly lastName$ = new BehaviorSubject<string | undefined>(undefined)
    readonly externalRecordId$ = new BehaviorSubject<string | undefined>(
        undefined
    )
    readonly requester$ = new BehaviorSubject<string | undefined>(undefined)
    readonly correspondent$ = new BehaviorSubject<string | undefined>(undefined)
    readonly companyFilter$ = new BehaviorSubject<number | undefined>(undefined)

    currentItem: AnalysisRecordDto | null = null
    analysisRecords: AnalysisRecordFilterOptionsDto | null = null
    firstNameList: string[] | null = null
    lastNameList: string[] | null = null
    externalIdList: string[] | null = null
    requesterList: string[] | null = null
    correspondentList: string[] | null = null
    filterRuleList: object[] | null = null
    dialogConsult = false
    dialogNotConsult = false
    readDossier = false

    async created(): Promise<void> {
        this.filterRuleList = [
            { id: null, idc: 'Tous' },
            ...(await this.imedsAnalysisRecordsClient.getRules()),
        ]
    }

    async onItemAction(
        itemAction: ItemAction<AnalysisRecordDto>
    ): Promise<void> {
        switch (itemAction.actionCode) {
            case ActionCodes.AnalysisRecord.MarkAsRead:
                this.currentItem = itemAction.item
                this.dialogNotConsult = true
                this.readDossier = true
                break
            case ActionCodes.AnalysisRecord.MarkAsUnread:
                this.currentItem = itemAction.item
                this.dialogConsult = true
                break
        }
    }
    redirectToResultPage(analysisRecord: AnalysisRecordDto) {
        window.location.href =
            `/results.aspx?` +
            new URLSearchParams({
                id: analysisRecord?.idDos.toString(),
                idbie: '0',
            })
    }
    async UpdateReadStatus(): Promise<void> {
        if (!this.currentItem?.idDos) return

        await this.imedsAnalysisRecordsClient.updateAnalysisRecord(
            this.currentItem.idDos,
            this.readDossier
        )
        this.refreshActionSubject$.next()
    }

    private async SetFilters(
        fromDate?: Date | null | undefined,
        toDate?: Date | null | undefined,
        firstName?: string | undefined,
        lastName?: string | undefined,
        birthDate?: Date | null | undefined,
        externalRecordId?: string | undefined,
        requester?: string | undefined,
        correspondent?: string | undefined,
        company?: number | null | undefined,
        isEmergency?: boolean | undefined,
        isRead?: boolean | undefined,
        isPatho?: boolean | undefined,
        sortBy?: string[] | null | undefined,
        sortDirections?: SortDirection[] | undefined,
        pageSize?: number | null | undefined,
        page?: number | null | undefined,
        isPagingEnabled?: boolean | null | undefined
    ): Promise<void> {
        this.analysisRecords = await this.imedsAnalysisRecordsClient.getFilter(
            fromDate,
            toDate,
            firstName,
            lastName,
            birthDate,
            externalRecordId,
            requester,
            correspondent,
            company,
            isEmergency,
            isRead,
            isPatho,
            sortBy,
            sortDirections,
            pageSize,
            page,
            isPagingEnabled
        )
        this.firstNameList = this.analysisRecords.firstName ?? null
        this.lastNameList = this.analysisRecords.lastName ?? null
        this.externalIdList = this.analysisRecords.externalRecordId ?? null
        this.requesterList = this.analysisRecords.requester ?? null
        this.correspondentList = this.analysisRecords.correspondent ?? null
    }

    resetData(): void {
        this.currentItem = null
    }
}
