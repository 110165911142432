import { Icon, IconColor } from '../api/ImedsApi'
import * as mdi from '@mdi/js'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { KhTooltip } from '@internal-libraries/kheops-ui-lib'
import * as khi from '@internal-libraries/kheops-ui-lib/src/components/Atoms/KhIcon/KhCustomIcons'

@Component({ components: { KhTooltip } })
export default class ImedsIcon extends Vue {
    @Prop({ required: true })
    icon!: Icon

    @Prop({ required: false })
    tooltip!: string

    @Prop({ required: false })
    color?: IconColor

    @Prop({ required: false })
    left?: boolean

    @Prop({ required: false })
    right?: boolean

    @Prop({ required: false })
    top?: boolean

    @Prop({ required: false })
    bottom?: boolean

    @Prop()
    size?: string

    get iconContent(): string {
        return iconMappings[this.icon]
    }

    get colorValue(): string | null {
        return this.color ? colorMappings[this.color] : null
    }
}

const iconMappings: Record<Icon, string> = {
    Delete: mdi.mdiDelete,
    Eye: mdi.mdiEye,
    Warning: mdi.mdiAlert,
    Detail: mdi.mdiBookOpenVariant,
    Complete: mdi.mdiCheck,
    Cancel: mdi.mdiCancel,
    Home: mdi.mdiHome,
    Hospital: mdi.mdiHospitalBuilding,
    Office: khi.khiDoctorOffice,
    Clinic: khi.khiHospital,
    SamplingCenter: khi.khiSamplingCenter,
    NurseAtHome: khi.khiNurseAtHome,
    StatusAccepted: mdi.mdiAccountClock,
    StatusDone: mdi.mdiCheckOutline,
    Pending: mdi.mdiClockOutline,
    Expand: mdi.mdiChevronDown,
    Collapse: mdi.mdiChevronUp,
    Undo: mdi.mdiUndo,
    Assign: mdi.mdiAccountCheckOutline,
    Edit: mdi.mdiPencil,
    Information: mdi.mdiInformationOutline,
    Reject: mdi.mdiClose,
    AnalysisStatus: mdi.mdiFlask,
    OpenLetter: mdi.mdiEmailOpen,
    ClosedLetter: mdi.mdiEmailOff,
    AnalysisIsPathologic: mdi.mdiAlertOutline,
    Emergency: mdi.mdiRocket,
    Print: mdi.mdiPrinter,
    Copy: mdi.mdiContentCopy,
    Record: mdi.mdiFolderMoveOutline,
    History: mdi.mdiClipboardClock,
    Add: mdi.mdiPlus,
    MinusCircleMultiple: mdi.mdiMinusCircleMultipleOutline,
    MinusCircle: mdi.mdiMinusCircleOutline,
    SortAsc: mdi.mdiSortAlphabeticalAscending,
    SortDesc: mdi.mdiSortAlphabeticalDescending,
    Phone: mdi.mdiPhone,
    Favourite: mdi.mdiStar,
    Disconnected: mdi.mdiPowerPlugOffOutline,
    Filter: mdi.mdiFilterOutline,
    ArrowUp: mdi.mdiArrowUp,
    ArrowDown: mdi.mdiArrowDown,
}

const colorMappings: Record<IconColor, string | null> = {
    [IconColor.Default]: null,
    [IconColor.Success]: 'success',
    [IconColor.Warning]: 'warning',
    [IconColor.Info]: 'info',
    [IconColor.Error]: 'error',
}
