﻿import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import '@internal-libraries/kheops-ui-lib/dist/kheops-ui-lib.css'
import 'i18next-vue'
import {
    KheopsUiLib,
    registerComponents,
    RootState,
} from '@internal-libraries/kheops-ui-lib'
import Vue from 'vue'
import { ModuleTree } from 'vuex'
import VueRx from 'vue-rx'
import * as components from '../components'
import { backendConfigurations, namespaces } from './i18n'
import { useErrorStore } from '../stores/error'

export interface InitOptions {
    language?: string
    storeModules?: ModuleTree<RootState>
}

export function init({
    language,
    storeModules,
}: InitOptions): KheopsUiLib['plugins'] {
    Vue.config.devtools = true
    Vue.use(VueRx)

    const kheopsUiLib = KheopsUiLib.init(Vue, {
        i18n: {
            language,
            backendConfigurations,
            namespaces,
        },
        storeModules,
        httpClient: {
            baseUrl: import.meta.env.BASE_URL,
        },
    })

    const errorStore = useErrorStore(kheopsUiLib.plugins.pinia)
    Vue.config.errorHandler = (err: Error, vm: Vue, info: string): void => {
        errorStore.handleError(err, { vm, info })
    }

    if (components) {
        registerComponents(Vue, components)
    }

    return kheopsUiLib.plugins
}
