﻿import { KhComponent } from '@internal-libraries/kheops-ui-lib'
import Component from 'vue-class-component'
import { BehaviorSubject } from 'rxjs'
import { ImedsInputExposerElement } from './ImedsInputExposerElement'

@Component({ inheritAttrs: true })
export default class ImedsInputExposer<TModel> extends KhComponent {
    modelSubject: BehaviorSubject<TModel | undefined>
    binders: { model: TModel | undefined }

    constructor() {
        super()
        const modelSubject = new BehaviorSubject<TModel | undefined>(undefined)
        this.modelSubject = modelSubject

        this.binders = {
            get model(): TModel | undefined {
                return modelSubject.value
            },
            set model(value: TModel | undefined) {
                modelSubject.next(value)
            },
        }
    }

    $el!: ImedsInputExposerElement<TModel>

    mounted(): void {
        this.$el._imedsInputExposer = {
            modelSubject: this.modelSubject,
        }
    }
}
