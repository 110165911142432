﻿import { useClient } from '../../../../api/clients'
import ImedsDataTable from '../../../../components/ImedsDataTable.vue'
import PrintableDocument from '../../../../components/Pdf/PrintableDocument.vue'
import {
    ActionCodes,
    AdditionDto,
    AdditionsLogisticsClient,
    AdditionUpdateDto,
    DataTableConfigurationDto,
    OfficeDto,
    Roles,
    OfficesClient,
    SortDirection,
} from '../../../../api/ImedsApi'
import { anyLoading, Loadable, loadable } from '../../../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../../../../components/ImedsDataTable'
import { Component, Prop, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime, tap } from 'rxjs/operators'
import { VSelectItem } from '../../../../components/Courier/VSelectItem'
import {
    Status,
    statusText,
} from '../../../../components/models/Courier/Status'
import {
    KhAutocomplete,
    KhButton,
    KhConfirmModal,
    KhModal,
} from '@internal-libraries/kheops-ui-lib'
import { useCurrentUserStore } from '../../../../stores/currentUser'
import ImedsCompanySelector from '../../../../components/Company/ImedsCompanySelector.vue'
import { createDatatableAutorefreshObservable } from '../../../../utils/Datatable'
import { useErrorStore } from '../../../../stores/error'
import {
    ImedsLogisticsHistoryState,
    showHistory,
} from '../../../../components/History/ImedsLogisticsHistory'

@Component({
    components: {
        ImedsDataTable,
        KhConfirmModal,
        KhButton,
        KhModal,
        PrintableDocument,
        ImedsCompanySelector,
        KhAutocomplete,
    },
    subscriptions(this: AdditionTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.additionsLogisticsClient.getConfiguration()))

        const refresh$ = createDatatableAutorefreshObservable(
            this.refreshActionSubject$,
            this.page$
        )

        const loadablePagedResult: Loadable<PagedResultDto<AdditionDto>> =
            loadable(
                combineLatest([
                    combineLatest([this.pageSize$, this.page$]),
                    combineLatest([this.sortBy$, this.sortDirections$]),
                    combineLatest([this.statusFilter$, this.companyFilter$]),
                    this.unitPrint$,
                    refresh$,
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([
                    [pageSize, page],
                    [sortBy, sortDirections],
                    [statusFilter, companyFilter],
                    unitPrint,
                ]) =>
                    from(
                        this.additionsLogisticsClient.get(
                            pageSize,
                            page,
                            sortBy,
                            sortDirections,
                            !this.printable,
                            statusFilter,
                            companyFilter,
                            unitPrint
                        )
                    ).pipe(
                        tap(() => {
                            window.dispatchEvent(this.readyToPrint)
                        })
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            statusFilter: this.statusFilter$,
            companyFilter: this.companyFilter$,
            unitPrint: this.unitPrint$,
        })
    },
})
export default class AdditionTable extends Vue {
    readonly additionsLogisticsClient = useClient(AdditionsLogisticsClient)
    readonly officesClient = useClient(OfficesClient)
    readonly currentUserStore = useCurrentUserStore()
    readonly errorStore = useErrorStore()

    async created(): Promise<void> {
        await this.currentUserStore.load()
        if (this.currentUserStore.roles.includes(Roles.Logistics))
            this.officeList = await this.officesClient.getAllOffices()
    }

    @Prop({ default: null })
    statuses!: string[] | null

    @Prop({ default: null })
    companies!: number[] | null

    @Prop({ default: null })
    idRequest!: string | null

    @Prop({ default: false, type: Boolean })
    printable!: boolean

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)

    statusFilter$ = new BehaviorSubject<string[] | undefined>(
        this.$route.query.statuses &&
        typeof this.$route.query.statuses === 'string'
            ? this.$route.query.statuses.split(',')
            : !('statuses' in this.$route.query) &&
              this.$route.name === 'AdditionPrint'
            ? []
            : [Status.pending]
    )
    companyFilter$ = new BehaviorSubject<number[] | undefined>(
        this.$route.query.companies &&
        typeof this.$route.query.companies === 'string'
            ? this.$route.query.companies
                  .split(',')
                  .map((company) => parseInt(company))
            : !('companies' in this.$route.query) &&
              this.$route.name === 'AdditionPrint'
            ? undefined
            : this.currentUserStore.companyId
            ? [this.currentUserStore.companyId]
            : undefined
    )
    unitPrint$ = new BehaviorSubject<string | undefined>(
        this.$route.query.idRequest &&
        typeof this.$route.query.idRequest === 'string'
            ? this.$route.query.idRequest
            : undefined
    )

    currentItem: AdditionDto | null = null
    currentItemUpdate: AdditionUpdateDto | null = null
    dialogComplete = false
    dialogReject = false
    dialogReopen = false
    dialogUnassign = false
    dialogEditOffice = false
    assignee = ''
    readyToPrint = new CustomEvent('readyToPrint')
    historyModalState: ImedsLogisticsHistoryState = null

    officeList: OfficeDto[] | null = null

    async onItemAction({
        item,
        actionCode,
    }: ItemAction<AdditionDto>): Promise<void> {
        this.currentItem = item

        switch (actionCode) {
            case ActionCodes.Additions.Reject:
                this.dialogReject = true
                break
            case ActionCodes.Additions.Reopen:
                this.dialogReopen = true
                break
            case ActionCodes.Additions.Complete:
                this.dialogComplete = true
                break
            case ActionCodes.Additions.Unassign:
                this.dialogUnassign = true
                break
            case ActionCodes.Additions.Edit: {
                this.dialogEditOffice = true
                this.currentItemUpdate = {
                    officeId: item.officeId ?? 0,
                }
                break
            }
            case ActionCodes.Additions.ViewHistory:
                await showHistory(this, () =>
                    this.additionsLogisticsClient.getHistory(item.id)
                )
                break
            case ActionCodes.Additions.UnitPrint:
                await this.printUnitAddition(item.id)
                break
        }
    }

    async printPlanning(): Promise<void> {
        const urlToPrint =
            `/logistics/#/addition/print?` +
            new URLSearchParams({
                ...(this.statusFilter$.value != undefined &&
                this.statusFilter$.value?.length > 0
                    ? { statuses: this.statusFilter$.value.toString() }
                    : {}),
                ...(this.companyFilter$.value != undefined &&
                this.companyFilter$.value?.length > 0
                    ? { companies: this.companyFilter$.value.toString() }
                    : {}),
            })

        const urlApi =
            '/report/?' +
            new URLSearchParams({
                url: this.utf8_to_b64(urlToPrint),
                scale: (0.7).toString(),
                landscape: true.toString(),
            })
        const urlPdfViewer =
            '/pdf/web/viewer.html?' +
            new URLSearchParams({ file: urlApi }).toString()

        const pdfWindow = window.open('', '_blank')
        if (pdfWindow) {
            pdfWindow.location.href = urlPdfViewer
        }
    }

    async printUnitAddition(requestId: number): Promise<void> {
        const urlToPrint =
            `/logistics/#/addition/print?` +
            new URLSearchParams({
                ...(this.statusFilter$.value != undefined &&
                this.statusFilter$.value?.length > 0
                    ? { statuses: this.statusFilter$.value.toString() }
                    : {}),
                ...(this.companyFilter$.value != undefined &&
                this.companyFilter$.value?.length > 0
                    ? { companies: this.companyFilter$.value.toString() }
                    : {}),
                ...(requestId != undefined
                    ? { idRequest: requestId.toString() }
                    : {}),
            })

        const urlApi =
            '/report/?' +
            new URLSearchParams({
                url: this.utf8_to_b64(urlToPrint),
                scale: (0.7).toString(),
                landscape: (1).toString(),
            })
        const urlPdfViewer =
            '/pdf/web/viewer.html?' +
            new URLSearchParams({ file: urlApi }).toString()

        const pdfWindow = window.open('', '_blank')
        if (pdfWindow) {
            pdfWindow.location.href = urlPdfViewer
        }
    }

    utf8_to_b64(str: string): string {
        return window.btoa(unescape(encodeURIComponent(str)))
    }

    b64_to_utf8(str: string): string {
        return decodeURIComponent(escape(window.atob(str)))
    }

    async completeRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.additionsLogisticsClient.completeRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.resetData()
    }

    async rejectRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.additionsLogisticsClient.rejectRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.resetData()
    }

    async reopenRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.additionsLogisticsClient.reopenRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.resetData()
    }

    async unassignRequest(): Promise<void> {
        if (!this.currentItem?.id) return

        await this.additionsLogisticsClient.unassignRequest(this.currentItem.id)
        this.refreshActionSubject$.next()
        this.resetData()
    }

    async editRequest(): Promise<void> {
        if (!this.currentItem?.id || !this.currentItemUpdate) return

        await this.additionsLogisticsClient.update(
            this.currentItem.id,
            this.currentItemUpdate
        )
        this.refreshActionSubject$.next()
        this.dialogEditOffice = false
    }

    get acceptationFormRule(): boolean {
        return !!this.assignee && this.assignee.length >= 2
    }

    resetData(): void {
        this.currentItem = null
        this.assignee = ''
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<Status>[] {
        return [
            Status.pending,
            Status.done,
            Status.rejected,
            Status.cancelled,
        ].map((status) => ({
            value: status,
            text: this.$t(statusText[status]),
        }))
    }
}
