﻿import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { useMicrobiologyStore } from '../../../stores/prescription/microbiology'
import {
    MicrobiologySampleDto,
    MicrobiologySelectedSampleDto,
    MicrobiologyTypeDto,
} from '../../../api/ImedsApi'
import ImedsMicrobiologySamplePicker from './ImedsMicrobiologySamplePicker.vue'
import ImedsMicrobiologyAnalysesPicker from './ImedsMicrobiologyAnalysesPicker.vue'

@Component({
    components: {
        ImedsMicrobiologySamplePicker,
        ImedsMicrobiologyAnalysesPicker,
    },
})
export default class ImedsMicrobiologyPicker extends Vue {
    readonly microbiologyStore = useMicrobiologyStore()
    readonly MicrobiologyPickerStep = MicrobiologyPickerStep
    sample: MicrobiologySampleDto | null = null

    get step(): MicrobiologyPickerStep {
        return !this.sample
            ? MicrobiologyPickerStep.PickSample
            : MicrobiologyPickerStep.PickAnalyses
    }

    get loading(): boolean {
        return this.microbiologyStore.loading
    }

    get catalog(): MicrobiologyTypeDto[] {
        return this.microbiologyStore.catalog
    }

    created(): void {
        window.imedsMicrobiologyStore = this.microbiologyStore
    }

    pickSample(sample: MicrobiologySampleDto): void {
        this.sample = sample
    }

    cancel(): void {
        this.sample = null
    }

    submit(selectedSample: MicrobiologySelectedSampleDto): void {
        this.microbiologyStore.selectSample(selectedSample)
        this.sample = null
    }
}

declare global {
    interface Window {
        imedsMicrobiologyStore: unknown
    }
}

enum MicrobiologyPickerStep {
    PickSample = 1,
    PickAnalyses,
}
