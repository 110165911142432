import { Component, Vue } from 'vue-property-decorator'
import { useAnalysisStore } from '../../../stores/prescription/analysis'
import { useMicrobiologyStore } from '../../../stores/prescription/microbiology'

import ImedsMicrobiologyAnalysesPicker from '../Microbiology/ImedsMicrobiologyAnalysesPicker.vue'
import { AnalysisGroupItem } from '../AnalysisList/Models/AnalysisGroupItem'
import {
    Icon,
    IconColor,
    MicrobiologySampleDto,
    MicrobiologySelectedSampleDto,
} from '../../../api/ImedsApi'
import { sortIcons, SortOrder } from '../Microbiology/SortOrder'
import ImedsAnalysisGroupList from '../AnalysisList/ImedsAnalysisGroupList.vue'
import { format } from 'date-fns'

@Component({
    components: {
        ImedsAnalysisGroupList,
        ImedsMicrobiologyAnalysesPicker,
    },
})
export default class ImedsCart extends Vue {
    readonly analysisStore = useAnalysisStore()
    readonly microbiologyStore = useMicrobiologyStore()

    editedSelectedSample: MicrobiologySelectedSampleDto | null = null

    closeEdition(): void {
        this.editedSelectedSample = null
    }
    get editedSample(): MicrobiologySampleDto | null {
        return this.editedSelectedSample
            ? this.microbiologyStore.findSampleById(
                  this.editedSelectedSample.sampleId
              )
            : null
    }

    submitEdition(newSelectedSample: MicrobiologySelectedSampleDto): void {
        if (!this.editedSelectedSample) return

        this.microbiologyStore.updateSelectedSample(
            this.editedSelectedSample,
            newSelectedSample
        )

        this.closeEdition()
    }

    get itemGroups(): AnalysisGroupItem[] | null {
        const itemGroups: AnalysisGroupItem[] | null = []

        if (this.analysisStore.selectedAnalysesOrdered.length > 0)
            itemGroups.push({
                name: 'prescription:laboratory',
                action: [
                    {
                        icon: sortIcons[this.analysisStore.sortOrder],
                        iconColor: IconColor.Default,
                        action: () =>
                            (this.analysisStore.sortOrder =
                                this.analysisStore.sortOrder === SortOrder.ASC
                                    ? SortOrder.DESC
                                    : SortOrder.ASC),
                    },
                ],

                items: this.analysisStore.selectedAnalysesOrdered.map(
                    (analysis) => ({
                        name: analysis.name ?? '',
                        action: [
                            {
                                icon: Icon.MinusCircle,
                                iconColor: IconColor.Error,
                                action: () =>
                                    this.analysisStore.unselectAnalysis(
                                        analysis.id
                                    ),
                            },
                        ],
                    })
                ),
            })

        if (this.microbiologyStore.selectedSamples.length > 0)
            itemGroups.push({
                name: 'microbio:microbiology',
                action: [
                    {
                        icon: sortIcons[
                            this.microbiologyStore
                                .selectedSamplesWithCatalogSortOrder
                        ],
                        iconColor: IconColor.Default,
                        action: () =>
                            (this.microbiologyStore.selectedSamplesWithCatalogSortOrder =
                                this.microbiologyStore
                                    .selectedSamplesWithCatalogSortOrder ===
                                SortOrder.ASC
                                    ? SortOrder.DESC
                                    : SortOrder.ASC),
                    },
                ],
                items: this.microbiologyStore.selectedSamplesWithCatalog.map(
                    ([selectedSample, sample, analyses]) => ({
                        name: sample.name,
                        detail: format(
                            new Date(selectedSample.samplingDate),
                            'dd.MM.yyyy HH:mm'
                        ),
                        action: [
                            {
                                icon: Icon.Edit,
                                iconColor: IconColor.Default,
                                action: () => {
                                    this.editedSelectedSample = selectedSample
                                },
                            },
                            {
                                icon: Icon.MinusCircleMultiple,
                                iconColor: IconColor.Error,
                                action: () =>
                                    this.microbiologyStore.unselectSample(
                                        selectedSample
                                    ),
                            },
                        ],
                        items: analyses.map((analysis) => ({
                            name: analysis.name,
                            action: [
                                {
                                    icon: Icon.MinusCircle,
                                    iconColor: IconColor.Error,
                                    action: () =>
                                        this.microbiologyStore.unselectAnalysis(
                                            selectedSample,
                                            analysis.id
                                        ),
                                },
                            ],
                        })),
                    })
                ),
            })

        return itemGroups
    }
}
