﻿import Component from 'vue-class-component'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'

import { KhModal, KhButton } from '@internal-libraries/kheops-ui-lib'
import { loadable, Loadable } from '../../utils/Loadable'
import {
    ActionCodes,
    CurrentUserPrintersClient,
    DataTableColumnDto,
    DataTableColumnType,
    DataTableConfigurationDto,
    Icon,
    IconColor,
    SortDirection,
    UserPrinterDto,
    UserPrinterEditionDto,
} from '../../api/ImedsApi'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../ImedsDataTable'
import ImedsDataTable from '../ImedsDataTable.vue'
import { useClient } from '../../api/clients'
import { useErrorStore } from '../../stores/error'
import ImedsUserPrinterEditionForm from './ImedsUserPrinterEditionForm.vue'
import { UserPrinterWithConnectedPrinter } from '../../stores/print/UserPrinterWithConnectedPrinter'
import { usePrintStore } from '../../stores/print/PrintStore'
import { usePrintersStore } from '../../stores/print/PrintersStore'

@Component({
    components: {
        ImedsUserPrinterEditionForm,
        ImedsDataTable,
        KhModal,
        KhButton,
    },
    subscriptions(this: ImedsUserPrintersConfiguration) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(
                from(
                    this.currentUserPrintersClient.getDatatableConfiguration()
                ).pipe(
                    map((configuration) => ({
                        ...configuration,
                        columns: [
                            // This is defined in the frontend because it needs the 'printer' field that does not exist
                            // in the back-end.
                            // If the 'printer' field is set, it means that the printer is connected.
                            {
                                name: 'print:printer_status',
                                type: DataTableColumnType.Icons,
                                path: 'connectedPrinter',
                                isSortable: true,
                                isPrintable: true,
                                filterType: false,
                                icons: [
                                    {
                                        icon: Icon.Disconnected,
                                        color: IconColor.Warning,
                                        title: 'print:disconnected_printer_warning',
                                        conditionValue: false,
                                    },
                                ],
                            } satisfies DataTableColumnDto,
                            ...configuration.columns,
                        ],
                    }))
                )
            )

        const loadablePagedResult: Loadable<PagedResultDto<UserPrinterDto>> =
            loadable(
                combineLatest([
                    combineLatest([this.page$, this.pageSize$]),
                    combineLatest([this.sortBy$, this.sortDirections$]),
                    this.refreshActionSubject$,
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([[page, pageSize], [sortBy, sortDirections]]) =>
                    from(
                        this.currentUserPrintersClient.getPagedCurrentUserPrinters(
                            pageSize,
                            page,
                            sortBy,
                            sortDirections,
                            true
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
        })
    },
})
export default class ImedsUserPrintersConfiguration extends Vue {
    readonly configuration!: DataTableConfigurationDto
    readonly pagedResult!: PagedResultDto<UserPrinterDto>
    readonly configurationLoading!: boolean
    readonly pagedResultLoading!: boolean

    readonly currentUserPrintersClient = useClient(CurrentUserPrintersClient)
    readonly printStore = usePrintStore()
    readonly printersStore = usePrintersStore()
    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)

    currentPrinter: UserPrinterWithConnectedPrinter | null = null

    get loading(): boolean {
        return (
            this.configurationLoading ||
            this.pagedResultLoading ||
            this.printersStore.loading ||
            this.printersStore.connectedPrintersLoading
        )
    }

    get pagedResultWithConnectedPrinters(): PagedResultDto<UserPrinterWithConnectedPrinter> {
        return {
            ...this.pagedResult,
            results: this.pagedResult.results.map((result) =>
                this.printersStore.joinConnectedPrinter(result)
            ),
        }
    }

    async onItemAction({
        item: printer,
        actionCode,
    }: ItemAction<UserPrinterWithConnectedPrinter>): Promise<void> {
        switch (actionCode) {
            case ActionCodes.Printer.Edit:
                this.openFormModal(printer)
                break
            case ActionCodes.Printer.Delete:
                await this.openDeleteModal(printer)
                break
            case ActionCodes.Printer.Print:
                this.printStore.printTestLabels(printer.connectedPrinter)
                break
            case ActionCodes.Printer.SetDefault:
                await this.currentUserPrintersClient.updateCurrentUserDefaultPrinter(
                    printer.id
                )
                this.refreshActionSubject$.next()
                break
        }
    }

    editionModal = false
    openFormModal(printer: UserPrinterWithConnectedPrinter) {
        this.currentPrinter = printer
        this.editionModal = true
    }
    closeEditionModal() {
        this.editionModal = false
        this.currentPrinter = null
    }

    async savePrinter(
        editedPrinter: UserPrinterEditionDto,
        editedPrinterId?: number
    ) {
        await (editedPrinterId
            ? this.currentUserPrintersClient.updatePrinter(
                  editedPrinterId,
                  editedPrinter
              )
            : this.currentUserPrintersClient.createPrinter(editedPrinter))

        this.closeEditionModal()
        this.refreshActionSubject$.next()
    }

    deleteModal = false
    openDeleteModal(printer: UserPrinterWithConnectedPrinter) {
        this.currentPrinter = printer
        this.deleteModal = true
    }
    closeDeleteModal() {
        this.currentPrinter = null
        this.deleteModal = false
    }
    async deletePrinter() {
        if (!this.currentPrinter) return

        await this.currentUserPrintersClient.deletePrinter(
            this.currentPrinter.id
        )
        this.closeDeleteModal()
        this.refreshActionSubject$.next()
    }

    async reload() {
        await this.printersStore.reload()
        this.refreshActionSubject$.next()
    }
}
