import Vue from 'vue'
import Component from 'vue-class-component'
import { KhTooltip, KhButton, KhIcon } from '@internal-libraries/kheops-ui-lib'
import { useCurrentUserStore } from '../../../../stores/currentUser'
import ImedsAnalysisProfileEditionModal from './ImedsAnalysisProfileEditionModal.vue'
import { useAnalysisProfileStore } from '../../../../stores/prescription/analysisProfile'
import { Roles } from '../../../../api/ImedsApi'

@Component({
    components: {
        KhTooltip,
        KhButton,
        KhIcon,
        ImedsAnalysisProfileEditionModal,
    },
})
export default class ImedsAnalysisProfileEditionButton extends Vue {
    readonly currentUserStore = useCurrentUserStore()
    readonly analysisProfileStore = useAnalysisProfileStore()

    editionModal = false

    get visible(): boolean {
        return this.currentUserStore.hasAnyRoleIn(
            Roles.ProfileEditor,
            Roles.AdminConnectedAs
        )
    }

    get disabled(): boolean {
        return (
            this.analysisProfileStore.loading ||
            !this.analysisProfileStore.selectedProfile
        )
    }
}
