<script setup lang="ts">
import { usePrintersStore } from '../../stores/print/PrintersStore'
import { useTranslate } from '../../i18n/useTranslation'
import { Icon, IconColor } from '../../api/ImedsApi'
import { KhSelect } from '@internal-libraries/kheops-ui-lib'
import ImedsIcon from '../ImedsIcon.vue'
import ReloadButton from '../ReloadButton/ReloadButton.vue'

const printersStore = usePrintersStore()
const $t = useTranslate()
</script>
<template>
    <kh-select
        label="print:printer"
        :items="printersStore.printers"
        :loading="printersStore.loading"
        :readonly="printersStore.loading"
        item-text="name"
        item-value="id"
        return-object
        :no-data-text="$t('print:no_printer_found')"
        v-model="printersStore.selectedPrinter"
        :error-messages="$t(printersStore.selectedPrinter?.error)"
    >
        <template #prepend-inner>
            <div
                v-if="
                    printersStore.selectedPrinter &&
                    !printersStore.selectedPrinter.connectedPrinter
                "
            >
                <imeds-icon
                    :icon="Icon.Disconnected"
                    :color="IconColor.Warning"
                    :tooltip="$t('print:disconnected_printer_warning')"
                    right
                />
            </div>
        </template>
        <template #append-outer>
            <reload-button
                @reload="printersStore.reload()"
                :tooltip="$t('print:refresh_printer_list')"
                :loading="printersStore.loading"
            />
        </template>
        <template #item="{ item }">
            <imeds-icon
                v-if="!item.connectedPrinter"
                :icon="Icon.Disconnected"
                :color="IconColor.Warning"
            />
            <imeds-icon
                v-if="item.isDefault"
                :icon="Icon.Favourite"
                :color="IconColor.Info"
            />

            <span class="ml-2">{{ item.name }}</span>
        </template>
    </kh-select>
</template>
