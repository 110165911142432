import Vue from 'vue'
import Component from 'vue-class-component'
import {
    DataTableFilterType,
    DataTableColumnType,
    Icon,
    SortDirection,
} from '../../../api/ImedsApi'
import { Emit, Prop } from 'vue-property-decorator'
import {
    KhButton,
    KhDatePicker,
    KhDatePickerReturnType,
    KhEllipsis,
} from '@internal-libraries/kheops-ui-lib'
import ImedsIcon from '../../ImedsIcon.vue'

@Component({
    components: { KhButton, KhEllipsis, KhDatePicker, ImedsIcon },
})
export default class ImedsDataTableHeaderElement extends Vue {
    @Prop({ required: true })
    columnType!: DataTableColumnType

    @Prop({ default: null })
    filterType!: DataTableFilterType | null

    @Prop({ default: false })
    sortable!: boolean

    @Prop({ default: null })
    sortingDirection!: SortDirection | null

    @Prop({ required: true })
    text!: string

    @Prop({ required: true })
    printable!: boolean

    isFiltering = false
    dateReturnType = KhDatePickerReturnType.dateOnlyString
    get DataTableColumnType() {
        return DataTableColumnType
    }
    get DataTableFilterType() {
        return DataTableFilterType
    }

    get translatedText() {
        return this.$t(this.text)
    }

    @Emit('update:sorting')
    emitSorting(): SortDirection | null {
        switch (this.sortingDirection) {
            case SortDirection.Ascending:
                return SortDirection.Descending
            case SortDirection.Descending:
                return null
            default:
                return SortDirection.Ascending
        }
    }

    @Emit('update:filter')
    emitFilter(value: string | Date | null): string | Date | null {
        return value
    }

    getSortDirectionIcon(): Icon | null {
        if (!this.sortable) return null

        return this.sortingDirection === SortDirection.Descending
            ? Icon.ArrowDown
            : Icon.ArrowUp
    }
}
