import { computed } from 'vue'
import { groupBy, orderBy, sumBy, uniqBy } from 'lodash'
import { defineStore } from 'pinia'
import { TubeDto } from '../../api/ImedsApi'
import { useAnalysisStore } from './analysis'
import { useMicrobiologyStore } from './microbiology'

export const useTubeStore = defineStore('tubes', () => {
    const analysisStore = useAnalysisStore()
    const microbiologyStore = useMicrobiologyStore()

    const tubes = computed(() => {
        const tubes: TubeWithType[] = []
        const individualTubes: TubeWithType[] = analysisStore.selectedAnalyses
            .flatMap((analysis) =>
                analysis.tubes.map((tube) => ({
                    ...tube,
                    isRoutine: true,
                }))
            )
            .concat(
                microbiologyStore.selectedSamples.flatMap((selectedSample) => {
                    const sample = microbiologyStore.findSampleById(
                        selectedSample.sampleId
                    )

                    const analysesTubes = sample.analyses.flatMap((analysis) =>
                        selectedSample.selectedAnalyses.includes(analysis.id)
                            ? analysis.tubes
                            : []
                    )

                    const analysesTubesGrouped = uniqBy(
                        analysesTubes,
                        (analysisTube) => analysisTube.id
                    )

                    return sample.tubes
                        .concat(analysesTubesGrouped)
                        .map((tube) => ({
                            ...tube,
                            isRoutine: false,
                        }))
                })
            )

        const groupedTubes = groupBy(individualTubes, (tube) => tube.id)

        for (const tubeGroup in groupedTubes) {
            const firstTube = groupedTubes[tubeGroup][0]
            const maxQuantity = firstTube.maxQuantity

            const tubeWithoutQuantity: Omit<TubeWithType, 'quantity'> = {
                ...firstTube,
                color: firstTube.color?.startsWith('#')
                    ? firstTube.color
                    : '#' + firstTube.color,
                maxQuantity,
            }

            let totalQuantity = sumBy(
                groupedTubes[tubeGroup],
                (tube) => tube.quantity
            )

            while (totalQuantity >= maxQuantity) {
                tubes.push({
                    ...tubeWithoutQuantity,
                    quantity: maxQuantity,
                })
                totalQuantity -= maxQuantity
            }
            if (totalQuantity > 0) {
                tubes.push({
                    ...tubeWithoutQuantity,
                    quantity: totalQuantity,
                })
            }
        }

        return orderBy(
            tubes,
            (tube) => [tube.name?.toLowerCase(), tube.quantity], //toLowerCase() because UpperCase > LowerCase (E > a)
            ['asc', 'desc']
        )
    })

    return {
        tubes,
    }
})

type TubeWithType = TubeDto & { isRoutine: boolean }
