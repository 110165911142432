﻿import { useClient } from '../../api/clients'
import ImedsDataTable from '../ImedsDataTable.vue'
import {
    ActionCodes,
    DataTableConfigurationDto,
    NurseOrdersClient,
    NurseRequestDto,
    SortDirection,
} from '../../api/ImedsApi'
import { anyLoading, Loadable, loadable } from '../../utils/Loadable'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
    PagedResultDto,
} from '../ImedsDataTable'
import { Component, Watch } from 'vue-property-decorator'
import Vue from 'vue'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { VSelectItem } from '../Courier/VSelectItem'
import { Status, statusText } from '../models/Courier/Status'
import { useErrorStore } from '../../stores/error'
import { KhConfirmModal } from '@internal-libraries/kheops-ui-lib'

@Component({
    components: { ImedsDataTable, KhConfirmModal },
    subscriptions(this: NurseOrdersTable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.nurseOrdersClient.getConfiguration()).pipe())

        const loadablePagedResult: Loadable<PagedResultDto<NurseRequestDto>> =
            loadable(
                combineLatest([
                    this.page$,
                    this.pageSize$,
                    this.sortBy$,
                    this.sortDirections$,
                    this.filterStatus$,
                    this.refreshActionSubject$,
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([page, pageSize, sortBy, sortDirections, filterStatus]) =>
                    from(
                        this.nurseOrdersClient.get(
                            pageSize,
                            page,
                            sortBy,
                            sortDirections,
                            true,
                            filterStatus
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
            filterStatus: this.filterStatus$,
        })
    },
})
export default class NurseOrdersTable extends Vue {
    readonly nurseOrdersClient = useClient(NurseOrdersClient)
    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
    readonly filterStatus$ = new BehaviorSubject<string | null>(null)

    isDeleteModalOpen = false
    currentRequestId: number | null = null

    async onItemAction(itemAction: ItemAction<NurseRequestDto>): Promise<void> {
        switch (itemAction.actionCode) {
            case ActionCodes.Nurse.Order.Cancel:
                this.currentRequestId = itemAction.item.id
                this.isDeleteModalOpen = true
                break
        }
    }

    async cancelRequest() {
        if (this.currentRequestId) {
            await this.nurseOrdersClient.cancel(this.currentRequestId)
            this.refreshActionSubject$.next()
        }
    }

    @Watch('$t')
    get statusOptions(): VSelectItem<Status>[] {
        return [
            Status.accepted,
            Status.pending,
            Status.done,
            Status.cancelled,
            Status.rejected,
        ].map((status) => ({
            value: status,
            text: this.$t(statusText[status]),
        }))
    }
}
