import { Component, Ref } from 'vue-property-decorator'
import PrescriptionOrdersTable from './PrescriptionOrdersTable'
import Vue from 'vue'

@Component
export default class PrescriptionOrderSearchButton extends Vue {
    private searchModal = false

    @Ref('orderTable')
    readonly orderTable?: PrescriptionOrdersTable

    reloadDatatable(): void {
        this.orderTable?.reload()
    }

    copyOrder(id: number) {
        this.searchModal = false
        this.$emit('copy-order', id)
    }

    editOrder(id: number) {
        this.searchModal = false
        this.$emit('edit-order', id)
    }
}
