﻿import { ref } from 'vue'
import { defineLoadableStore } from '@internal-libraries/kheops-ui-lib/src/store/LoadableStore'
import { useClient } from '../api/clients'
import { CompaniesClient, CompanyDto } from '../api/ImedsApi'

const companiesClient = useClient(CompaniesClient)

export const useCompanyStore = defineLoadableStore(
    'company',
    () => {
        return {
            companies: ref<CompanyDto[]>([]),
        }
    },
    async (store) => {
        store.companies.value = await companiesClient.getCompanies()
    }
)
