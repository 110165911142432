import Vue, { computed, ComputedRef, Ref, ref } from 'vue'
import { TFunction } from 'i18next'
import { i18n } from 'i18next'
import { hashTranslate } from './hashTranslate'
import { fallbackToEnglishExceptForFrench } from './fallbackToEnglishExceptForFrench'
import { refDebounced } from '@vueuse/core'

let translation: Translation | undefined

export function useTranslation(): Translation {
    if (!translation) {
        const vue = new Vue()
        const i18next = vue.$i18next
        const t = ref<TFunction>(i18next.t)
        const tDebounced = refDebounced(t, 200)

        i18next.on('loaded', () => {
            t.value = ((...args: Parameters<TFunction>) =>
                vue.$i18next.t(...args)) as TFunction
        })

        const tHash = computed<THashFunction>(() => {
            const $t = tDebounced.value
            return (namespace, text) => hashTranslate($t, namespace, text)
        })

        const tDefault = computed<TDefaultFunction>(() => {
            const $t = tDebounced.value
            return (namespace, key, defaultValue) =>
                $t(key, {
                    ns: namespace,
                    defaultValue,
                    fallbackLng: fallbackToEnglishExceptForFrench,
                })
        })

        translation = {
            t: tDebounced,
            tHash,
            tDefault,
            i18next,
        }
    }

    return translation
}

export function useTranslate() {
    const { t } = useTranslation()
    return t
}

export interface Translation {
    /**
     * Reference to the i18next translation function.
     * Updated when the available translations change.
     */
    t: Ref<TFunction>
    /**
     * Reference to a translation function
     */
    tHash: ComputedRef<THashFunction>
    tDefault: ComputedRef<TDefaultFunction>
    i18next: i18n
}

export interface THashFunction {
    /**
     * Tries to find a translation using the Base62 MD5 hash of the given text as the key
     * @param namespace Namespace where the translation is located
     * @param text Text to translate
     * @returns The translated text if found or the original text if not found
     */
    (namespace: string, text: string | undefined): string
}

export interface TDefaultFunction {
    /**
     * Tries to find a translation or returns a default if not found
     * @param namespace Namespace where the translation is located
     * @param key Text to translate
     * @param defaultValue The text to show if no translation is found.
     */
    (namespace: string, key: string, defaultValue: string): string
}
