import Vue from 'vue'
import Component from 'vue-class-component'
import { ImedsDataTable } from '../index'
import { anyLoading, loadable, Loadable } from '../../utils/Loadable'
import {
    AnnouncementsClient,
    AnnouncementDto,
    DataTableConfigurationDto,
    SortDirection,
} from '../../api/ImedsApi'
import {
    DATA_TABLE_DEFAULT_DEBOUNCE_TIME,
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    PagedResultDto,
} from '../ImedsDataTable'
import { BehaviorSubject, combineLatest, from } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { useClient } from '../../api/clients'
import { useErrorStore } from '../../stores/error'

@Component({
    components: { ImedsDataTable },
    subscriptions(this: ImedsAnnouncementDatatable) {
        const loadableConfiguration: Loadable<DataTableConfigurationDto> =
            loadable(from(this.announcementsClient.getConfiguration()))

        const loadablePagedResult: Loadable<PagedResultDto<AnnouncementDto>> =
            loadable(
                combineLatest([
                    combineLatest([this.page$, this.pageSize$]),
                    combineLatest([this.sortBy$, this.sortDirections$]),
                ]).pipe(debounceTime(DATA_TABLE_DEFAULT_DEBOUNCE_TIME)),
                ([[page, pageSize], [sortBy, sortDirections]]) =>
                    from(
                        this.announcementsClient.getAnnouncements(
                            pageSize,
                            page,
                            sortBy,
                            sortDirections,
                            true
                        )
                    )
            )

        return this.errorStore.handleSubscriptionsErrors({
            configuration: loadableConfiguration.value$,
            pagedResult: loadablePagedResult.value$,
            loading: anyLoading(loadableConfiguration, loadablePagedResult),
            configurationLoading: loadableConfiguration.loading$,
            pagedResultLoading: loadablePagedResult.loading$,
        })
    },
})
export default class ImedsAnnouncementDatatable extends Vue {
    readonly announcementsClient = useClient(AnnouncementsClient)
    readonly errorStore = useErrorStore()

    readonly page$ = new BehaviorSubject<number>(DATA_TABLE_DEFAULT_PAGE)
    readonly pageSize$ = new BehaviorSubject<number>(
        DATA_TABLE_DEFAULT_PAGE_SIZE
    )
    readonly sortBy$ = new BehaviorSubject<string[]>([])
    readonly sortDirections$ = new BehaviorSubject<SortDirection[]>([])
    readonly refreshActionSubject$ = new BehaviorSubject<void>(undefined)
}
