import { ResourceKey } from 'i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import { useClient } from '../api/clients'
import { TranslationsClient } from '../api/ImedsApi'
import { chain, isArray } from 'lodash'

const asyncTranslationBackend = (
    load: (language: string, namespace: string) => Promise<ResourceKey>
) => ({
    backend: resourcesToBackend(async (language, namespace, callback) => {
        try {
            callback(null, await load(language, namespace))
        } catch (error) {
            callback(error as Error, null)
        }
    }),
})

const staticTranslationBackend = asyncTranslationBackend(
    (language, namespace) => import(`../locales/${namespace}/${language}.json`)
)

const translationsClient = useClient(TranslationsClient)
const apiTransactionBackend = asyncTranslationBackend((language, namespace) =>
    translationsClient.getTranslations(language, namespace)
)

export const backendConfigurations = [
    staticTranslationBackend,
    apiTransactionBackend,
]

const jsonLocalesNamespaces: string[] = chain(
    import.meta.glob('../locales/*/*.json')
)
    .keys()
    .flatMap((path) => {
        const matches = path.match(/.*\/(.*)\/.*\.json$/)
        return matches && matches[1] ? [matches[1]] : []
    })
    .uniq()
    .value()

const windowNamespaces: string[] =
    'imedsI18nNamespaces' in window && isArray(window.imedsI18nNamespaces)
        ? window.imedsI18nNamespaces.filter(
              (namespace: unknown): namespace is string =>
                  typeof namespace === 'string'
          )
        : []
export const namespaces: string[] = [
    ...jsonLocalesNamespaces,
    ...windowNamespaces,
]
