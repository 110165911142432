import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import ImedsDataTable from '../../ImedsDataTable.vue'
import {
    DataTableConfigurationDto,
    SortDirection,
    PatientsSearchClient,
    PrescriptionPatientDto,
    VisitDto,
    PagedResultDtoOfPrescriptionPatientDto,
    SearchType,
    ActionCodes,
} from '../../../api/ImedsApi'
import {
    DATA_TABLE_DEFAULT_PAGE,
    DATA_TABLE_DEFAULT_PAGE_SIZE,
    ItemAction,
} from '../../ImedsDataTable'

import { useClient } from '../../../api/clients'
import { useErrorStore } from '../../../stores/error'
import ImedsUserPrinterSelector from '../../Printers/ImedsUserPrinterSelector.vue'
import { usePrintStore } from '../../../stores/print/PrintStore'
import PrescriptionPatientVisitTable from './PrescriptionPatientVisitTable.vue'
import { usePatientSearchStore } from '../../../stores/patientSearch'
import { KhDatePickerReturnType } from '@internal-libraries/kheops-ui-lib'

@Component({
    components: {
        PrescriptionPatientVisitTable,
        ImedsDataTable,
        ImedsUserPrinterSelector,
    },
})
export default class PrescriptionPatientTable extends Vue {
    readonly patientsSearchClient = useClient(PatientsSearchClient)
    readonly patientSearchStore = usePatientSearchStore()
    readonly errorStore = useErrorStore()
    readonly printStore = usePrintStore()
    page: number = DATA_TABLE_DEFAULT_PAGE
    pageSize: number = DATA_TABLE_DEFAULT_PAGE_SIZE
    sortBy: string[] = []
    sortDirections: SortDirection[] = []
    patientFirstName: string | null = null
    patientLastName: string | null = null
    patientBirthday: string | null = null
    patientReferenceNumber: string | null = null
    patientAvs: string | null = null
    visitNumber: string | null = null
    visitSelectorModal = false
    gdprValidationModal = false

    birthdayDatepickerReturnType = KhDatePickerReturnType.dateOnlyString
    onItemAction({
        item,
        actionCode,
    }: ItemAction<PrescriptionPatientDto>): void {
        if (actionCode === ActionCodes.PatientSearch.Print)
            this.printStore.printPatient({
                firstName: item.givenName,
                lastName: item.familyName,
                birthDate: item.birthDate,
                address1: item.address,
                address2: item.additionalAddress,
                postalCode: item.zipCode,
                city: item.city,
                country: item.country,
            })
    }

    @Watch('page')
    @Watch('pageSize')
    @Watch('sortBy')
    @Watch('sortDirections')
    onPagingChange() {
        if (this.pagedResult?.results.length) this.search(true)
    }

    selectedPatient: PrescriptionPatientDto | null = null
    onPatientSelected(patient: PrescriptionPatientDto) {
        this.selectedPatient = patient
        let selectedVisit = null
        if (this.patientSearchStore.searchType == SearchType.External) {
            if (patient.visits?.length) {
                const matchedVisit: VisitDto | undefined = patient.visits.find(
                    (visit) => visit.visitNumber === this.visitNumber
                )
                if (matchedVisit) {
                    this.setSelectedPatient(matchedVisit)
                    return
                }

                if (patient.visits.length == 1) {
                    selectedVisit = patient.visits[0]
                    this.setSelectedPatient(selectedVisit)
                    return
                }
                this.visitSelectorModal = true
                return
            }
        }

        this.setSelectedPatient()
    }

    setSelectedPatient(visit?: VisitDto | null) {
        this.visitSelectorModal = false
        this.$emit('setPatient', this.selectedPatient, visit)
        this.reset()
    }

    isSearchWithAddresses = false
    configurationLoading = false
    pagedResultLoading = false
    pagedResult: PagedResultDtoOfPrescriptionPatientDto | null = null
    configuration: DataTableConfigurationDto | null = null
    async mounted() {
        this.configurationLoading = true
        this.configuration = await this.patientsSearchClient.getConfiguration()
        this.configurationLoading = false

        if (this.patientSearchStore.searchType == SearchType.External) {
            //TODO: To refactor when the prescription is in a SPA page
            const visitIdParameter =
                (document.getElementById('visitId') as HTMLInputElement | null)
                    ?.value ||
                new URLSearchParams(window.location.search).get('vid')
            if (visitIdParameter) {
                this.visitNumber = visitIdParameter
                this.search(true)
            }
        }
    }
    get loading() {
        return this.configurationLoading || this.pagedResultLoading
    }
    public async search(hasValidatedGdpr: boolean) {
        if (this.patientSearchStore.searchType == SearchType.External)
            if (
                !hasValidatedGdpr &&
                (this.visitNumber == null || this.visitNumber.length == 0) &&
                (!this.patientFirstName ||
                    this.patientFirstName.trim().length < 2 ||
                    !this.patientLastName ||
                    this.patientLastName.trim().length < 2 ||
                    !this.patientBirthday)
            ) {
                this.gdprValidationModal = true
                return
            }

        this.pagedResultLoading = true
        this.pagedResult = await this.patientsSearchClient.getPatientsTable(
            this.sortBy,
            this.sortDirections,
            this.pageSize,
            this.page,
            true,
            this.patientFirstName?.trim() || undefined,
            this.patientLastName?.trim() || undefined,
            this.patientBirthday || undefined,
            this.patientReferenceNumber?.trim() || undefined,
            this.patientAvs?.trim() || undefined,
            this.visitNumber?.trim() || undefined,
            this.visitNumber == null ? this.isSearchWithAddresses : true
        )
        this.pagedResultLoading = false
        this.isSearchWithAddresses = false
    }

    private reset() {
        this.pagedResult = null
        this.patientFirstName = null
        this.patientLastName = null
        this.patientBirthday = null
        this.patientReferenceNumber = null
        this.patientAvs = null
        this.visitNumber = null
        this.isSearchWithAddresses = false

        this.selectedPatient = null
    }
}
