import Vue from 'vue'
import Component from 'vue-class-component'
import ImedsLanguageSelector from '../ImedsLanguageSelector.vue'

@Component({ components: { ImedsLanguageSelector } })
export default class ImedsMpaLanguageSelector extends Vue {
    reloadPage(): void {
        window.location.reload()
    }
}
