import { BaseImedsClient } from './ImedsApi'
import axios, { AxiosInstance } from 'axios'

const axiosInstance = axios.create({ transformResponse: [] })
export function useAxiosInstance(): AxiosInstance {
    return axiosInstance
}

export interface ImedsApiClientConstructor<
    TClient extends BaseImedsClient = BaseImedsClient
> {
    new (baseUrl?: string, instance?: AxiosInstance): TClient
}

const clientInstances: Map<ImedsApiClientConstructor, BaseImedsClient> =
    new Map()

export function useClient<TClient extends BaseImedsClient>(
    clientConstructor: ImedsApiClientConstructor<TClient>
): TClient {
    let client = clientInstances.get(clientConstructor) as TClient | undefined
    if (!client) {
        client = new clientConstructor(undefined, axiosInstance)
        clientInstances.set(clientConstructor, client)
    }
    return client
}
