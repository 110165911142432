import Component from 'vue-class-component'
import { KhComponent } from '@internal-libraries/kheops-ui-lib'
import ImedsPrescriptionOrderSaveForm from './ImedsPrescriptionOrderSaveForm.vue'

@Component({
    components: { ImedsPrescriptionOrderSaveForm },
})
export default class ImedsPrescriptionOrderSaveFormButton extends KhComponent {
    modal = false
}
