import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { KhSelect, KhSelectMenu } from '@internal-libraries/kheops-ui-lib'
import {
    AdditionRequestHistoryPropertyCode,
    CourierRequestHistoryPropertyCode,
    LogisticsHistoryEntryDtoOfAdditionRequestHistoryPropertyCode,
    LogisticsHistoryEntryDtoOfCourierRequestHistoryPropertyCode,
    LogisticsHistoryEntryDtoOfNurseRequestHistoryPropertyCode,
    NurseRequestHistoryPropertyCode,
} from '../../api/ImedsApi'
import { format, parseJSON, isValid } from 'date-fns'
import { useErrorStore } from '../../stores/error'

@Component({
    components: { KhSelect, KhSelectMenu },
    filters: {
        formatDateOnly: (value: Date) => format(parseJSON(value), 'dd.MM.yyyy'),
        formatHourOnly: (value: Date) => format(parseJSON(value), 'HH:mm'),
    },
    model: {
        prop: 'state',
        event: 'input',
    },
})
export default class ImedsLogisticsHistory extends Vue {
    readonly iconsByPropertyCode: Record<
        | CourierRequestHistoryPropertyCode
        | NurseRequestHistoryPropertyCode
        | AdditionRequestHistoryPropertyCode,
        string
    > = {
        Status: 'mdi-state-machine',
        Assignee: 'mdi-account',
        Unassign: 'mdi-account',
        SampleDate: 'mdi-calendar-clock',
        Creation: 'mdi-flare',
        Office: 'mdi-office-building-outline',
    }

    @Prop({ required: true })
    state!: ImedsLogisticsHistoryState

    closeModal() {
        this.$emit('input', null)
    }

    formatNewValue(history: LogisticsHistory) {
        return this.$t(
            formatField(
                history.newValue,
                fieldTypeByLogisiticsHistoryPropertyCode[history.propertyCode]
            )
        )
    }

    formatUserId(userId: string | null | undefined): string {
        return this.$t(formatField(userId, FieldType.User))
    }
}

export type ImedsLogisticsHistoryState =
    | { histories: LogisticsHistory[][] }
    | { loading: true }
    | { error: string }
    | null

export async function showHistory(
    component: { historyModalState: ImedsLogisticsHistoryState },
    loadHistory: () => Promise<LogisticsHistory[][]>
): Promise<void> {
    component.historyModalState = { loading: true }
    const result = await useErrorStore().tryOrHandleErrorAndDefault<
        LogisticsHistory[][] | string
    >(
        () => loadHistory(),
        (message) => message
    )

    component.historyModalState =
        typeof result === 'string' ? { error: result } : { histories: result }
}

type LogisticsHistory =
    | LogisticsHistoryEntryDtoOfCourierRequestHistoryPropertyCode
    | LogisticsHistoryEntryDtoOfNurseRequestHistoryPropertyCode
    | LogisticsHistoryEntryDtoOfAdditionRequestHistoryPropertyCode

const LogisiticsHistoryPropertyCode = {
    ...CourierRequestHistoryPropertyCode,
    ...NurseRequestHistoryPropertyCode,
    ...AdditionRequestHistoryPropertyCode,
}

const formatDateTime = (
    value: string | number | Date | null | undefined
): string | null => {
    const parsed = value ? parseJSON(value) : null

    return parsed == null || !isValid(parsed)
        ? null
        : format(parsed, 'dd.MM.yyyy HH:mm')
}

enum FieldType {
    User = 'user',
    Status = 'status',
    Date = 'date',
    Any = 'any',
}

const fieldTypeByLogisiticsHistoryPropertyCode: Record<
    keyof typeof LogisiticsHistoryPropertyCode,
    FieldType
> = {
    [LogisiticsHistoryPropertyCode.Assignee]: FieldType.User,
    [LogisiticsHistoryPropertyCode.Unassign]: FieldType.User,
    [LogisiticsHistoryPropertyCode.Creation]: FieldType.Any,
    [LogisiticsHistoryPropertyCode.SampleDate]: FieldType.Date,
    [LogisiticsHistoryPropertyCode.Status]: FieldType.Status,
    [LogisiticsHistoryPropertyCode.Office]: FieldType.Any,
}

const formatField = (
    value: string | undefined | null,
    type: FieldType
): string => {
    if (type === FieldType.Date) {
        value = formatDateTime(value)
    }

    if (!value) {
        return `logistics:history_unknown_value.${type}`
    }

    if (type === FieldType.Status) {
        return `logistics:${value}`
    }

    return value
}
