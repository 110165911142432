﻿export enum OptionsSaveForm {
    Pathologic = 1,
    Create = 2,
    Done = 4,
}

export const optionsSaveFormText: Record<OptionsSaveForm, string> = {
    [OptionsSaveForm.Pathologic]: 'prescription:saveForm.pathologic',
    [OptionsSaveForm.Create]: 'prescription:saveForm.create',
    [OptionsSaveForm.Done]: 'prescription:saveForm.done',
}
