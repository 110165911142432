﻿import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { sortIcons, SortOrder } from './SortOrder'
import { chain, deburr } from 'lodash'
import {
    MicrobiologySampleDto,
    MicrobiologyTypeDto,
} from '../../../api/ImedsApi'

@Component
export default class ImedsMicrobiologySamplePicker extends Vue {
    sortOrder = SortOrder.ASC
    sampleFilter = ''
    typeFilter = ''

    @Prop({ required: true })
    catalog!: MicrobiologyTypeDto[]

    get sortIcon() {
        return this.sortOrder === SortOrder.ASC
            ? sortIcons[SortOrder.DESC]
            : sortIcons[SortOrder.ASC]
    }

    get sampleFilterPredicate(): (sample: SampleWithType) => boolean {
        return createContainsPredicate(
            this.sampleFilter,
            (sample) => sample.name
        )
    }

    get typeFilterPredicate(): (sample: SampleWithType) => boolean {
        return createContainsPredicate(
            this.typeFilter,
            (sample) => sample.typeName
        )
    }

    get samples(): SampleWithType[] {
        return chain(this.catalog)
            .flatMap((type) =>
                type.samples.map((sample) => ({
                    typeName: type.name,
                    ...sample,
                }))
            )
            .filter(this.sampleFilterPredicate)
            .filter(this.typeFilterPredicate)
            .orderBy(
                (sample) => sample.name.toLowerCase().trim(),
                this.sortOrder
            )
            .value()
    }

    get typeFilterAutocompletions(): string[] {
        return chain(this.samples)
            .filter(this.sampleFilterPredicate)
            .map((sample) => sample.typeName)
            .value()
    }

    toggleSortOrder(): void {
        this.sortOrder =
            this.sortOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC
    }

    @Emit()
    pick(sample: MicrobiologySampleDto): MicrobiologySampleDto {
        return sample
    }

    boldSearch(text: string): string {
        if (!text || !this.sampleFilter) {
            return text
        }

        const searchIndex = deburr(text)
            .toLowerCase()
            .indexOf(deburr(this.sampleFilter).toLowerCase())

        if (searchIndex < 0) {
            return text
        }

        const afterSearchIndex = searchIndex + this.sampleFilter.length
        const before = text.substring(0, searchIndex)
        const searched = text.substring(searchIndex, afterSearchIndex)
        const after = text.substring(afterSearchIndex)

        return `${before}<b>${searched}</b>${after}`
    }
}

function createContainsPredicate<T>(
    needle: string | null,
    getField: (sample: T) => string
): (sample: T) => boolean {
    if (!needle) return () => true
    const lowercaseDeburredNeedle = deburr(needle).toLowerCase()
    return (sample) =>
        deburr(getField(sample)).toLowerCase().includes(lowercaseDeburredNeedle)
}

export interface SampleWithType extends MicrobiologySampleDto {
    typeName: string
}
